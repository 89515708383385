import React, { Component, useEffect, useState } from "react";
import { styled, useTheme } from '@mui/material/styles';
import CustomNumberInput from "components/number-input";
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ForwardIcon from '@mui/icons-material/Forward';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import LinearProgress from '@mui/material/LinearProgress';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import Tooltip from '@mui/material/Tooltip';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';


import AddBoxIcon from '@mui/icons-material/AddBox';
import ClearIcon from '@mui/icons-material/Clear';
import LockIcon from '@mui/icons-material/Lock';
import SearchIcon from '@mui/icons-material/Search';
import DatasetIcon from '@mui/icons-material/Dataset';
import ReplayIcon from '@mui/icons-material/Replay';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditAttributesIcon from '@mui/icons-material/EditAttributes';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import OutputIcon from '@mui/icons-material/Output';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import CheckIcon from '@mui/icons-material/Check';
import UndoIcon from '@mui/icons-material/Undo';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import PreviewIcon from '@mui/icons-material/Preview';
import MoreIcon from '@mui/icons-material/More';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SettingsIcon from '@mui/icons-material/Settings';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { default as FileUpload, netUpload } from '../file-upload';

import { MessageHooks } from "../../App";
import { UserInfo, netGet, netPost, netFetch, keycloak } from "../network";
import { UploadFileDialog } from "./workflow-select-draft";
import FileDownloader from "../file-download";

import { SupplementApprover, updateSupplementForm } from "components/extensions/supplements";

import { fabClasses } from "@mui/material";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import { setCurrentMenu } from "features/menu/menuSlice";

// import "./forms.css";

//import { WorkflowCheckinDialog, WorkflowRevokeCheckoutDialog, WorkflowDeleteDialog, 
//    StepDeleteDialog, CreateWorkflowDialog, WorkflowSaveDialog } from "./management-workflow-dialogs";

const TYPE_LABELS = {
    TEXT: 'Text Field',
    TEXTFIELD: 'Text Field',
    TEXTAREA: 'Text Box',
    CHECKBOX: 'Checkbox',
    CHECKBOX_GROUP: 'Checkbox Group',
    DATE_PICKER: 'Date Picker',
    DATETIME_PICKER: 'Date & Time Picker',
    TIME_PICKER: 'Time Picker',
    FILE_UPLOAD: 'File Upload',
    RADIOBUTTON_GROUP: 'Radio Button Group',
    SELECTION: 'Selection',
    PREDEFINED: 'Predefined Field',
};

const PREDEFINED_FIELDS = [
    // { label: 'Document Selection', type: 'SELECTION', name: 'documentName'},
    // { label: 'First Name', type: 'TEXTFIELD', name: 'firstName'},
    // { label: 'Last Name', type: 'TEXTFIELD', name: 'lastName'},
    { label: 'Revision Number', type: 'TEXTFIELD', name: 'revisionNumber' },
    // { label: 'New Role', type: 'TEXTFIELD', name: 'newRole'},
    // { label: 'Old Role Selection', type: 'SELECTION', name: 'oldRole'},
    // { label: 'Role Selection', type: 'SELECTION', name: 'role'},
    // { label: 'Revision Date', type: 'DATE', name: 'revisionDate'},
    { label: 'Upload DCR Candidate', type: 'FILE_UPLOAD', name: 'uploadDCR' },
    { label: 'User Email', type: 'TEXTFIELD', name: 'userEmail' },
];

const TEXT_VARIABLES = [
    { label: 'Current User', value: 'currentUserName'},
    { label: 'Document', value: 'document'},
    { label: 'From Other Form Field', value: 'fromFormField'},
    { label: 'ID Generator', value: 'idGenerator'},
    { label: 'Read Revision Number', value: 'roRevisionNumber'},
    { label: 'Revision Number', value: 'revisionNumber' },
    { label: 'Today\'s Date', value: 'TODAY'},
    { label: 'User Email', value: 'userEmail' },
    { label: 'User Input', value: 'CUSTOM' },
];

const SELECTION_VARIABLES = [
    { label: 'Custom', value: 'CUSTOM' },
    { label: 'Document List and Parts', value: 'DOCUMENTS' },
    { label: 'Document List only', value: 'DOCUMENTS_ONLY'},
    { label: 'Document Unit', value: 'DOC_UNIT'},
    { label: 'Peer Reviewers', value: 'PEER_REVIEWERS'},
    { label: 'Role List', value: 'ROLES' },
    { label: 'User List', value: 'USERS' },
    
];
const UPLOAD_VARIABLES = [
    { value: 'DCR', label: 'DCR Candidate' },
    { value: 'DOC', label: 'External Document Sources/Outputs'},
    { value: 'OEM', label: 'OEM Revision' },
    { value: 'DLUP', label: 'Digital Library Upload Shelf'},
];
const DATE_VARIABLES = [
    { label: 'Custom', value: 'CUSTOM' },
    { value: 'RD_OEM', label: 'OEM Revision Date' },
    { value: 'RD_TARGET', label: 'Target Revision Date' },
];

const DOC_TYPES = [
    { value: 'COMPANY', label: "Company"},
    { value: 'EXTERNAL', label: 'External'},
    { value: 'CORE', label: 'Internal'},
    { value: 'PARTED', label: 'Parts document'},
    { value: 'OEM', label: 'OEM'},
];

const DOC_UNIT_TYPES = [
    { value: 'SUPS', label: "Supplements 1"},
];

const OTHER_VARIABLES = [{ label: 'Custom', value: 'CUSTOM' }];
const GROUP_VARIABLES = [{ label: 'Custom', value: 'CUSTOM' }];

const TREE_SELECTION_OTHER_COMPONENTS = [
    {label: 'Supplement Approver', value: 'SUPP_APR'},
];

const FIRST_REVISION = '1';

const PREVIEW_DOC_UNIT_DOC = 'NZ_SUPP1';

dayjs.extend(utc);
dayjs.extend(timezone);

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

const getPredefinedField = (fieldName) => {
    const f = PREDEFINED_FIELDS.find((field) => field.name === fieldName);
    return f;
};

function FormErrorDialog(props) {
    const {
        title,
        message,
        open,
        onClose,
        ...other
    } = props;

    const onOKClick = () => {
        if (typeof onClose === 'function') {
            onClose();
        }
    };

    const onLocalClose = () => {
        if (typeof onClose === 'function') {
            onClose();
        }
    };

    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onLocalClose}
            PaperProps={{
                sx: {
                    minWidth: 500,
                    maxHeight: 600
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold', backgroundColor: '#d32f2f' }}>{title}</DialogTitle>
            <DialogContent>

                <Box sx={{ paddingTop: '2ex' }}>
                    <Typography>
                        {message}
                    </Typography>
                </Box>

            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onOKClick} sx={{ minWidth: '7em' }} >Close</Button>
            </DialogActions>
        </Dialog>

    );

}

function ExportFormDialog(props) {
    const {
        title = 'Export Form',
        downloadLabel='Export',
        open,
        files,
        onCancel,
        onExport,
        ...other
    } = props;

    const [startExport, setStartExport] = useState(false);

    useEffect( () => {
        if ( open && files ) {
            console.log('EXPORT DIALOG: ' + JSON.stringify(files));
            setStartExport(false);
        }

    },[open,files]);

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if ( typeof onCancel === 'function' ) {
            onCancel();
        }
    };

    const onDone = (count) => {
        setStartExport(false);
        if ( typeof onExport === 'function') {
            console.log('Export ' + count + ' file: ' + JSON.stringify(files));
            onExport(count);
        }
    }

    const onExportClick = () => {
        setStartExport(true);
    }


    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                  minWidth: 700,
                  maxHeight: 600
                }
              }}
        >
            <DialogTitle sx={{fontWeight: 'bold'}}>{title}</DialogTitle>
            <DialogContent>
                <FileDownloader files={files} onDone={onDone} downloadLabel={downloadLabel} omitStart={true} startAction={startExport} />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onExportClick} sx={{minWidth: '7em'}}>Export</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{minWidth: '7em'}}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}


function TargetRow(props) {
    const { file, onLabelChange, onItemClick, itemSelected, ...other } = props;
    const [selected, setSelected] = React.useState(false);
    const [label, setLabel] = React.useState('');
    const theme = useTheme();

    React.useEffect(() => {
        setLabel(file.label);
    }, [file]);

    React.useEffect(() => {
        setSelected(itemSelected);
    }, [itemSelected]);

    const handleItemClick = (event) => {
        if (typeof onItemClick === 'function') {
            onItemClick(event);
        }
    };

    const handleLabelChange = (event) => {
        setLabel(event.target.value);
        file.label = event.target.value;
        if (typeof onLabelChange === 'function') {
            onLabelChange(event.target.value);
        }
    };

    return (
        <React.Fragment>
            <TableRow sx={{
                '& > *': {
                    borderBottom: 'unset', padding: 0,
                    [theme.breakpoints.up('lg')]: { borderBottom: 'unset', padding: 0 },
                    [theme.breakpoints.down('lg')]: { borderBottom: 'unset', padding: 0 }
                }
            }}>
                <TableCell padding="none" size="small" sx={{
                    borderBottom: 'unset', padding: 0,
                    [theme.breakpoints.up('lg')]: { borderBottom: 'unset', padding: 0 },
                    [theme.breakpoints.down('lg')]: { borderBottom: 'unset', padding: 0 }
                }}>
                    <List sx={{ padding: '0.5ex' }} >
                        <ListItem dense>
                            <ListItemButton onClick={handleItemClick} selected={selected} >
                                <ListItemText primary={file.path.slice(file.path.lastIndexOf('/') + 1)} sx={{
                                    padding: 0, margin: 0,
                                    [theme.breakpoints.up('lg')]: { padding: 0, margin: 0 },
                                    [theme.breakpoints.down('lg')]: { padding: 0, margin: 0 }
                                }} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </TableCell>
                <TableCell sx={{
                    width: '50%', borderBottom: 'unset', padding: 0,
                    [theme.breakpoints.up('lg')]: { borderBottom: 'unset', padding: 0 },
                    [theme.breakpoints.down('lg')]: { borderBottom: 'unset', padding: 0 }
                }}><TextField value={label} onChange={handleLabelChange} size="small" variant="standard" sx={{ width: '100%' }} /></TableCell>
            </TableRow>
        </React.Fragment>
    );
}

function UploadFormFileDialog(props) {
    const {
        open,
        onCancel,
        onSave,
        title = 'Select Files to Upload',
        accept = { 'application/pdf': ['.pdf'], 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']} ,
        maxFiles = 1,
        ...other
    } = props;

    // const [selected, setSelected] = React.useState([]);
    // const [target, setTarget] = React.useState([]);
    // const [selectedTarget, setSelectedTarget] = React.useState([]);
    const [files, setFiles] = React.useState([]);
    const [progressValue, setProgressValue] = React.useState([]);
    const [progressFactor, setProgressFactor] = React.useState(1);
    const [progressShow, setProgressShow] = React.useState(false);
    const fileUploadProgress = useSelector((state) => state.fileUpload.progress);
    const [errorOpen, setErrorOpen] = React.useState(false);
    const [errorTitle, setErrorTitle] = React.useState('Upload Error');
    const [errorMessage, setErrorMessage] = React.useState('An error occurred uploading files. Please try again or consult administrator if problem persists.');

    const theme = useTheme();

    React.useEffect(() => {
        setFiles([]);
        setErrorOpen(false);
    }, [open]);

    React.useEffect(() => {
        setProgressValue(fileUploadProgress / progressFactor);
    }, [fileUploadProgress, progressFactor]);

    /*
    const doSingleClick = (sel, index) => {
        if ( sel.length === 1 && sel.includes(index) ) {
            const i = sel.indexOf(index);
            sel.splice(i,1);
            setSelectedTarget(sel);
        } else {
            sel.splice(0, sel.length);
            sel.push(index);
            setSelectedTarget(sel);
        }
    };
    */

    /*
    const handleItemClick = (event, index) => {
        // handle Ctrl and Shift clicks for multiple selections
        // console.log('ctrl: ' + event.ctrlKey + ' shift: ' + event.shiftKey);
        const sel = [ ...selectedTarget];
        if ( event.ctrlKey || event.metaKey) {   // MacOS cmd key maps to metaKey on modern browsers
            if ( sel.includes(index) ) {
                const i = sel.indexOf(index);
                sel.splice(i,1);
            } else {
                sel.push(index);
            }
            setSelectedTarget(sel);
        } else if ( event.shiftKey ) {
            if ( sel.length > 0 ) {
                // add all items from last index
                const last = sel[sel.length -1];
                if ( index === last ) {
                    doSingleClick(sel, index);
                } else if ( index > last ) {
                    for(let i=last+1; i<=index; i++) {
                        sel.push(i);
                    }
                    setSelectedTarget(sel);
                } else {
                    for(let i=last-1; i>=index; i--) {
                        sel.push(i);
                    }
                    setSelectedTarget(sel);
                }
            } else {
                doSingleClick(sel, index);
            }
        } else {
            doSingleClick(sel, index);
        }
    };
    */

    /*
    const uploadFiles = () => {
        const allFiles = [];
        const localFiles = [];
        

        target.forEach( item => {
            if ( item.origin === 'F' ) {
                localFiles.push(item);
                // netUpload(item.file, `/api/upload/${item.uid}?dst=${item.path}&uid=${item.uid}`);
            } else {
                allFiles.push(item);
            }

        });
        console.log('Local files: ' + localFiles.length);
        setProgressShow(true);
        if ( localFiles.length > 0 ) {
            setProgressFactor(localFiles.length);
        } else {
            setProgressFactor(1);
        }
        setProgressValue(0);
        localFiles.reduce(function (prev, item) {
            return prev.then((value) => {
                if (value.status >= 400) {
                    return Promise.reject(value.statusText);
                } else {
                    if (value.token) {
                        console.log('value token1: ' + JSON.stringify(value.token));
                        const dest = {
                            path: value.token.path,
                            label: value.token.label,
                            uid: value.token.uid,
                            origin: 'F',
                        };
                        allFiles.push(dest);
                        console.log('Adding ' + JSON.stringify(dest));
                    }
                    return netUpload(item.file, `/api/upload/${item.uid}?dst=${item.path}&uid=${item.uid}`, item);
                }
            });
        }, Promise.resolve({})).then((value) => {
            if (value.status >= 400) {
                return Promise.reject(value.statusText);
            } else if (value.token) {
                console.log('value token2: ' + JSON.stringify(value.token));
                const dest = {
                    path: value.token.path,
                    label: value.token.label,
                    uid: value.token.uid,
                    origin: 'F',
                };
                allFiles.push(dest);
                setProgressShow(false);
                console.log('Adding ' + JSON.stringify(dest));

                setTarget(allFiles);
                if (typeof onSave === 'function') {
                    console.log('Saving ' + JSON.stringify(allFiles));
                    onSave(allFiles);
                }
            }

        }).catch(error => {
            console.log('Error uploading files. Please try again or consult administrator if problem persists.')
            setProgressShow(false);
            setErrorOpen(true);
        }); 
        console.log('Uploading files and showing progress now...');
    };
    */

    const onErrorClose = () => {
        setErrorOpen(false);
    };


    const onOkClick = () => {
        /*
        const localFiles = target.filter( item => item.origin === 'F');
        if ( localFiles.length > 0 ) {
            uploadFiles();
        } else {
            if (typeof onSave === 'function') {
                onSave(target);
            }
        }
        */
        if (typeof onSave === 'function') {
            onSave(files);
        }
    };

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };

    /*
    const handleSelectionChange = (sel,index) => {
        setSelected(sel);
    };
    */

    /*
    const handleAddClick = (event) => {
        const dest = [...target];
        selected.forEach( item => {
            const ditem = dest.find( file => file.path === item.path);
            if ( ! ditem ) {
                let titem = { ...item}; // make a copy so we can change the label without changing original
                titem.origin =  'F';
                dest.push(titem);
            }
        });
        setTarget(dest);
    };

    const handleDeleteTarget = () => {
        const rtarget = [...target];
        const starget = [...selectedTarget];
        starget.sort();
        for(let i=starget.length-1; i>=0; i--) {
            rtarget.splice(starget[i], 1);
        }
        setTarget(rtarget);
        setSelectedTarget([]);
    };
    */

    const handleFileChange = (newFile) => {
        setFiles(newFile);
        /*
        const fs = [];
        const upid = uuidv4();
        newFile.forEach((f) => {
            fs.push({
                origin: 'F',
                path: `/opt/UCS/dlib/uploads/${upid}/${f.name}`,
                label: f.name,
                file: f,
                uid: upid,
            })
        });
        setSelected(fs);
        */
        console.log('File changed: ' + JSON.stringify(newFile) + ' ' + hasNoFiles());
    };

    const hasNoFiles = () => {
        if (!files) {
            return true;
        }
        return !(Array.isArray(files) && files.length > 0);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    [theme.breakpoints.up('lg')]: {
                        width: 800,
                        minWidth: 700,
                        minHeight: 500
                    },
                    [theme.breakpoints.down('lg')]: {
                        width: '100%',
                        minWidth: '100%',
                        minHeight: '90%'
                    },
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>{title}</DialogTitle>
            <DialogContent>
                <Box sx={{ height: '310px', display: 'flex', justifyContent: 'center', alignItems: 'stretch' }}>
                    <FileUpload buttonText='Select' title="Drag 'n' drop PDF files here, or click to select files" accept={accept}
                        value={files} onChange={handleFileChange} maxFiles={maxFiles} sx={{ border: 'none', width: '100%', height: '100%' }} />
                </Box>
            </DialogContent>
            <DialogActions>
                {/*
                <Box sx={{visibility: progressShow ? 'visible' : 'hidden', minWidth: '500px'}}>
                    <LinearProgress variant="determinate" value={progressValue} />
                </Box>
                */}
                <Box>
                    <Button variant="outlined" onClick={onOkClick} sx={{ minWidth: '7em' }} disabled={!Array.isArray(files) || files.length == 0} >OK</Button>
                    <Button variant="outlined" onClick={onCancelClick} sx={{ marginLeft: '1em', minWidth: '7em' }}>Cancel</Button>
                </Box>
                {/*
                <FormErrorDialog open={errorOpen} title={errorTitle} message={errorMessage} onClose={onErrorClose} />
                */}
            </DialogActions>
        </Dialog>

    );

}

function UploadFormFile(props) {
    const {
        dest = '/opt/UCS/dlib/uploads',
        open = false,
        label,
        fieldValue = '',
        required,
        upload = false,
        onChange,
        progressShow,
        progressValue,
        maxFiles = 1,
        accept = { 'application/pdf': ['.pdf'], 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']} ,
        ...other
    } = props;

    const [fileValue, setFileValue] = React.useState([]);
    const [target, setTarget] = React.useState([]);
    const [showClear, setShowClear] = React.useState(false);
    const [nameValue, setNameValue] = React.useState('');
    const [browseOpen, setBrowseOpen] = React.useState(false);
    /*
    const [progressShow, setProgressShow] = React.useState(false);
    const [progressFactor, setProgressFactor] = React.useState(1);
    const [progressValue, setProgressValue] = React.useState(0);
    const fileUploadProgress = useSelector((state) => state.fileUpload.progress);
    */
    /*
    const [errorOpen, setErrorOpen] = React.useState(false);
    const [errorTitle, setErrorTitle] = React.useState('Upload Error');
    const [errorMessage, setErrorMessage] = React.useState('An error occurred uploading files. Please try again or consult administrator if problem persists.');
    */

    useEffect(() => {
        if (open) {
            console.log('fieldValue: ' + fieldValue);
            if (fieldValue && fieldValue !== '') {
                setNameValue(fieldValue);
                setShowClear(true);
            } else {
                setNameValue('');
                setShowClear(false);
            }
        } else {
            setNameValue('');
            setShowClear(false);
        }

    }, [fieldValue,open]);

    const title = 'Select Files to Upload';

    const onNameValueChanged = (value) => {
        setNameValue(value);
        setShowClear(true);
    }

    const onBrowseClick = () => {
        setBrowseOpen(true);
    };

    const onBrowseCancel = () => {
        setBrowseOpen(false);
    };

    const onBrowseSave = (files) => {
        setBrowseOpen(false);
        if (Array.isArray(files) && files.length > 0) {
            /*
            const f = files[0];
            setFile(f);
            setNameValue(f.name);
            setShowClear(true);
            if (typeof onChange == 'function') {
                const ff = [];
                ff.push(f);
                onChange(ff);
            }
            */
            setFileValue(files);
            if ( files.length == 1 ) {
                setNameValue(files[0].name);
            } else {
                setNameValue(files.reduce((sum, item) => sum === '' ? item.name : sum + ', ' + item.name, ''));
            }
            if (typeof onChange === 'function') {
                onChange(files);
            }
        }

    }

    const onClearHandler = () => {
        setFileValue([]);
        setNameValue('');
        setShowClear(false);
        if (typeof onChange == 'function') {
            const ff = [];
            onChange(ff);
        }
    };

    /*
    const onErrorClose = () => {
        setErrorOpen(false);
    };
    */

    return (
        <Box sx={{
            display: 'grid', gridTemplateColumns: '1fr auto', justifyItems: 'stretch',
            alignItems: 'center', justifyContent: 'stretch', columnGap: '2ex', paddingTop: '2ex'
        }}>
            <TextField label={label} value={nameValue} onChange={(event) => onNameValueChanged(event.target.value)}
                size="small" fullWidth required={required} title={nameValue}
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end" sx={{ visibility: showClear ? 'visible' : 'hidden' }} >
                            <IconButton type="button" sx={{ p: 0 }} aria-label="clear" onClick={onClearHandler}>
                                <ClearIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }} />
            <Button variant="outlined" onClick={onBrowseClick} sx={{ minWidth: '7em' }} >Select...</Button>
            <Box sx={{
                visibility: progressShow ? 'visible' : 'hidden', minWidth: '300px',
                paddingTop: '1ex', paddingBotton: '0.5ex', gridColumnStart: '1', gridColumnEnd: 'span 2'
            }}  >
                <LinearProgress variant="determinate" value={progressValue} />
            </Box>
            <UploadFormFileDialog open={browseOpen} onCancel={onBrowseCancel} onSave={onBrowseSave} title={title} maxFiles={maxFiles} />
            {/* <FormErrorDialog open={errorOpen} title={errorTitle} message={errorMessage} onClose={onErrorClose} /> */}
        </Box>
    );

};

function EvaluateFormDialog(props) {
    const {
        form,
        open,
        onSave,
        onCancel,
        preview = false,
        beforeComponent,
        afterComponent,
        workflowInstance,
        stepInstance,
        ...other
    } = props;

    const [fieldValues, setFieldValues] = useState([]);
    // const [groupValues, setGroupValues] = useState({}); // for nested radio button or checkbox groups
    const [docList, setDocList] = useState([]);
    const [docUnitList, setDocUnitList] = useState([]);
    const [partList, setPartList] = useState([]);
    const [partid, setPartid] = useState('');
    const [roleList, setRoleList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [peerList, setPeerList] = useState([]);
    const [progressShow, setProgressShow] = useState(false);
    const [progressFactor, setProgressFactor] = useState(1);
    const [progressValue, setProgressValue] = useState(0);
    const [fileUploadNameValue, setFileUploadNameValue] = useState('');
    const [fileUploadMaxFiles, setFileUploadMaxFiles] = useState(1);
    const [calledIdGenerators, setCalledIdGenerators] = useState([]);
    const fileUploadProgress = useSelector((state) => state.fileUpload.progress);


    const [errorOpen, setErrorOpen] = React.useState(false);
    const [errorTitle, setErrorTitle] = React.useState('Upload Error');
    const [errorMessage, setErrorMessage] = React.useState('An error occurred uploading files. Please try again or consult administrator if problem persists.');
    const [serverTimezone, setServerTimezone] = useState('system');

    React.useEffect(() => {
        netGet('/config/timezone')
            .then( response => response.json())
            .then( tinfo => {
                console.log('Timezone: ' + JSON.stringify(tinfo));
                setServerTimezone(tinfo.id);
            });
    },[]);

    React.useEffect(() => {
        setProgressValue(fileUploadProgress / progressFactor);
    }, [fileUploadProgress, progressFactor]);


    React.useEffect(() => {
        if (form && open) {
            let fv = [];
            let partDoc = null;
            const fvDefer = [];
            const calledIdGens = [];
            setPartList([]);
            setPartid(null);
            form.fields.forEach((item, index) => {
                if (typeof item?.value !== 'undefined') {
                    if (item?.type === 'CHECKBOX_GROUP') {
                        if (item?.properties?.selectionData) {
                            let cv = {};
                            let iv = item.value;
                            if (item.value.fields) {
                                iv = item.value.fields;
                            }
                            item.properties.selectionData.forEach(sitem => {
                                if (typeof iv[sitem.value] === 'boolean') {
                                    cv[sitem.value] = iv[sitem.value];
                                } else {
                                    cv[sitem.value] = typeof sitem.defvalue === 'boolean' ? sitem.defvalue : false;
                                }
                            });
                            fv.push({ fields: cv, other: item.value?.other ? item.value.other : {}, comp: item.value?.comp ? item.value.comp : {} });
                        } else {
                            fv.push({ fields: {}, other: {}, comp: {} });
                        }
                    } else if (item?.type === 'DATE_PICKER' || item?.type === 'TIME_PICKER' || item?.type === 'DATETIME_PICKER') {
                        // need to convert it from stored string to dayjs object
                        try {
                            const d = dayjs(item.value);
                            // fv.push(dayjs(item.value));
                            fv.push(d);
                        } catch (error ) {
                            console.log('Error converting date: ' + error);
                            fv.push(dayjs());
                        } ;
                        
                    } else if (item?.type === 'SELECTION') {
                        console.log('SELECTION ' + item.name + ' = ' + item.value);
                        if (typeof item.value === 'string') {
                            const v = { value: item.value, otherField: '', label: item.label };
                            if ( item.part ) {
                                v['part'] = item.part;
                                partDoc = item.value;
                                setPartid(v.part.number);
                            }
                            fv.push(v);
                        } else if (item.value.value) {
                            fv.push(item.value);
                            if ( item.value.part ) {
                                partDoc = item.value.value;
                                setPartid(item.value.part.number);
                            }
                        } else if (item.properties?.variableListType === 'DOC_UNIT') {
                            fv.push(item.value);
                        } else {
                            fv.push({ value: '', otherField: '', label: '' });
                        }
                    } else {
                        fv.push(item.value);
                    }
                    // console.log(`FIELD ${item.name}: ${item.value}`);
                } else if (item?.type === 'CHECKBOX') {
                    fv.push(false);
                } else if (item?.type === 'CHECKBOX_GROUP') {
                    if (item?.properties?.selectionData) {
                        let cv = {};
                        const ot = {};
                        const cp = {};
                        item.properties.selectionData.forEach(sitem => {
                            cv[sitem.value] = typeof sitem.defvalue === 'boolean' ? sitem.defvalue : false;
                            ot[sitem.value] = '';
                            cp[sitem.value] = {};
                        });
                        fv.push({ fields: cv, other: ot, comp: cp });
                    } else {
                        fv.push({ fields: {}, other: {}, comp: {} });
                    }
                } else if ( item?.type === 'SELECTION') {
                    if (typeof item?.defvalue === 'string') {
                        fv.push({ value: item.defvalue, otherField: '' });
                    } else if (item?.properties?.selectionData) {
                        let cv = '';
                        let cl = '';
                        item.properties.selectionData.forEach(si => {
                            if (typeof si.defvalue === 'boolean' && si.defvalue) {
                                cv = si.value;
                                cl = si.label;
                                // console.log('Found default: ' + JSON.stringify(si));
                            }
                        });
                        fv.push({ value: cv, otherField: item.properties?.otherField, label: cl });
                    } else {
                        fv.push({ value: item.defvalue, otherField: '', label: '' });
                    }
                } else if (item?.type === 'RADIOBUTTON_GROUP' ) {
                    if (typeof item?.defvalue === 'string') {
                        let l = item.defvalue;
                        if (item?.properties?.selectionData) {
                            const it = item.properties.selectionData.find( i => i.value === item.defvalue);
                            if ( it ) {
                                l = it.label;
                            }
                        }
                        fv.push({ value: item.defvalue, label: l, other: {}, comp: {} });
                    } else if (item?.properties?.selectionData) {
                        let cv = '';
                        let cl = '';
                        const ot = {};
                        const cp = {};
                        item.properties.selectionData.forEach(si => {
                            if (typeof si.defvalue === 'boolean' && si.defvalue) {
                                cv = si.value;
                                cl = si.label;
                                // console.log('Found default: ' + JSON.stringify(si));
                                ot[si.value] = '';
                                cp[si.value] = {};
                            }
                        });
                        fv.push({ value: cv, label: cl, other: ot, comp: cp  });
                    } else {
                        fv.push({ value: item.defvalue, other: {}, comp: {} });
                    }
                } else if ( item?.type === 'DATE_PICKER' || item?.type === 'TIME_PICKER' || item?.type === 'DATETIME_PICKER') {
                    fv.push(dayjs());
                } else if ( item?.type === 'TEXTFIELD' && item.properties?.variableListType === 'TODAY') {
                    fv.push(dayjs().format('D MMM YYYY'));
                } else if ( item?.type === 'TEXTFIELD' && item.properties?.variableListType === 'currentUserName') {
                    fv.push(UserInfo.info.givenName + ' ' + UserInfo.info.familyName);
                } else {
                    fv.push('');
                }
                if (item?.type === 'PREDEFINED' && item?.name === 'revisionNumber' && typeof item?.value === 'undefined') {
                    fv[index] = FIRST_REVISION;
                }
                if (item?.type === 'PREDEFINED' && item?.name === 'documentName' && typeof item?.value === 'undefined') {
                    fv[index] = { value: '', otherField: '' };
                }
                if (item?.type === 'PREDEFINED' && item?.name === 'roleName' && typeof item?.value === 'undefined') {
                    fv[index] = { value: '', otherField: '' };

                }
                if (item.properties?.selectionListType === 'DOCLIST' || item.properties?.selectionListType === 'documentName'
                    || item.properties?.variableListType === 'documentName' || item.properties?.variableListType === 'DOCUMENTS' || item.properties?.variableListType === 'DOCUMENTS_ONLY' || (item?.type === 'PREDEFINED' && item?.name === 'documentName')) {
                        let categs = '';
                        if ( Array.isArray(item.properties.categs) ) {
                            const cc = item.properties.categs.reduce( (a, c) => {
                                if ( a.length > 0 ) {
                                    return a + ',' + c;
                                }
                                return c;
                            }, '');
                            categs = cc !== '' ? `&cat=${encodeURIComponent(cc)}` : '';
                        }
                    const url = '/api/doc/roots?sort=alpha' + categs;
                    console.log('Select docs URL = ' + url);
                    netGet(url)
                        .then(response => response.json())
                        .then(docs => {
                            if (Array.isArray(docs)) {
                                // console.log('Got ' + docs.length + ' documents.');
                                setDocList(docs);
                                if ( partDoc ) {
                                    loadParts(partDoc);
                                }
                            }
                        }).catch(error => {
                            console.log('Error fetching document list: ' + error);
                        });
                }
                if ((item?.type === 'PREDEFINED' && item?.name === "roleName") || (item?.type === 'SELECTION' && item?.properties?.variableListType === 'ROLES')) {
                    netGet('/api/auth/roles')
                        .then(response => response.json())
                        .then(roles => {
                            if (Array.isArray(roles)) {
                                // console.log('Got ' + docs.length + ' documents.');
                                setRoleList(roles);
                            }
                        }).catch(error => {
                            console.log('Error fetching role list: ' + error);
                        });
                }
                if (item?.type === 'SELECTION' && item?.properties?.variableListType === 'USERS') {
                    netGet('/api/auth/users')
                        .then(response => response.json())
                        .then(users => {
                            if (Array.isArray(users)) {
                                // console.log('Got ' + docs.length + ' documents.');
                                setUserList(users);
                            }
                        }).catch(error => {
                            console.log('Error fetching user list: ' + error);
                        });
                }
                if (item?.type === 'SELECTION' && item?.properties?.variableListType === 'DOC_UNIT') {
                    if (workflowInstance?.properties?.document) {
                        netGet('/api/doc/supp/list?doc=' + workflowInstance.properties.document)
                        // netGet('/api/doc/supp/list?doc=' + 'NZ_SUPP1')
                            .then(response => response.json())
                            .then(dus => {
                                if (Array.isArray(dus)) {
                                    // console.log('Got ' + docs.length + ' documents.');
                                    if ( fv[index].id ) {
                                        const du = dus.find( d => d.id === fv[index].id );
                                        if ( du ) {
                                            fv[index] = du;
                                        }
                                    }
                                    dus.sort((a,b) => a.title.localeCompare(b.title));
                                    setDocUnitList(dus);
                                }
                            }).catch(error => {
                                console.log('Error fetching supplements list: ' + error);
                            });
                    } else if ( typeof PREVIEW_DOC_UNIT_DOC === 'string' && PREVIEW_DOC_UNIT_DOC.length > 0 ) {
                        console.log('OPENING SUPP LIST: ' + PREVIEW_DOC_UNIT_DOC);
                        netGet('/api/doc/supp/list?doc=' + PREVIEW_DOC_UNIT_DOC)
                        // netGet('/api/doc/supp/list?doc=' + 'NZ_SUPP1')
                            .then(response => response.json())
                            .then(dus => {
                                if (Array.isArray(dus)) {
                                    /*
                                    const rdus = dus.map(du => {
                                        const rdu = {...du};
                                        rdu.label = du.title;
                                        return rdu;
                                    });

                                    console.log('Got ' + dus.length + ' supplements:' + JSON.stringify(rdus));
                                    */
                                    if ( fv[index].id ) {
                                        const du = dus.find( d => d.id === fv[index].id );
                                        if ( du ) {
                                            fv[index] = du;
                                        }
                                    }
                                    dus.sort((a,b) => a.title.localeCompare(b.title));
                                    setDocUnitList(dus);
                                }
                            }).catch(error => {
                                console.log('Error fetching supplements list: ' + error);
                            });
                    }
                }
                if (item?.type === 'SELECTION' && item?.properties?.variableListType === 'PEER_REVIEWERS') {
                    const params = workflowInstance ? `?id=${workflowInstance.id}` : '';
                    netGet('/api/workflow/peer/list' + params)
                        .then(response => response.json())
                        .then(peers => {
                            if (Array.isArray(peers)) {
                                // console.log('Got ' + docs.length + ' documents.');
                                const p = [ {value: 'NONE', label: 'None'}, ...peers];
                                setPeerList(p);
                            }
                        }).catch(error => {
                            console.log('Error fetching user list: ' + error);
                        });
                }
                if (item?.type === 'FILE_UPLOAD') {
                    if ( item.properties.maxFiles && item.properties.maxFiles > 1) {
                        setFileUploadMaxFiles(item.properties.maxFiles);
                    } else {
                        setFileUploadMaxFiles(1);
                    }
                    if (item.value?.label) {
                        setFileUploadNameValue(item.value.label);
                    }
                }
                if ( item?.type === 'TEXTFIELD' && item?.properties?.variableListType === 'fromFormField') {
                    // console.log('Load From field for ' + workflowInstance?.id);
                    if (workflowInstance && workflowInstance.id) {
                        
                        const frname = item.properties.targetFormName;
                        const frlabel = item.properties.targetFormLabel;
                        const ffname = item.properties.targetFieldName;
                        const fflabel = item.properties.targetFieldLabel;
                        
                        const pparams = {
                            type: 'FORM',
                            instanceId: workflowInstance?.id,
                            targetFormName: frname,
                            targetFormLabel: frlabel,
                            targetFieldName: ffname,
                            targetFieldLabel: fflabel,
                            index: index,
                        };
                        fvDefer.push(pparams);
                        // console.log('Load Form Field params: ' + JSON.stringify(pparams));
                        /* defer
                        netPost('/api/workflow/form/field/info', pparams)
                            .then(response => response.json())
                            .then(finfo => {
                                // console.log('Load FormField result = ' + JSON.stringify(finfo));
                                if (finfo.value) {
                                    // const ffv = [...fv];
                                    fv[index] = finfo.value;
                                    setFieldValues(fv);
                                }

                            }).catch(error => {
                                console.log('Error fetching form field info: ' + error);
                            });
                        */
                    }
                }
                if ( item?.type === 'TEXTFIELD' && item?.properties?.variableListType === 'idGenerator') {
                    if ( !fv[index] || fv[index] === '') {
                        if ( item?.properties?.targetName && item.properties.targetName !== '') {
                            // const url = preview ? '/api/workflow/id/try' : '/api/workflow/id/next';
                            const url =  '/api/workflow/id/try';
                            const pparams = {
                                type: 'ID',
                                url: url,
                                try: true, // always use preview, only commit on OK click
                                name: item.properties.targetName,
                                index: index,
                            };
                            fvDefer.push(pparams);
                            if ( !preview ) {
                                calledIdGens.push({
                                    field: item.name,
                                    params: {
                                        type: 'ID',
                                        url:  '/api/workflow/id/next',
                                        try: false,
                                        name: item.properties.targetName,
                                        index: index
                                    }
                                });
                            }
                            /* defer
                            netGet(url + '?name=' + item.properties.targetName)
                                .then(response => response.json())
                                .then(finfo => {
                                    // console.log('Load FormField result = ' + JSON.stringify(finfo));
                                    if (finfo.value) {
                                        // const ffv = [...fv];
                                        fv[index] = finfo.value;
                                        setFieldValues(fv);
                                    }

                                }).catch(error => {
                                    console.log('Error fetching ID next value: ' + error);
                                });
                            */
                        }

                    }
                }
                if ( item?.type === 'TEXTFIELD' && item?.properties?.variableListType === 'document') {
                    const url = '/api/workflow/doc/current';
                    const pparams = {
                        type: 'DOC',
                        url: url,
                        instanceId: workflowInstance?.id,
                        target: 'CURRENT',
                        index: index
                    };
                    fvDefer.push(pparams);
                }
                if ( item?.type === 'TEXTFIELD' && item?.properties?.variableListType === 'roRevisionNumber') {
                    const url = '/api/workflow/doc/revnum';
                    const pparams = {
                        type: 'DOC',
                        url: url,
                        instanceId: workflowInstance?.id,
                        target: 'REVNUM',
                        index: index
                    };
                    fvDefer.push(pparams);
                }
                
                // console.log('FIELD ' + item?.name + ' = ' + JSON.stringify(fv[index]) + ', defvalue = ' + JSON.stringify(item?.defvalue));
            });
            setFieldValues(fv);
            setCalledIdGenerators(calledIdGens);
            if ( fvDefer.length > 0 ) {
                const params = {
                    requests: fvDefer,
                };
                netPost('/api/workflow/multi', params)
                    .then(response => response.json())
                    .then(data => {
                        // console.log('Load Deferred results = ' + JSON.stringify(data));
                        if (Array.isArray(data.results)) {
                            const ffv = [...fv];
                            data.results.forEach(item => {
                                if (item.value && item.index >= 0) {
                                    ffv[item.index] = item.value;
                                }
                            });
                            if ( workflowInstance?.properties?.part ) {
                                const part = workflowInstance.properties.part;
                                const docReqs = fvDefer.filter( d => d.type === 'DOC');
                                console.log('doc requests = ' + JSON.stringify(docReqs));
                                if (Array.isArray(docReqs)) {
                                    docReqs.forEach(item => {
                                        if (item.target === 'CURRENT') {
                                            const v = ffv[item.index] + ', ' + part.number + ' ' + part.title;
                                            ffv[item.index] = v;
                                            console.log('Overriding form doc = ' + v);
                                        } else if (item.target === 'REVNUM') {
                                            ffv[item.index] = part.revnum;
                                            console.log('Overriding form revnum = ' + ffv[item.index]);
                                        }
                                    });
                                }
                            }
                            setFieldValues(ffv);
                        }

                    }).catch(error => {
                        console.log('Error fetching form field info: ' + error);
                    });
            }
        } else {
            setFieldValues([]);
        }

    }, [form, open]);

    const clearForm = () => {
        // TODO

    };

    const onErrorClose = () => {
        setErrorOpen(false);
    };

    const uploadFiles = (localFiles, fv) => {

        setProgressShow(true);
        const allFiles = [];
        /*
        const localFiles = [];
        const upid = uuidv4();
        localFiles.push({
            origin: 'F',
            path: `${dest}/${upid}/${file.name}`,
            label: file.name,
            file: file,
            uid: upid,
        });
        */
        // console.log('Local files: ' + localFiles.length);
        setProgressFactor(1);
        setProgressValue(0);
        localFiles.reduce(function (prev, item) {
            return prev.then((value) => {
                if (value.status >= 400) {
                    return Promise.reject(value.statusText);
                } else {
                    if (value.token) {
                        console.log('value token1: ' + JSON.stringify(value.token));
                        const dest = {
                            path: value.token.path,
                            label: value.token.label,
                            uid: value.token.uid,
                            origin: 'F',
                        };
                        allFiles.push(dest);
                        console.log('Adding ' + JSON.stringify(dest));
                    }
                    return netUpload(item.file, `/api/upload/${item.uid}?dst=${item.path}&uid=${item.uid}`, item);
                }
            });
        }, Promise.resolve({})).then((value) => {
            if (value.status >= 400) {
                return Promise.reject(value.statusText);
            } else if (value.token) {
                console.log('value token2: ' + JSON.stringify(value.token));
                const dest = {
                    path: value.token.path,
                    label: value.token.label,
                    uid: value.token.uid,
                    origin: 'F',
                };
                allFiles.push(dest);
                setProgressShow(false);
                console.log('Adding ' + JSON.stringify(dest));

                // setTarget(allFiles);
                // onSave(fv);
                onCommit(fv);
            }

        }).catch(error => {
            console.log('Error uploading files. Please try again or consult administrator if problem persists.')
            setProgressShow(false);
            setErrorOpen(true);
        });
        console.log('Uploading files and showing progress now...');

    };


    const onOKClick = () => {
        if (typeof onSave === 'function') {
            let fv = {};
            if (Array.isArray(form?.fields)) {
                form.fields.forEach((item, index) => {
                    fv[item.name] = fieldValues[index];
                    if (item.type === 'CHECKBOX') {
                        if (typeof fieldValues[index] === 'undefined') {
                            fv[item.name] = false;
                        }
                    }
                });
            }
            let files = [];
            if (Array.isArray(form?.fields)) {
                form.fields.forEach((field, index) => {
                    if (field.type === 'FILE_UPLOAD' && (field.properties?.variableListType === 'DCR' || field.properties?.variableListType === 'OEM' 
                        || field.properties?.variableListType === 'DOC')) {
                        if ( fieldValues[index]?.file ) {
                            files.push(fieldValues[index]);
                        } else if ( Array.isArray(fieldValues[index]?.files) ) {
                            files = [ ...fieldValues[index].files];
                        }
                    }
                });
            }
            if (files.length > 0 && !preview) {
                uploadFiles(files, fv);
            } else {
                onCommit(fv);
            }
        }
    };

    const onCommit = (values) => {
        if ( Array.isArray(calledIdGenerators) && calledIdGenerators.length > 0) {
            const pp = [];
            calledIdGenerators.forEach( item => {
                pp.push(item.params);
            });
            const params = {
                requests: pp,
            };
            netPost('/api/workflow/multi', params)
                .then(response => response.json())
                .then(data => {
                    // console.log('Load Deferred results = ' + JSON.stringify(data));
                    if (Array.isArray(data.results)) {
                        const ffv = [...fieldValues];
                        data.results.forEach(item => {
                            if (item.value && item.index >= 0) {
                                ffv[item.index] = item.value;
                            }
                        });
                        setFieldValues(ffv);
                        calledIdGenerators.forEach(item => {
                            values[item.field] = ffv[item.params.index];
                        });
                        if ( typeof onSave === 'function') {
                            onSave(values);
                        }
                    }

                }).catch(error => {
                    console.log('Error fetching form field info: ' + error);
                });
        } else if ( typeof onSave === 'function') {
            onSave(values);
        }

    };

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };

    const onValueChanged = (value, index) => {
        let fv = [...fieldValues];
        fv[index] = value;
        setFieldValues(fv);
        // console.log('Value changed [' + index + '] = ' + JSON.stringify(value));
        if (form && Array.isArray(form.fields)) {
            const field = form.fields[index];
            if (field?.type === 'DATE_PICKER' && field.properties?.variableListType === 'RD_OEM') {
                const ti = form.fields.findIndex(item =>
                    (item.type === 'DATE_PICKER' && item.properties?.variableListType === 'RD_TARGET')
                );
                if (ti >= 0) {
                    let oemDate = dayjs(value);

                    fv[ti] = oemDate.add(3, 'M');
                    setFieldValues(fv);
                }
            } else if (field?.type === 'TEXTFIELD' && field.properties?.variableListType === 'CUSTOM') {
                const max = field.properties.maxChars;
                if ( max > 0 && value.length > max ) {
                    fv[index] = value.substring(0, max);
                    setFieldValues(fv);
                }
                if ( field.properties.translate === 'UPPER' ) {
                    fv[index] = fv[index].toUpperCase();
                } else if (field.properties.translate === 'LOWER') {
                    fv[index] = fv[index].toLowerCase();
                }
            }
        }
    };

    const onDocumentPartValueChanged = (value,index) => {
        let fv = [...fieldValues];
        const p = partList.find( item => item.number === value);
        if ( p ) {
            setPartid(p.number);
            fv[index]['part'] = p;
            console.log('selected part: ' + JSON.stringify(p));
            const rni = form.fields.findIndex(item =>
                (item.type === 'PREDEFINED' && (item.properties?.predefined?.name === 'revisionNumber' || item.properties?.variableListType === 'revisionNumber')) ||
                (item.type === 'TEXTFIELD' && item.properties?.variableListType === 'revisionNumber')
            );
            console.log('selected revnum field: ' + JSON.stringify(rni));
            if ( rni >= 0 && p && p.revnum ) {
                fv[rni] = p.revnum;
                setFieldValues(fv);
            }
        }
    };

    const loadParts = (docid) => {
        netGet('/api/doc/parts/' + encodeURIComponent(docid))
        .then(response => response.json())
        .then(parts => {
            if ( Array.isArray(parts) ) {
                setPartList(parts);
            }
        }).catch(error => console.log('Error retrieving document parts: ' + error));
    };

    const onGroupValueChanged = (value, index) => {
        console.log('SELECTION [' + index + '] = ' + value);
        let fv = [...fieldValues];
        if (typeof fv[index] === 'string') {
            fv[index] = { value: value, otherField: '' };
        } else {
            fv[index]['value'] = value;
        }
        if (form && Array.isArray(form.fields)) {
            const field = form.fields[index];
            if ((field.type === 'PREDEFINED' && (field.name === 'documentName' || field.properties?.predefined?.type === 'SELECTION')) ||
                (field.type === 'SELECTION' && field.properties?.variableListType === 'DOCUMENTS')) {
                const rni = form.fields.findIndex(item =>
                    (item.type === 'PREDEFINED' && (item.properties?.predefined?.name === 'revisionNumber' || item.properties?.variableListType === 'revisionNumber')) ||
                    (item.type === 'TEXTFIELD' && item.properties?.variableListType === 'revisionNumber')
                );
                const doc = docList.find(d => d.id === value);
                if ( doc ) {
                    fv[index]['label'] = doc.title;
                    
                    if ( doc.category?.includes('PARTED') ) {
                        // console.log('Found parted doc: ' + JSON.stringify(doc));
                        setPartid('');
                        loadParts(doc.id);
                        /*
                        netGet('/api/doc/parts/' + encodeURIComponent(doc.id))
                            .then(response => response.json())
                            .then(parts => {
                                if ( Array.isArray(parts) ) {
                                    setPartList(parts);
                                }
                            }).catch(error => console.log('Error retrieving document parts: ' + error));
                        */
                    } else {
                        setPartList([]);
                        setPartid(null);
                    }
                }
                // console.log('Found revision number field: ' + rni);
                if (rni >= 0 && doc && doc.revnum) {
                    fv[rni] = doc.revnum;
                }
            } else if (field.type === 'SELECTION' && field.properties?.variableListType === 'USERS') {
                const uei = form.fields.findIndex(item => item.type === 'TEXTFIELD' && item.properties?.variableListType === 'userEmail');
                if (uei >= 0) {
                    const usr = userList.find(u => u.userId === value);
                    if (usr) {
                        fv[uei] = usr.email;
                        fv[index]['label'] = usr.firstName + ' ' + usr.lastName;
                    }
                }
            } else if (field.type === 'SELECTION' && field.properties?.variableListType === 'DOC_UNIT') {
                const doc = docUnitList.find(d => d.id === value);
                if ( doc ) {
                    fv[index]['label'] = doc.number + ' ' + doc.title;
                }
            } else if ( Array.isArray(field.properties?.selectionData) ) {
                const item = field.properties.selectionData.find( i => i.value === value);
                if ( item ) {
                    fv[index]['label'] = item.label;
                }
            } else if (field.type === 'SELECTION' && field.properties?.variableListType === 'DOCUMENTS_ONLY') {
                setPartList([]);
                setPartid(null);
            }
        }
        setFieldValues(fv);
        console.log('Value changed [' + index + '] = ' + JSON.stringify(fv[index]));
    }

    const onFileValueChanged = (value, index) => {
        console.log('File value changed: ' + JSON.stringify(value));
        if (Array.isArray(value) ) {
            let fv = [...fieldValues];
            if (value.length == 1) {
                // keep it backwards compatible
                const file = value[0];
                const upid = uuidv4();
                const dest = '/opt/UCS/dlib/uploads';
                fv[index] = {
                    origin: 'F',
                    path: `${dest}/${upid}/${file.name}`,
                    label: file.name,
                    file: file,
                    progressShow: false,
                    uid: upid,
                };
                if (file?.name) {
                    setFileUploadNameValue(file.name);
                }
            } else if ( value.length > 1 ) {
                console.log('FILES: ' + JSON.stringify(value));
                const ff = [];
                const dest = '/opt/UCS/dlib/uploads'
                value.forEach(item => {
                    const upid = uuidv4();
                    ff.push({
                        origin: 'F',
                        path: `${dest}/${upid}/${item.name}`,
                        label: item.name,
                        file: item,
                        progressShow: false,
                        uid: upid,
                    });
                });
                const dn = value.reduce((sum, item) => sum === '' ? item.name : sum + ', ' + item.name, '');
                fv[index] = { files: ff, label: dn};
                setFileUploadNameValue(dn);
            } else {
                fv[index] = '';
                setFileUploadNameValue('');
            }
            setFieldValues(fv);
        }
    };

    const onDocumentUnitChange = (event,value,field, index) => {
        console.log('DOC UNIT SELECTED = ' + JSON.stringify(value));
        const doc = preview ? PREVIEW_DOC_UNIT_DOC : workflowInstance?.properties?.document;
        updateSupplementForm(form.fields, fieldValues, field, index, value, doc).then( (ff) => {
            ff[index] = value;
            setFieldValues(ff);
        }).catch (error => {
            console.log(error);
            // this probably means selection was cleared
            // need to clear the form (need clear form function)
            clearForm();
        });
    };



    const documentUnitEqual = (option, value) => {
        if ( option?.id && value?.id ) {
            return option.id === value.id;
        }
        return false;
    }

    const handleCheckboxGroupChange = (val, index, cval) => {
        let cv = fieldValues[index];
        if (cv.fields) {
            cv.fields[cval] = val;
        } else {
            cv = { fields: {}, otherField: '' };
            cv.fields[cval] = val;
        }
        onValueChanged(cv, index);
    };

    const handleOtherFieldChange = (event, field, index) => {
        let fv = [...fieldValues];
        fv[index].otherField = event.target.value;
        setFieldValues(fv);
    }

    const isTextField = (field) => {
        if (field.type === 'TEXT' || field.type === 'TEXTFIELD' || (field.type === 'PREDEFINED' && (field.name === 'revisionNumber' || field.properties?.predefined?.type === 'TEXTFIELD'))) {
            return true;
        }
        if (field.type === 'PREDEFINED') {
            const pf = getPredefinedField(field.name);
            if (pf && pf.type === 'TEXTFIELD') {
                return true;
            }
        }
        return false;
    };

    const getNestedRadioItem = (ritem, index, showLabel=false) => {
        const fv = fieldValues[index];
        if (!fv.groups) {
            fv.groups = {};
        }
        if (!fv.groups[ritem.value]) {
            fv.groups[ritem.value] = {
                value: '',
                other: {},
                comp: {},
            };
        }
        ritem.children.forEach((fitem, findex) => {
            if (!fv.groups[ritem.value].value) {
                fv.groups[ritem.value].value = '';
            }
            if ( !fv.groups[ritem.value].other[fitem.value] ) {
                fv.groups[ritem.value].other[fitem.value] = '';
            }
            if ( !fv.groups[ritem.value].comp[fitem.value] ) {
                fv.groups[ritem.value].comp[fitem.value] = '';
            }
        });
        const res = (
            <Box sx={{ padding: 1, paddingLeft: 2 }}>
                <FormControl>
                    {showLabel && <FormLabel>{ritem.label}</FormLabel>}
                    <FormGroup sx={{ flexDirection: ritem.orientation === 'H' ? 'row' : 'column' }}>
                        {Array.isArray(ritem.children) &&
                            ritem.children.map((sitem, sindex) => {
                                const cntrl = (
                                    <FormControlLabel key={`${sitem.value}-${sindex}`} label={sitem.label ? sitem.label : sitem.value}
                                        control={<Radio checked={fieldValues[index].groups[ritem.value].value === sitem.value}
                                            onChange={(event) => {
                                                if (event.target.checked) {
                                                    const ffvs = [...fieldValues];
                                                    ffvs[index].groups[ritem.value].value = sitem.value;
                                                    setFieldValues(ffvs);
                                                }
                                            }} />}
                                    />
                                );
                                const other = sitem.other && fieldValues[index]?.groups[ritem.value].value === sitem.value &&
                                    <Box sx={{ paddingLeft: 2 }}>
                                        <TextField value={fieldValues[index].groups[ritem.value].other[sitem.value]}
                                            size="small" label={sitem.otherLabel ? sitem.otherLabel : 'Other'}
                                            onChange={(event) => {
                                                const ffvs = [...fieldValues];
                                                ffvs[index].groups[ritem.value].other[sitem.value] = event.target.value;
                                                setFieldValues(ffvs);
                                            }}
                                            sx={{minWidth: '90%'}}
                                            fullWidth
                                        />
                                    </Box>;
                                const comp = sitem.comp && sitem.compName === 'SUPP_APR' && fieldValues[index]?.groups[ritem.value].value === sitem.value &&
                                            <SupplementApprover />;
                                if (ritem.orientation !== 'H' && (sitem.itemType === 'radio' || sitem.itemType === 'check') &&
                                    fieldValues[index]?.groups[ritem.value].value === sitem.value) {
                                    // console.log('Nested: ' + JSON.stringify(sitem));
                                    const nested = getNestedGroupChildren(sitem, index);

                                    return (
                                        <React.Fragment>
                                            {cntrl}
                                            {other}
                                            {comp}
                                            <Box sx={{ paddingLeft: 2 }}>
                                                {nested}
                                            </Box>
                                        </React.Fragment>);
                                } else if (ritem.orientation !== 'H') {
                                    return (
                                        <React.Fragment>
                                            {cntrl}
                                            {other}
                                            {comp}
                                        </React.Fragment>
                                    );

                                } else {
                                    return cntrl;
                                }
                            })

                        }
                    </FormGroup>
                    {ritem.orientation === 'H' && Array.isArray(ritem.children) &&
                        ritem.children.map((sitem, sindex) => {
                            let r;
                            if (fieldValues[index].groups[ritem.value].value === sitem.value && sitem.other) {
                                r = (
                                    <Box sx={{ paddingLeft: 2 }}>
                                        <TextField value={fieldValues[index].groups[ritem.value].other[sitem.value]} size="small" label={sitem.otherLabel ? sitem.otherLabel : 'Other'}
                                            onChange={(event) => {
                                                const ffvs = [...fieldValues];
                                                ffvs[index].groups[ritem.value].other[sitem.value] = event.target.value;
                                                setFieldValues(ffvs);
                                            }}
                                            sx={{minWidth: '90%'}}
                                            fullWidth
                                        />
                                    </Box> );
                            }
                            return r;
                        })
                    }
                    {ritem.orientation === 'H' && Array.isArray(ritem.children) &&
                        ritem.children.map((sitem, sindex) => {

                            if (/* fd === 'column' && */ (sitem.itemType === 'radio' || sitem.itemType === 'check') &&
                                fieldValues[index].groups[ritem.value].value === sitem.value) {
                                // console.log('Nested radio:  ' + JSON.stringify(sitem));
                                const nested = getNestedGroupChildren(sitem, index);

                                return (
                                    <Box sx={{ paddingLeft: 2 }}>
                                        {nested}
                                    </Box>
                                );

                            }
                        })

                    }
                </FormControl>
            </Box>
        );
        return res;
    };

    const getNestedCheckboxItem = (ritem, index, showLabel=false) => {
        const fv = fieldValues[index];
        const fd = ritem.orientation === 'V' ? 'column' : 'row';
        // console.log('flexDirection: ' + fd);
        if (!fv.groups) {
            fv.groups = {};
        }
        if (!fv.groups[ritem.value]) {
            fv.groups[ritem.value] = {
                fields: {},
                other: {},
                comp: {},
            };
        }
        let count = 0;
        ritem.children.forEach((fitem, findex) => {
            if (!fv.groups[ritem.value].fields[fitem.value]) {
                fv.groups[ritem.value].fields[fitem.value] = false;
            }
            if (!fv.groups[ritem.value].other[fitem.value]) {
                fv.groups[ritem.value].other[fitem.value] = '';
            }
            if (!fv.groups[ritem.value].comp[fitem.value]) {
                fv.groups[ritem.value].comp[fitem.value] = '';
            }
            if ( fv.groups[ritem.value].fields[fitem.value] &&
                (fitem.itemType === 'radio' || fitem.itemType === 'check' || fitem.other)) {
                    count++;
                }
        });
        const res = (
            <Box sx={{ padding: 1, paddingLeft: 2 }}>
                <FormControl>
                    {showLabel && <FormLabel>{ritem.label}</FormLabel>}
                    <FormGroup sx={{ display: 'flex', flexDirection: fd }}>
                        {Array.isArray(ritem.children) &&
                            ritem.children.map((sitem, sindex) => {
                                const cntrl = (
                                    <FormControlLabel key={`${sitem.value}-${sindex}`} label={sitem.label ? sitem.label : sitem.value}
                                        control={<Checkbox
                                            checked={fieldValues[index].groups[ritem.value].fields[sitem.value]}
                                            onChange={(event) => {
                                                const ffvs = [...fieldValues];
                                                ffvs[index].groups[ritem.value].fields[sitem.value] = event.target.checked;
                                                setFieldValues(ffvs);
                                            }}
                                            name={`${ritem.value}-${sitem.value}`}
                                        />}
                                    />);
                                const other = sitem.other &&
                                    <Box sx={{ paddingLeft: 2 }}>
                                        <TextField value={fieldValues[index].groups[ritem.value].other[sitem.value]}
                                            size="small" label={sitem.otherLabel ? sitem.otherLabel : 'Other'}
                                            onChange={(event) => {
                                                const ffvs = [...fieldValues];
                                                ffvs[index].groups[ritem.value].other[sitem.value] = event.target.value;
                                                setFieldValues(ffvs);
                                            }}
                                            sx={{minWidth: '90%'}}
                                            fullWidth
                                        />
                                    </Box>;
                                const comp = sitem.comp && sitem.compName === 'SUPP_APR' && 
                                    <SupplementApprover />;
                                if (fd === 'column' && (sitem.itemType === 'radio' || sitem.itemType === 'check') &&
                                    fieldValues[index].groups[ritem.value].fields[sitem.value]) {
                                    // console.log('Nested: ' + JSON.stringify(sitem));

                                    const nested = getNestedGroupChildren(sitem, index);

                                    return (
                                        <React.Fragment>
                                            {cntrl}
                                            {other}
                                            {comp}
                                            <Box sx={{ paddingLeft: 2 }}>
                                                {nested}
                                            </Box>
                                        </React.Fragment>);

                                } else if (fd === 'column' && fieldValues[index].groups[ritem.value].fields[sitem.value]) {
                                    return (
                                        <React.Fragment>
                                            {cntrl}
                                            {other}
                                            {comp}
                                        </React.Fragment>);
                                } else {
                                    return cntrl;
                                }
                            })

                        }
                        {fd !== 'column' && Array.isArray(ritem.children) &&
                            ritem.children.map((sitem, sindex) => {
                                if (fieldValues[index].groups[ritem.value].fields[sitem.value]) {
                                    const other = sitem.other && (
                                            <Box sx={{ paddingLeft: 2 }}>
                                                <TextField value={fieldValues[index].groups[ritem.value].other[sitem.value]} size="small" label={sitem.otherLabel ? sitem.otherLabel : 'Other'}
                                                    onChange={(event) => {
                                                        const ffvs = [...fieldValues];
                                                        ffvs[index].groups[ritem.value].other[sitem.value] = event.target.value;
                                                        setFieldValues(ffvs);
                                                    }}
                                                    sx={{minWidth: '90%'}}
                                                    fullWidth
                                                />
                                            </Box>
                                    );
                                    const comp = sitem.comp && sitem.compName === 'SUPP_APR' && 
                                        <SupplementApprover />;
                                    if (sitem.itemType === 'radio' || sitem.itemType === 'check') {
                                        // console.log('Nested: ' + JSON.stringify(sitem));
                                       
                                        const nested = getNestedGroupChildren(sitem, index);

                                        return (
                                            <Box>
                                                {count > 1 && <Typography sx={{ color: 'rgb(0,0,0,0.6)', }}>{sitem.label}</Typography>}
                                                {other}
                                                {comp}
                                                <Box sx={{ paddingLeft: 2 }}>
                                                    {nested}
                                                </Box>
                                            </Box>);
                                    } else if (sitem.other || sitem.comp) {
                                        const r = (
                                                <Box>
                                                    {count > 1 && <Typography sx={{ color: 'rgb(0,0,0,0.6)', }}>{sitem.label}</Typography>}
                                                    {other}
                                                    {comp}
                                                </Box>
                                            );
                                        
                                        return r;
                                    }
                                }
                            })
                        }
                        
                    </FormGroup>
                </FormControl>
            </Box>
        );
        return res;
    };

    const getNestedGroupChildren = (ritem, index) => {
        if ( Array.isArray(ritem?.children) ) {            
            let res;
            if (ritem?.itemType === 'check') {    
                res = getNestedCheckboxItem(ritem, index);
            } else if (ritem?.itemType === 'radio') {
                res = getNestedRadioItem(ritem, index);
            }
            return res;
        }

    };


    const getRadioGroupChildren = (field, index, fval = fieldValues[index]?.value) => {
        if ( Array.isArray(field?.properties?.selectionData) || Array.isArray(field?.children)) {
            const sd = field.properties?.selectionData ? field.properties.selectionData : field.children;
            let res;
            const ritem = sd.find( item => item.value === fval);
            if ( ritem?.itemType === 'check' ) {
                res = getNestedCheckboxItem(ritem, index);
            } else if ( ritem?.itemType === 'radio' ) {
                res = getNestedRadioItem(ritem, index);
            }
            return res;
        }

    };

    const getCheckboxChildren = (field, index) => {
        if ( Array.isArray(field?.properties?.selectionData) ) {
            const sd = field.properties.selectionData;
            const res = [];
            const fv = fieldValues[index];
            let count = 0;
            sd.forEach( ritem => {
                if (fv?.fields && fv.fields[ritem.value] &&
                    (ritem.other || ritem.comp || ritem.itemType === 'radio' || ritem.itemType === 'check') ) {
                    count++;
                }
            });
            sd.forEach((ritem,rindex) => {
                // console.log('Checking ' + ritem.value);
                // console.log('  val=' + fieldValues[index]?.fields[ritem.value]);
                if (fv?.fields && fv.fields[ritem.value]) {
                    // console.log('CheckBox selected: ' + ritem.value);
                    if (ritem?.itemType === 'check') {
                        res.push(getNestedCheckboxItem(ritem, index, count>1));
                    } else if (ritem?.itemType === 'radio') {
                        res.push(getNestedRadioItem(ritem, index, count>1));
                    } else if ( ritem?.other ) {
                        const other = 
                            <Box sx={{ paddingTop: '1ex', paddingBottom: '1.5ex', width: '100%', paddingLeft: 2 }}>
                                {count>1 && <Typography sx={{ color: 'rgb(0,0,0,0.6)'}} >{ritem.label}</Typography>}
                                <FormControl>
                                    <TextField value={fieldValues[index].other[ritem.value]}
                                        size="small" label={ritem.otherLabel ? ritem.otherLabel : 'Other'}
                                        onChange={(event) => {
                                            const ffvs = [...fieldValues];
                                            ffvs[index].other[ritem.value] = event.target.value;
                                            setFieldValues(ffvs);
                                        }}
                                        sx={{minWidth: '90%'}}
                                        fullWidth
                                    />
                                </FormControl>
                            </Box>
                            ;
                        res.push(other);
                    } else if ( ritem?.comp && ritem?.compName === 'SUPP_APR' ) {
                        const comp = <SupplementApprover fields={form?.fields} values={fieldValues} item={ritem} 
                            index={index} itemIndex={rindex} onChange={(itemValue) => {
                                const ffvs = [...fieldValues];
                                if ( !ffvs[index].comp ) {
                                    ffvs[index].comp = {};
                                }
                                ffvs[index].comp[ritem.value] = itemValue;
                                setFieldValues(ffvs);
                            }} />;
                        res.push(comp);
                    }
                }
            });
            return res;
        }

    };

    const isReadOnlyField = (field) => {
        return field.type === 'TEXTFIELD' && (
            field.properties?.variableListType === 'TODAY' ||
            field.properties?.variableListType === 'currentUserName' ||
            field.properties?.variableListType === 'fromFormField' ||
            field.properties?.variableListType === 'idGenerator' ||
            field.properties?.variableListType === 'document' ||
            field.properties?.variableListType === 'roRevisionNumber'
        );
    };

    const getTextFieldValue = (value) => {
        if ( typeof value === 'undefined') {
            return '';
        }
        if ( value.label ) {
            return value.label;
        }
        if ( value.value ) {
            return value.value;
        }
        return value;
    }

    return (
        <Dialog
            maxWidth={'1200px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    minWidth: 900,
                    maxHeight: 650
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>{form?.label}</DialogTitle>
            <DialogContent sx={{ paddingBottom: '1ex' }}>
                {beforeComponent &&
                    <React.Fragment>
                        {beforeComponent}
                        <Divider sx={{ marginBottom: 2 }} />
                    </React.Fragment>
                }
                {form && form.fields.map((field, index) =>
                    <Box sx={{ paddingTop: '2ex' }}>
                        {isTextField(field) &&
                            <TextField label={field.label} value={getTextFieldValue(fieldValues[index])} 
                                onChange={(event) => onValueChanged(event.target.value, index)}
                                size="small" fullWidth required={field.required} InputProps={{readOnly: isReadOnlyField(field) }}
                               />
                        }
                        {field.type === 'TEXTAREA' &&
                            <TextField label={field.label} value={typeof fieldValues[index] === 'undefined' ? '' : fieldValues[index]} onChange={(event) => onValueChanged(event.target.value, index)}
                                size="small" fullWidth multiline rows={3} required={field.required} />
                        }
                        {field.type === 'CHECKBOX' &&
                            <FormControlLabel control={<Checkbox checked={typeof fieldValues[index] === 'undefined' ? false : fieldValues[index]}
                                onChange={(event) => onValueChanged(event.target.checked, index)} />} label={field.label} required={field.required} />
                        }
                        {field.type === 'DATE_PICKER' &&
                            <DatePicker label={field.required ? field.label + '*' : field.label} required={field.required} value={typeof fieldValues[index] === 'undefined' ? dayjs() : fieldValues[index]}
                                onChange={(newValue) => onValueChanged(newValue, index)} timezone={serverTimezone} />

                        }
                        {field.type === 'DATETIME_PICKER' &&
                            <DateTimePicker label={field.label} required={field.required} value={typeof fieldValues[index] === 'undefined' ? '' : fieldValues[index]}
                                onChange={(newValue) => onValueChanged(newValue, index)} timezone={serverTimezone} />

                        }
                        {field.type === 'TIME_PICKER' &&
                            <TimePicker label={field.label} required={field.required} value={typeof fieldValues[index] === 'undefined' ? '' : fieldValues[index]}
                                onChange={(newValue) => onValueChanged(newValue, index)} />

                        }
                        {field.type === 'FILE_UPLOAD' && (field.properties?.variableListType === 'DCR' || field.properties?.variableListType === 'OEM'
                            || field.properties?.variableListType === 'DOC' ) &&
                            <UploadFormFile label={field.label + (field.required ? ' *' : '')} fieldValue={fileUploadNameValue} open={open} maxFiles={fileUploadMaxFiles}
                                onChange={(newValue) => onFileValueChanged(newValue, index)} progressShow={progressShow} progressValue={progressValue} />
                        }
                        {((field.type === 'SELECTION' && field.properties?.variableListType !== 'DOC_UNIT') || (field.type === 'PREDEFINED' && (field.name === 'documentName' || field.name === 'roleName' || field.properties?.predefined?.type === 'SELECTION'))) &&
                            <Box sx={{ paddingTop: '1ex', paddingBottom: '1ex' }}>
                                <FormControl size="small" sx={{ width: '100%' }} required={field.required}>
                                    <InputLabel id={`field-select-label-${index}`} size="small">{field.label}</InputLabel>
                                    <Select
                                        labelId={`field-select-label-${index}`}
                                        id={`field-select-${index}`}
                                        value={typeof fieldValues[index]?.value === 'undefined' ? '' : fieldValues[index].value}
                                        label={field.required ? `${field.label} *` : field.label}
                                        onChange={(event) => onGroupValueChanged(event.target.value, index)}
                                        required={field.required}
                                        size="small"
                                    >
                                        {field.type === 'PREDEFINED' && (field.name === 'documentName' || field.properties?.selectionListType === 'DOCLIST' || field.properties?.selectionListType === 'documentName' ||
                                            field.properties?.variableListType === 'documentName') &&
                                            docList.map((doc, dindex) => (
                                                <MenuItem value={doc.id} key={doc.id}>{doc.title}</MenuItem>
                                            ))
                                        }
                                        {field.type === 'SELECTION' && (field.properties?.variableListType === 'DOCUMENTS' || field.properties?.variableListType === 'DOCUMENTS_ONLY') &&
                                            docList.map((doc, dindex) => (
                                                <MenuItem value={doc.id} key={doc.id}>{doc.title}</MenuItem>
                                            ))
                                        }
                                        {field.type === 'SELECTION' && field.properties?.variableListType === 'DOC_UNIT'  &&
                                            docUnitList.map((doc, dindex) => (
                                                <MenuItem value={doc.id} key={doc.id}>{`${doc.number} ${doc.title}`}</MenuItem>
                                            ))
                                        }
                                        {field.type === 'SELECTION' && field.properties?.variableListType === 'ROLES' &&
                                            roleList.map((role, rindex) => (
                                                <MenuItem value={role} key={`role-${role}-${rindex}`}>{role}</MenuItem>
                                            ))

                                        }
                                        {field.type === 'SELECTION' && field.properties?.variableListType === 'USERS' &&
                                            userList.map((user, uindex) => (
                                                <MenuItem value={user.userId} key={`user-${user.userId}-${uindex}`}>{user.firstName + ' ' + user.lastName}</MenuItem>
                                            ))

                                        }
                                        {field.type === 'SELECTION' && field.properties?.variableListType === 'PEER_REVIEWERS' &&
                                                peerList.map((peer, pindex) => (
                                                    <MenuItem value={peer.value} key={`peer-${peer.value}-${pindex}`}>{peer.label}</MenuItem>
                                                ))

                                        }
                                        {field.type === 'SELECTION' &&
                                            (field.properties?.variableListType === 'CUSTOM' || field.properties?.variableListType === '') &&
                                            Array.isArray(field.properties?.selectionData) &&

                                            field.properties.selectionData.map((sitem, sindex) => (
                                                <MenuItem value={sitem.value} key={`${sitem.value}-${sindex}`}>{sitem.label ? sitem.label : sitem.value}</MenuItem>
                                            ))

                                        }
                                    </Select>
                                </FormControl>
                                {field.properties?.other && field.properties?.other === fieldValues[index]?.value &&
                                    <Box sx={{ paddingTop: '1.5ex', width: '100%' }}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField value={fieldValues[index]?.otherField} onChange={(event) => handleOtherFieldChange(event, field, index)}
                                                label={field.properties?.otherLabel ? field.properties.otherLabel : 'Other'} size="small" fullWidth />
                                        </FormControl>
                                    </Box>
                                }
                            </Box>
                        }
                        {field.type === 'SELECTION' && field.properties?.variableListType === 'DOCUMENTS' && Array.isArray(partList) && partList.length > 0 &&
                            <Box sx={{ paddingTop: '1ex', paddingBottom: '1ex' }}>
                                <FormControl size="small" sx={{ width: '100%' }} >
                                    <InputLabel id="start-select-part-id-label" size="small">Document Part</InputLabel>
                                    <Select
                                        labelId="start-select-part-id-label"
                                        id="start-select-part-id"
                                        value={partid}
                                        label="Document Part"
                                        onChange={(event) => onDocumentPartValueChanged(event.target.value, index)}
                                        size="small"
                                        helperText="To use as default on document part operations unless a step overrides it."
                                    >
                                        <MenuItem value={'NONE'} key={'NONE'}>None</MenuItem>
                                        {Array.isArray(partList) &&
                                            partList.map((part, dindex) => (
                                                <MenuItem value={part.number} key={part.number}>{`${part.number} ${part.title}`}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        }
                        {field.type === 'SELECTION' && field.properties?.variableListType === 'DOC_UNIT' &&
                            <Box sx={{ paddingTop: '1ex', paddingBottom: '1ex' }}>
                                <Autocomplete
                                    disablePortal
                                    options={docUnitList}
                                    value={fieldValues[index] ? fieldValues[index] : null}
                                    getOptionLabel={(item) => item?.title ? item.title : ''}
                                    getOptionKey={(item) => item?.id ? item.id : 'undefined'}
                                    isOptionEqualToValue={documentUnitEqual}
                                    fullWidth
                                    onChange={(event,value) => onDocumentUnitChange(event, value, field, index)}
                                    renderInput={(params) => <TextField {...params} label={field.label} />}
/>
                            </Box>
                        }
                        

                        {field.type === 'RADIOBUTTON_GROUP' &&
                            <Box sx={{ paddingTop: '1ex', paddingBottom: '1ex' }}>
                                <Box>
                                    <FormControl>
                                        <FormLabel id={`form-radio-buttons-group-label-${field.name}`}>{field.required ? `${field.label} *` : field.label}</FormLabel>

                                        <FormGroup sx={{ flexDirection: field.properties?.orientation === 'H' ? 'row' : 'column' }}>
                                            {field.properties?.selectionData &&
                                                field.properties.selectionData.map((sitem, sindex) => {
                                                    const cntrl = (
                                                        <FormControlLabel value={sitem.value} key={`${sitem.value}-${sindex}`} label={sitem.label ? sitem.label : sitem.value}
                                                            control={<Radio checked={fieldValues[index]?.value === sitem.value}
                                                                onChange={(event) => {
                                                                    if ( event.target.checked ) {
                                                                        onGroupValueChanged(sitem.value, index);
                                                                    }
                                                                }
                                                            } />}
                                                        />);
                                                    const other = sitem.other && fieldValues[index]?.value === sitem.value && <TextField value={fieldValues[index].other[sitem.value]} 
                                                        size="small" label={sitem.otherLabel ? sitem.otherLabel : 'Other'}
                                                        onChange={(event) => {
                                                            const ffvs = [...fieldValues];
                                                            ffvs[index].other[sitem.value] = event.target.value;
                                                            setFieldValues(ffvs);
                                                        }}
                                                        sx={{minWidth: '90%'}}
                                                        fullWidth
                                                    />;
                                                    const comp = sitem.comp && fieldValues[index]?.value === sitem.value &&
                                                        <SupplementApprover/>;
                                                    if (field.properties?.orientation !== 'H' && (sitem.itemType === 'radio' || sitem.itemType === 'check') &&
                                                        fieldValues[index]?.value === sitem.value) {
                                                        // console.log('Nested: ' + JSON.stringify(sitem));
                                                        const nested = getNestedGroupChildren(sitem, index);

                                                        return (
                                                            <React.Fragment>
                                                                {cntrl}
                                                                {other}
                                                                {comp}
                                                                <Box sx={{ paddingLeft: 2 }}>
                                                                    {nested}
                                                                </Box>
                                                            </React.Fragment>);
                                                    } else if (field.properties?.orientation !== 'H') {
                                                        return (
                                                            <React.Fragment>
                                                                {cntrl}
                                                                {other}
                                                                {comp}
                                                            </React.Fragment>
                                                        );

                                                    } else {
                                                        return cntrl;
                                                    }
                                                })

                                            }
                                        </FormGroup>

                                    </FormControl>
                                </Box>
                                {field.properties?.orientation === 'H' && getRadioGroupChildren(field, index)}
                                {field.properties?.orientation === 'H' && field.properties?.selectionData &&
                                    field.properties.selectionData.map((sitem, sindex) => {
                                        const other = sitem.other && fieldValues[index]?.value === sitem.value && 
                                        <Box sx={{paddingLeft: 2, paddingTop: 1}}>
                                        <TextField value={fieldValues[index].other[sitem.value]}
                                            size="small" label={sitem.otherLabel ? sitem.otherLabel : 'Other'}
                                            onChange={(event) => {
                                                const ffvs = [...fieldValues];
                                                ffvs[index].other[sitem.value] = event.target.value;
                                                setFieldValues(ffvs);
                                            }}
                                            sx={{minWidth: '90%'}}
                                            fullWidth
                                        /></Box>;
                                        return other;
                                    })
                                }
                                
                            </Box>
                        }
                        {field.type === 'CHECKBOX_GROUP' &&
                            <Box sx={{ paddingTop: '1ex', paddingBottom: '1ex' }}>
                                <Box>
                                    <FormControl>
                                        <FormLabel id={`form-checkbox-group-label-${field.name}`}>{field.required ? `${field.label} *` : field.label}</FormLabel>
                                        <FormGroup sx={{ flexDirection: field.properties?.orientation === 'H' ? 'row' : 'column' }}>
                                            {field.properties?.selectionData &&
                                                field.properties.selectionData.map((sitem, sindex) => { 
                                                    // console.log('CHECKBOX GROUP ITEM: ' + JSON.stringify(sitem));
                                                    const cntrl = (
                                                    <FormControlLabel key={`${sitem.value}-${sindex}`} label={sitem.label ? sitem.label : sitem.value}
                                                        control={<Checkbox checked={fieldValues[index]?.fields ? fieldValues[index].fields[sitem.value] : false} 
                                                        onChange={(event) => handleCheckboxGroupChange(event.target.checked, index, sitem.value)}
                                                            name={`${field.name}-${sitem.value}`} />}
                                                    />
                                                    );
                                                    const other = sitem.other && fieldValues[index]?.fields && fieldValues[index].fields[sitem.value] &&
                                                        <Box sx={{ paddingTop: '0.5ex', width: '100%', paddingLeft: 2 }}>
                                                            <FormControl>
                                                                <TextField value={fieldValues[index].other[sitem.value]}
                                                                    size="small" label={sitem.otherLabel ? sitem.otherLabel : 'Other'}
                                                                    onChange={(event) => {
                                                                        const ffvs = [...fieldValues];
                                                                        ffvs[index].other[sitem.value] = event.target.value;
                                                                        setFieldValues(ffvs);
                                                                    }}
                                                                    sx={{minWidth: '90%'}}
                                                                    fullWidth
                                                                />
                                                            </FormControl>
                                                        </Box>
                                                        ;
                                                    const comp = sitem.comp && sitem.compName === 'SUPP_APR' && fieldValues[index]?.fields && fieldValues[index].fields[sitem.value] &&
                                                                <SupplementApprover fields={form?.fields} values={fieldValues} index={index} item={sitem} itemIndex={sindex} 
                                                                    onChange={(itemValue) => {
                                                                        const ffvs = [...fieldValues];
                                                                        if ( !ffvs[index].comp ) {
                                                                            ffvs[index].comp = {};
                                                                        }
                                                                        ffvs[index].comp[sitem.value] = itemValue;
                                                                        setFieldValues(ffvs);
                                                                    }} />;
                                                    if (field.properties?.orientation !== 'H' && (sitem.itemType === 'radio' || sitem.itemType === 'check') &&
                                                        fieldValues[index]?.fields && fieldValues[index].fields[sitem.value]) {
                                                        // console.log('Nested: ' + JSON.stringify(sitem));
                                                        const nested = getNestedGroupChildren(sitem, index);

                                                        return (
                                                            <React.Fragment>
                                                                {cntrl}
                                                                {other}
                                                                {comp}
                                                                <Box sx={{ paddingLeft: 2 }}>
                                                                    {nested}
                                                                </Box>
                                                            </React.Fragment>);
                                                    } else if (field.properties?.orientation !== 'H') {
                                                        return (
                                                            <React.Fragment>
                                                                {cntrl}
                                                                {other}
                                                                {comp}
                                                            </React.Fragment>
                                                        );
                                                    } else {
                                                        return cntrl;
                                                    }
                                                }
                                            )

                                            }
                                        </FormGroup>
                                    </FormControl>
                                </Box>
                                {field.properties?.orientation === 'H' && getCheckboxChildren(field, index)}
                            </Box>
                        }
                    </Box>
                )
                }
                {form?.fields &&
                    <Box sx={{ paddingTop: '3ex', paddingBottom: '0px' }}>
                        <Typography sx={{ fontStyle: 'italic', fontSize: '90%' }}>* indicates required input.</Typography>
                    </Box>
                }
                {afterComponent &&
                    <React.Fragment>
                        <Divider sx={{ marginTop: 2 }} />
                        {afterComponent}
                    </React.Fragment>
                }
                <FormErrorDialog open={errorOpen} title={errorTitle} message={errorMessage} onClose={onErrorClose} />
            </DialogContent>
            <DialogActions sx={{ paddingRight: 'calc(8px + 2ex)', paddingBlock: 'calc(8px + 1ex)', m: 1 }} >
                <Button variant="outlined" onClick={onOKClick} sx={{ minWidth: '7em' }} >OK</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{ minWidth: '7em' }}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );


}

function FormCheckinDialog(props) {
    const {
        title,
        open,
        formName,
        onSave,
        onCancel,
        formIndex,
        ...other
    } = props;
    const [message, setMessage] = React.useState('');


    const onOKClick = () => {
        if (typeof onSave === 'function') {
            onSave(message, formIndex);
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };

    const messageChanged = (event) => {
        setMessage(event.target.value);
    }


    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    minWidth: 500,
                    maxHeight: 600
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>{title}</DialogTitle>
            <DialogContent>

                <Box sx={{ paddingTop: '2ex' }}>
                    <TextField label="Checkin message" value={message} onChange={messageChanged} size="small" fullWidth autoFocus={true}
                        multiline rows={3} />
                </Box>

            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onOKClick} sx={{ minWidth: '7em' }} >Checkin</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{ minWidth: '7em' }}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}

function FormRevokeCheckoutDialog(props) {
    const {
        title,
        open,
        formName,
        onSave,
        onCancel,
        formIndex,
        ...other
    } = props;


    const onOKClick = () => {
        if (typeof onSave === 'function') {
            onSave(formIndex);
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };


    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    minWidth: 500,
                    maxHeight: 600
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>{title}</DialogTitle>
            <DialogContent>

                <Box sx={{ paddingTop: '2ex' }}>
                    <Typography>All changes since last checkout will be lost.</Typography>
                    <Typography>Do you want to continue?</Typography>
                </Box>

            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onOKClick} sx={{ minWidth: '7em' }} color="error">Revoke Checkout</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{ minWidth: '7em' }}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}


function CreateFormDialog(props) {
    const {
        title,
        name,
        open,
        description,
        onSave,
        onCancel,
        editOnly = false,
        ...other
    } = props;

    const [nameValue, setNameValue] = React.useState(null);
    const [descriptionValue, setDescriptionValue] = React.useState(null);
    const [templateValue, setTemplateValue] = React.useState(null);
    const [templateTypeValue, setTemplateTypeValue] = React.useState('MODEL');
    const [nameFocused, setNameFocused] = React.useState(false);
    const [okEnabled, setOkEnabled] = React.useState(false);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const CreateMenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    React.useEffect(() => {
        setNameValue(name);
        setDescriptionValue(description);
        setNameFocused(false);
        setOkEnabled(editOnly && name && typeof name == 'string' && name.length > 0);

    }, [name, description, open]);


    const onOKClick = () => {
        if (typeof onSave === 'function') {
            onSave(nameValue, descriptionValue);
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };

    const nameChanged = (event) => {
        if (event.target.value && typeof event.target.value === 'string' && event.target.value.length > 0) {
            setOkEnabled(true);
        }
        setNameValue(event.target.value);
    };

    const descriptionChanged = (event) => {
        setDescriptionValue(event.target.value);
    };

    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    minWidth: 500,
                    maxHeight: 600
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>{title}</DialogTitle>
            <DialogContent>
                <Box sx={{ paddingTop: '2ex' }}>
                    <TextField label="Name" value={nameValue} onChange={nameChanged} size="small" fullWidth autoFocus={true}
                        onFocus={event => {
                            event.target.select();
                        }} />
                </Box>
                <Box sx={{ paddingTop: '3ex' }}>
                    <TextField label="Description" value={descriptionValue} onChange={descriptionChanged} size="small"
                        multiline fullWidth
                        rows={3} />
                </Box>

            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onOKClick} sx={{ minWidth: '7em' }} disabled={!okEnabled}>OK</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{ minWidth: '7em' }}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}

function FormDeleteDialog(props) {
    const {
        title,
        open,
        formName,
        onSave,
        onCancel,
        formIndex,
        ...other
    } = props;


    const onOKClick = () => {
        if (typeof onSave === 'function') {
            onSave(formIndex);
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };


    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    minWidth: 500,
                    maxHeight: 600
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>{title}</DialogTitle>
            <DialogContent>

                <Box sx={{ paddingTop: '2ex' }}>
                    <Typography sx={{ display: 'inline' }}>Delete the </Typography><Typography sx={{ display: 'inline', fontWeight: 'bold' }}>{formName}</Typography><Typography sx={{ display: 'inline' }}> form?</Typography>
                </Box>

            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onOKClick} sx={{ minWidth: '7em' }} color="error">Delete</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{ minWidth: '7em' }}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );
}

function AddSelectionValueDialog(props) {
    const {
        title,
        open,
        onSave,
        onCancel,
        value, // { categ, select, label, defvalue, other }
        ...other
    } = props;

    const [categValue, setCategValue] = React.useState('default');
    const [selectValue, setSelectValue] = React.useState('');
    const [labelValue, setLabelValue] = React.useState('');
    const [defaultValue, setDefaultValue] = React.useState(false);
    const [otherValue, setOtherValue] = React.useState(false);
    const [okEnabled, setOkEnabled] = React.useState(false);

    const sre = /[^a-zA-Z0-9]+/g;

    React.useEffect(() => {
        setSelectValue('');
        setLabelValue('');
        if (value) {
            setCategValue(value.categ ? value.categ : 'default');
            setSelectValue(value.select ? value.select : '');
            setLabelValue(value.label ? value.label : '');
            setDefaultValue(typeof value.defvalue === 'boolean' ? value.defvalue : false);
            setOtherValue(typeof value.other === 'boolean' ? value.other : false);
        } else {
            setCategValue('default');
            setSelectValue('');
            setLabelValue('');
            setDefaultValue(false);
            setOtherValue(false);
        }
    }, [open]);


    const onOKClick = () => {
        if (typeof onSave === 'function') {
            onSave(categValue, selectValue, labelValue, defaultValue, otherValue);
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };

    const isValid = (val, label) => {
        return typeof val === 'string' && typeof label === 'string' && val !== '' && label !== '';
    };

    const categChanged = (event) => {
        setCategValue(event.target.value);
    };

    const selectChanged = (event) => {
        setSelectValue(event.target.value);
        setOkEnabled(isValid(event.target.value, labelValue));

    }

    const selectBlur = (event) => {
        if ((typeof labelValue === 'undefined' || labelValue === '') && typeof selectValue !== 'undefined') {
            setLabelValue(selectValue);
            setOkEnabled(isValid(selectValue, selectValue));
        }
    };


    const labelChanged = (event) => {
        const sl = event.target.value;
        const sv = sl.replace(sre, '_').toUpperCase() + '_' + getRandomInt(1000);
        setLabelValue(sl);
        setSelectValue(sv);
        setOkEnabled(isValid(sv, sl));
    }

    const labelBlur = (event) => {
        if ((typeof selectValue === 'undefined' || selectValue === '') && typeof labelValue !== 'undefined') {
            const val = labelValue.replace(sre, '_').toUpperCase();
            setSelectValue(val);
            setOkEnabled(isValid(val, labelValue));
        }
    };

    const handleDefaultChange = (event) => {
        setDefaultValue(event.target.checked);
    }

    const handleOtherChange = (event) => {
        setOtherValue(event.target.checked);
    }


    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    minWidth: 500,
                    maxHeight: 600
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>{title}</DialogTitle>
            <DialogContent>
                <Box sx={{ paddingTop: '2ex' }}>
                    <TextField label="Label" value={labelValue} onChange={labelChanged} size="small" fullWidth required={true} />
                </Box>
                <Box sx={{ paddingTop: '2ex' }}>
                    <FormControlLabel control={<Checkbox checked={defaultValue} onChange={handleDefaultChange} />} label="Set As Default" />
                </Box>
                <Box sx={{ paddingTop: '2ex' }}>
                    <FormControlLabel control={<Checkbox checked={otherValue} onChange={handleOtherChange} />} label="Other Field" />
                </Box>
                {/* 
                <Box sx={{ paddingTop: '2ex' }}>
                    <TextField label="Value" value={selectValue} onChange={selectChanged} size="small" fullWidth autoFocus={true}
                        onBlur={selectBlur} required={true} />
                </Box>
                <Box sx={{ paddingTop: '2ex' }}>
                    <TextField label="Category" value={categValue} onChange={categChanged} size="small" fullWidth />
                </Box>
                */}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onOKClick} sx={{ minWidth: '7em' }} disabled={!okEnabled}>OK</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{ minWidth: '7em' }}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );
}

function AddIdGeneratorDialog(props) {
    const {
        title='Create ID Generator',
        open,
        onSave,
        onCancel,
        ...other
    } = props;

    const [nameValue, setNameValue] = useState('');
    const [prefixValue, setPrefixValue] = React.useState('');
    const [labelValue, setLabelValue] = React.useState('');
    const [previewValue, setPreviewValue] = React.useState('00000001');
    const [okEnabled, setOkEnabled] = React.useState(false);

    const sre = /[^a-zA-Z0-9]+/g;

    React.useEffect(() => {
        if (open) {
            setNameValue('');
            setLabelValue('');
            setPrefixValue('');
            setPreviewValue('00000001');
        }
    }, [open]);


    const onOKClick = () => {
        if (typeof onSave === 'function') {
            onSave(nameValue, labelValue, prefixValue);
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };

    const isValid = (label, prefix) => {
        return typeof prefix === 'string' && typeof label === 'string' && prefix !== '' && label !== '';
    };

    const labelChanged = (event) => {
        const sl = event.target.value;
        const sv = sl.replace(sre, '_').toUpperCase() + '_' + getRandomInt(1000);
        setLabelValue(sl);
        setNameValue(sv);
        setOkEnabled(isValid(sl, prefixValue));
    }

    const prefixChanged = (event) => {
        const p = event.target.value;
        setPrefixValue(p);
        setPreviewValue(p + '00000001')
        setOkEnabled(isValid(labelValue, p));
    }



    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    minWidth: 500,
                    maxHeight: 600
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>{title}</DialogTitle>
            <DialogContent>
                <Box sx={{ paddingTop: '2ex' }}>
                    <TextField label="Name" value={labelValue} onChange={labelChanged} size="small" fullWidth required={true} />
                </Box>
                <Box sx={{ paddingTop: '2ex' }}>
                    <TextField label="Prefix" value={prefixValue} onChange={prefixChanged} size="small" fullWidth required={true} />
                </Box>
                <Box sx={{ paddingTop: '4ex' }}>
                    <TextField label="Preview" value={previewValue} size="small" fullWidth
                        inputProps={{readOnly: true}}/>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onOKClick} sx={{ minWidth: '7em' }} disabled={!okEnabled}>OK</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{ minWidth: '7em' }}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );
}

function AddTreeSelectionValueDialog(props) {
    const {
        title,
        open,
        onSave,
        onCancel,
        parent,
        value, // { categ, value, label, defvalue, other, otherLabel, itemType, otherComp }
        ...other
    } = props;

    const [categValue, setCategValue] = React.useState('default');
    const [selectValue, setSelectValue] = React.useState('');
    const [labelValue, setLabelValue] = React.useState('');
    const [otherValue, setOtherValue] = React.useState(false);
    const [otherLabelValue, setOtherLabelValue] = React.useState('');
    const [compValue, setCompValue] = React.useState(false);
    const [compNameValue, setCompNameValue] = React.useState('');
    const [defaultValue, setDefaultValue] = React.useState(false);
    
    const [okEnabled, setOkEnabled] = React.useState(false);
    const [typeValue, setTypeValue] = React.useState('leaf'); // leaf, radio, check
    const [orientationValue, setOrientationValue] = useState('H'); // H, V

    const sre = /[^a-zA-Z0-9]+/g;
    const TYPE_LABELS = {
        leaf: 'Leaf',
        radio: 'Radio Button Group',
        check: 'Check Box Group',
    };

    React.useEffect(() => {
        if (open) {
            setSelectValue('');
            setLabelValue('');
            if (value) {
                const val = value.value ? value.value : '';
                const label = value.label ? value.label : '';
                setCategValue(value.categ ? value.categ : 'default');
                setSelectValue(val);
                setLabelValue(label);
                setDefaultValue(typeof value.defvalue === 'boolean' ? value.defvalue : false);
                setOtherValue(typeof value.other === 'boolean' ? value.other : false);
                setOtherLabelValue(value.otherLabel ? value.otherLabel : '');
                setCompValue(typeof value.comp === 'boolean' ? value.comp : false);
                setCompNameValue(value.compName ? value.compName : '');
                setTypeValue(value.itemType ? value.itemType : 'leaf');
                console.log('VALUE=' + val + ', LABEL=' + label + ', VALID=' + isValid(val,label));
                // setOkEnabled(isValid(val, label));
                if ( value.orientation === 'V') {
                    setOrientationValue('V');
                } else {
                    setOrientationValue('H');
                }
                console.log('LOADED ORIENT: ' + value.orientation);
                setOkEnabled(false);
            } else {
                setCategValue('default');
                setSelectValue('');
                setLabelValue('');
                setDefaultValue(false);
                setOtherValue(false);
                setOtherLabelValue('');
                setCompNameValue('');
                setCompValue(false);
                setTypeValue('leaf');
                setOkEnabled(false);
                setOrientationValue('H');
            }
        }
    }, [open, value]);


    const onOKClick = () => {
        if (typeof onSave === 'function') {
            onSave({
                categ: categValue,
                name: selectValue,
                value: selectValue,
                label: labelValue,
                defvalue: defaultValue,
                other: otherValue,
                otherLabel: otherLabelValue,
                itemType: typeValue,
                orientation: orientationValue,
                comp: compValue,
                compName: compNameValue,
            });
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };

    const isValid = (val, label) => {
        return typeof val === 'string' && typeof label === 'string' && val !== '' && label !== '';
    };

    const categChanged = (event) => {
        setCategValue(event.target.value);
    };

    const selectChanged = (event) => {
        setSelectValue(event.target.value);
        setOkEnabled(isValid(event.target.value, labelValue));

    }

    const selectBlur = (event) => {
        if ((typeof labelValue === 'undefined' || labelValue === '') && typeof selectValue !== 'undefined') {
            setLabelValue(selectValue);
            setOkEnabled(isValid(selectValue, selectValue));
        }
    };


    const labelChanged = (event) => {
        const sl = event.target.value;
        const sv = sl.replace(sre, '_').toUpperCase() + '_' + getRandomInt(1000);
        setLabelValue(sl);
        setSelectValue(sv);
        setOkEnabled(isValid(sv, sl));
    }
    const otherLabelChanged = (event) => {
        setOtherLabelValue(event.target.value);
    }

    const labelBlur = (event) => {
        if ((typeof selectValue === 'undefined' || selectValue === '') && typeof labelValue !== 'undefined') {
            const val = labelValue.replace(sre, '_').toUpperCase();
            setSelectValue(val);
            setOkEnabled(isValid(val, labelValue));
        }
    };

    const handleDefaultChange = (event) => {
        setDefaultValue(event.target.checked);
        setOkEnabled(isValid(selectValue, labelValue));
    }

    const handleOtherChange = (event) => {
        setOtherValue(event.target.checked);
        if ( event.target.checked) {
            setCompValue(false);
        }
        setOkEnabled(isValid(selectValue, labelValue));
    }

    const handleCompChange = (event) => {
        setCompValue(event.target.checked);
        if ( event.target.checked) {
            setOtherValue(false);
            if ( compNameValue === '' ) {
                setCompNameValue(TREE_SELECTION_OTHER_COMPONENTS[0].value);
            }
        }
        setOkEnabled(isValid(selectValue, labelValue));
    }

    const compNameChanged = (event) => {
        setCompNameValue(event.target.value);
        setOkEnabled(isValid(selectValue, labelValue));
    }

    const onTypeValueChanged = (event) => {
        setTypeValue(event.target.value);
        setOkEnabled(isValid(selectValue, labelValue));
    }

    const onOrientationValueChanged = (event) => {
        setOrientationValue(event.target.value);
        setOkEnabled(isValid(selectValue, labelValue));
    }


    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    minWidth: 700,
                    maxHeight: 600
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>{title}</DialogTitle>
            <DialogContent>
                <Box sx={{ paddingTop: '2ex', display: 'flex' }}>
                    <Typography sx={{ fontWeight: 'bold', pr: 2 }}>Parent: </Typography>
                    <Typography>{parent?.label ? parent.label : '<Top>'}</Typography>
                </Box>
                <Box sx={{ paddingTop: '2ex' }}>
                    <TextField label="Label" value={labelValue} onChange={labelChanged} size="small" fullWidth required={true} />
                </Box>
                
                <Box sx={{ paddingTop: '3ex' }}>
                    <FormControl>
                        <FormLabel id="add-item-type"><Typography sx={{ fontWeight: 'bold' }}>Item Type</Typography></FormLabel>
                        <RadioGroup
                            row={true}
                            value={typeValue}
                            label="Item Type"
                            onChange={onTypeValueChanged}
                            size="small"
                            sx={{ paddingLeft: 3 }}
                        >
                            <FormControlLabel value="leaf" key="leaf-0" label={TYPE_LABELS['leaf']}
                                control={<Radio />} />
                            <FormControlLabel value="radio" key="radio-0" label={TYPE_LABELS['radio']}
                                control={<Radio />} />
                            <FormControlLabel value="check" key="check-0" label={TYPE_LABELS['check']}
                                control={<Radio />} />
                        </RadioGroup>
                    </FormControl>
                </Box>
                <Box sx={{ paddingTop: '2ex' }}>
                    <FormControlLabel control={<Checkbox checked={defaultValue} onChange={handleDefaultChange} />} label="Set As Default" />
                </Box>
                <Box sx={{ paddingTop: '2ex', display: 'flex' }}>
                    <FormControlLabel sx={{minWidth: '12em'}} control={<Checkbox checked={otherValue} onChange={handleOtherChange} />} label="Other Field" />
                    {otherValue && <TextField label="Other Label" value={otherLabelValue} onChange={otherLabelChanged} size="small" fullWidth />}
                </Box>
                <Box sx={{ paddingTop: '2ex', display: 'flex' }}>
                    <FormControlLabel sx={{minWidth: '12em'}} control={<Checkbox checked={compValue} onChange={handleCompChange} />} label="Other Component" />
                    {compValue && 
                        <FormControl size="small" fullWidth>
                            <InputLabel id="other-comp-label">Component</InputLabel>
                            <Select
                                labelId="other-comp-label"
                                id="other-comp-select"
                                value={compNameValue}
                                label="Component"
                                onChange={compNameChanged}
                                required={true}
                                
                            >
                                {TREE_SELECTION_OTHER_COMPONENTS.map((ov, oindex) => <MenuItem value={ov.value} key={`COMP-${ov.value}-${oindex}`} >{ov.label}</MenuItem>)}
                            </Select>
                        </FormControl>
                    }
                </Box>
                <Box sx={{ paddingTop: '3ex', visibility: (typeValue === 'radio' || typeValue === 'check') ? 'visible' : 'hidden' }}>
                    <FormControl>
                        <FormLabel id="add-item-orientation"><Typography sx={{ fontWeight: 'bold' }}>Orientation</Typography></FormLabel>
                        <RadioGroup
                            row={true}
                            value={orientationValue}
                            label="Orientation"
                            onChange={onOrientationValueChanged}
                            size="small"
                            sx={{ paddingLeft: 3 }}
                        >
                            <FormControlLabel value="H" key="H-0" label="Horizontal"
                                control={<Radio />} />
                            <FormControlLabel value="V" key="V-0" label="Vertical"
                                control={<Radio />} />
                        </RadioGroup>
                    </FormControl>
                </Box>

            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onOKClick} sx={{ minWidth: '7em' }} disabled={!okEnabled}>OK</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{ minWidth: '7em' }}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );
}

function MoreOptionsDialog(props) {
    const {
        title = 'Advanced Options',
        open,
        fieldName,
        onSave,
        onCancel,
        otherNames = [],
        ...other
    } = props;

    const [nameValue, setNameValue] = React.useState('');
    const [okEnabled, setOkEnabled] = React.useState(false);
    const [changed, setChanged] = React.useState(false);

    useEffect(() => {
        setNameValue(fieldName);
        if (open) {
            setChanged(false);
            setOkEnabled(false);
        }
    }, [open, fieldName]);

    const removeSpacesRE = /[\s'"]+/g;

    const isValid = (v, c) => {
        return typeof v === 'string' && v.length > 0 && !otherNames.includes(v) && !v.match(removeSpacesRE) && c;
    }

    const nameChanged = (event) => {
        setChanged(true);
        setNameValue(event.target.value);
        setOkEnabled(isValid(event.target.value, true));
    };

    const onOKClick = (event) => {
        if (typeof onSave === 'function') {
            onSave({ name: nameValue });
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };

    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    minWidth: 500,
                    maxHeight: 600
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>{title}</DialogTitle>
            <DialogContent>
                <Box sx={{ paddingTop: '2ex' }}>
                    <Box>
                        <Typography sx={{ fontWeight: 'bold' }}>Field Identifier</Typography>
                    </Box>
                    <Box sx={{ paddingBottom: '1em' }}>
                        <Typography>Used as variable name in templates or other objects that reference this form field.</Typography>
                    </Box>
                    <TextField value={nameValue} onChange={nameChanged} size="small" fullWidth required={true} />
                </Box>

            </DialogContent>
            <DialogActions sx={{ paddingRight: 'calc(8px + 2ex)', paddingBlock: 'calc(8px + 1ex)', m: 1 }} >
                <Button variant="outlined" onClick={onOKClick} sx={{ minWidth: '7em' }} disabled={!okEnabled}>OK</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{ minWidth: '7em' }}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );
}

function CreateFieldDialog(props) {
    const {
        title,
        field,
        open,
        onSave,
        onCancel,
        editOnly = false,
        otherNames = [],
        ...other
    } = props;

    const [nameValue, setNameValue] = React.useState(null);
    const [nameDisabled, setNameDisabled] = React.useState(false);

    const [variableList, setVariableList] = React.useState(['']);
    const [variableListValue, setVariableListValue] = React.useState('');
    const [docTypeValue, setDocTypeValue] = React.useState([]);
    const [docUnitTypeValue, setDocUnitTypeValue] = React.useState('SUPS');
    const [variableEnabled, setVariableEnabled] = React.useState(false);
    const [variableListLabel, setVariableListLabel] = React.useState('Data Source *');
    const [predefinedValue, setPredefinedValue] = React.useState({});
    const [variableLabelVisible, setVariableLabelVisible] = React.useState(true);

    const [labelValue, setLabelValue] = React.useState(null);
    const [descriptionValue, setDescriptionValue] = React.useState(null);
    const [placeholderValue, setPlaceholderValue] = React.useState(null);
    const [typeValue, setTypeValue] = React.useState(null);
    const [selectTypeVisible, setSelectTypeVisible] = React.useState(true);
    const [requiredValue, setRequiredValue] = React.useState(true);
    const [otherValue, setOtherValue] = React.useState(null); // value of 'other' selection
    const [nameFocused, setNameFocused] = React.useState(false);
    const [okEnabled, setOkEnabled] = React.useState(false);
    const [placeholderEnabled, setPlaceholderEnabled] = React.useState(false);
    const [selectionData, setSelectionData] = React.useState([]);
    const [selectionIndex, setSelectionIndex] = React.useState(-1);
    const [treeSelectionData, setTreeSelectionData] = useState([]);
    const [deleteSelectionEnabled, setDeleteSelectionEnabled] = React.useState(false);
    const [selectionDialogOpen, setSelectionDialogOpen] = React.useState(false);
    const [treeEditSelectionDialogOpen, setTreeEditSelectionDialogOpen] = useState(false);
    const [customSelectionEnabled, setCustomSelectionEnabled] = React.useState(true);
    const [editSelectionEnabled, setEditSelectionEnabled] = useState(false);
    const [orientationValue, setOrientationValue] = React.useState('H');
    const [orientationEnabled, setOrientationEnabled] = React.useState(false);

    const [moveUpItemEnabled, setMoveUpItemEnabled] = React.useState(false);
    const [moveDownItemEnabled, setMoveDownItemEnabled] = React.useState(false);

    const [addDialogTitle, setAddDialogTitle] = React.useState('Add Selection Item');
    const [addLabel, setAddLabel] = React.useState('Add Selection Item');
    const [deleteLabel, setDeleteLabel] = React.useState('Delete Selection Item');
    const [editDialogTitle, setEditDialogTitle] = useState('Edit Selection Item');

    const [itemHeaderLabel, setItemHeaderLabel] = React.useState('Selection Item');
    const [defvalue, setDefvalue] = React.useState('');
    const [moreDialogOpen, setMoreDialogOpen] = React.useState(false);


    const selectionDialogTitle = 'Add Selection Item';
    const selectionAddLabel = 'Add Selection Item';
    const selectionDeleteLabel = 'Delete Selection Item';
    const selectionHeaderLabel = 'Selection Item';

    const radioGroupTitle = 'Add Radio Button';
    const radioAddLabel = 'Add Radio Button';
    const radioDeleteLabel = 'Delete Radio Button';
    const radioHeaderLabel = 'Radio Button';

    const checkboxGroupTitle = 'Add Checkbox';
    const checkboxAddLabel = 'Add Checkbox';
    const checkboxDeleteLabel = 'Delete CheckBox';
    const checkboxHeaderLabel = 'Checkbox';

    const [selectedNodeKey, setSelectedNodeKey] = useState(null);
    const [treeSelectionDialogOpen, setTreeSelectionDialogOpen] = useState(false);
    const [parentNode, setParentNode] = useState(null);
    const [editParentNode, setEditParentNode] = useState(null);

    const [xformsList, setXformsList] = useState([]);
    const [selectedFormIndex, setSelectedFormIndex] = useState(-1);
    const [selectedFieldIndex, setSelectedFieldIndex] = useState(-1);

    const [selectedIdGenIndex, setSelectedIdGenIndex] = useState(-1);
    const [selectedIdgenValue, setSelectedIdgenValue] = useState('');
    const [selectedIdgenPreview, setSelectedIdgenPreview] = useState('');
    const [idgenList, setIdgenList] = useState([]);
    const [idGeneratorDialogOpen, setIdGeneratorDialogOpen] = useState(false);

    const [fileUploadMaxFiles, setFileUploadMaxFiles] = useState(1);
    const [textFieldMaxChars, setTextFieldMaxChars] = useState(-1);
    const [textTranslate, setTextTranslate] = useState(false);
    const [textTranslateValue, setTextTranslateValue] = useState('UPPER');

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const CreateMenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    React.useEffect(() => {
        if (field && open) {
            setNameValue(field.name);
            if (field.name.match(removeSpacesRE)) {
                setNameValue(evalVariableName(field.label));
            }
            setLabelValue(field.label);
            setDescriptionValue(field.description);
            setPlaceholderValue(field.placeholder);
            setTypeValue(field.type === 'TEXT' ? 'TEXTFIELD' : field.type);
            let vl = loadVariableList(field.type);

            setRequiredValue(field.required);
            setDefvalue(field.properties?.defvalue ? field.properties?.defvalue : '');
            setOtherValue(field.properties?.other);
            if ( field.properties?.translate ) {
                if ( field.properties.translate === 'NONE' ) {
                    setTextTranslate(false);
                    setTextTranslateValue('NONE');
                } else {
                    setTextTranslate(true);
                    setTextTranslateValue(field.properties.translate);
                }
            } else {
                setTextTranslate(false);
                setTextTranslateValue('NONE');
            }
            if ( typeof field.properties?.maxChars === 'number') {
                setTextFieldMaxChars(field.properties.maxChars);
            }
            if (field.type == 'CHECKBOX') {
                setPlaceholderEnabled(false);
            }
            if ((field.type == 'SELECTION' || field.type === 'RADIOBUTTON_GROUP' || field.type === 'CHECKBOX_GROUP')
                && field.properties && Array.isArray(field.properties.selectionData)) {
                const sd = structuredClone(field.properties.selectionData);
                setSelectionData(sd);
                const treeData = loadTreeData(sd);
                // console.log('TREE DATA: ' + JSON.stringify(treeData));
                setTreeSelectionData(treeData);
            } else {
                setSelectionData([]);
                setTreeSelectionData([]);
            }
            if ((field.type === 'RADIOBUTTON_GROUP' || field.type === 'CHECKBOX_GROUP')
                && field.properties) {
                if (field.properties.orientation === 'V') {
                    setOrientationValue('V');
                } else {
                    setOrientationValue('H');
                }
                setOrientationEnabled(true);
            } else {
                setOrientationValue('H');
                setOrientationEnabled(false);
            }
            if (field.type === 'PREDEFINED') {
                if (field.name === 'documentName') {
                    // convert to new encoding
                    setTypeValue('SELECTION');
                    vl = loadVariableList('SELECTION');

                } else
                    if (field.properties) {
                        setPredefinedValue(field.properties.predefined);
                        if (field.properties.predefined?.name) {
                            setVariableListValue(field.properties.predefined.name);
                        } else {
                            setVariableListValue(field.name);
                        }
                    } else {
                        setVariableListValue(field.name);
                    }
                setSelectTypeVisible(false);
                setNameDisabled(true);
                setVariableEnabled(true);
            } else {
                setNameDisabled(true);
                setSelectTypeVisible(false);
                setVariableEnabled(false);
            }
            setVariableEnabled(vl.length > 1);
            if (field.properties) {
                if (field.properties.variableListType || field.type === 'PREDEFINED') {
                    setVariableListValue(field.properties.variableListType);
                    setNameDisabled(field.properties.variableListType !== 'CUSTOM' || field.type === 'PREDEFINED');
                    setCustomSelectionEnabled((field.type == 'SELECTION' || field.type === 'RADIOBUTTON_GROUP' || field.type === 'CHECKBOX_GROUP') && field.properties.variableListType === 'CUSTOM');
                    if ( Array.isArray(field.properties.categs) ) {
                        setDocTypeValue(field.properties.categs);
                    } else {
                        setDocTypeValue([]);
                    }
                    if ( field.properties.duType) {
                        setDocUnitTypeValue(field.properties.duType);
                    } else {
                        setDocUnitTypeValue('SUPS');
                    }
                } else {
                    setVariableListValue('CUSTOM');
                    setCustomSelectionEnabled(true);
                    setNameDisabled(false);
                    setDocTypeValue([]);
                    setDocUnitTypeValue('SUPS');
                }
            } else {
                setVariableListValue('CUSTOM');
                setCustomSelectionEnabled(true);
                setNameDisabled(false);
                setDocTypeValue([]);
                setDocUnitTypeValue('SUPS');
            }
            loadXforms(field);
            loadIdGens(field);
        } else {
            setNameValue(null);
            setLabelValue(null);
            setDescriptionValue(null);
            setPlaceholderValue('');
            setTypeValue('');
            setSelectTypeVisible(true);
            setRequiredValue(true);
            setSelectionData([]);
            setTreeSelectionData([]);
            setVariableList(TEXT_VARIABLES);
            setVariableListValue('CUSTOM');
            setDocTypeValue([]);
            setDocUnitTypeValue('SUPS');
            setVariableEnabled(false);
            setNameDisabled(false);
            setCustomSelectionEnabled(true);
            setOrientationValue('H');
            setOrientationEnabled(false);
            setPredefinedValue({});
            setOtherValue(null);
            // setSelectionValueSource('CUSTOM');
            loadXforms();
            loadIdGens();
            setTextFieldMaxChars(-1);
            setTextTranslate(false);
            setTextTranslateValue('UPPER');
        }
        setNameFocused(false);
        setOkEnabled(editOnly && field);


    }, [field, open]);

    const loadTreeData = (data, parentKey) => {
        const treeData = data.map(item => {
            // console.log('ITEM: ' + JSON.stringify(item));
            let children = [];
            if (Array.isArray(item.children)) {
                children = loadTreeData(item.children, item.value);
            }
            const treeItem = loadTreeItem(item, item.value, parentKey, children);
            // console.log('TREE ITEM: ' + JSON.stringify(treeItem));
            return treeItem;
        });
        // console.log('TREE DATA: ' + JSON.stringify(treeData));
        return treeData;
    }

    const loadTreeItem = (item, key, parentKey, children) => {
        const itype = item.itemType ? item.itemType : (item.type ? item.type : 'leaf');
        let lp = itype === 'leaf' ? '1.5ex' : '0.5ex';
        if (!parentKey && itype === 'leaf') {
            lp = '0.2ex';
        }
        const treeItem = {
            // id: item.value,
            key: key,
            parentKey: parentKey,
            value: item.value,
            label: item.label,
            selectable: true,
            itemType: itype,
            defvalue: item.defvalue,
            other: item.other,
            otherLabel: item.otherLabel,
            comp: item.comp,
            compName: item.compName,
            orientation: item.orientation,
            children: children,
            data: {
                name: <Box sx={{display: 'inline-flex', alignItems: 'center', position: 'relative', top: itype === 'leaf' ? '0' : '0.75ex'}}>{itype === 'radio' && <RadioButtonCheckedIcon/>}{itype === 'check' && <CheckBoxIcon/>}<Typography sx={{paddingLeft: lp}}>{item.label}</Typography></Box>,
                def: item.defvalue ? <Box sx={{ textAlign: 'center', height: '1em' }}><CheckIcon /></Box> : <Box sx={{ textAlign: 'center', height: '1em' }}></Box>,
                other: item.other ? <Box sx={{ textAlign: 'center', height: '1em' }}><CheckIcon /></Box> : <Box sx={{ textAlign: 'center', height: '1em' }}></Box>
            }
        };
        // console.log('TREE ITEM: ' + JSON.stringify(treeItem));
        return treeItem;
    }

    const loadXforms = (sfield) => {
        netGet('/api/workflow/form/list')
            .then(response => response.json())
            .then(xforms => {
                setSelectedFormIndex(-1);
                setSelectedFieldIndex(-1);
                if (xforms && Array.isArray(xforms)) {
                    setXformsList(xforms);
                    if (sfield?.properties) {
                        let xform;
                        if (sfield.properties.targetFormName) {
                            xform = xforms.find(f => f.name === sfield.properties.targetFormName);
                        }
                        if (!xform && sfield.properties.targetFormLabel) {
                            xform = xforms.find(f => f.label === sfield.properties.targetFormLabel);
                        }
                        if (xform) {
                            let findex = xforms.findIndex(f => f.name === xform.name);
                            setSelectedFormIndex(findex);
                            if (Array.isArray(xform.fields)) {
                                let xfield;
                                if (sfield.properties.targetFieldName) {
                                    xfield = xform.fields.find(ff => ff.name === sfield.properties.targetFieldName);
                                }
                                if (!xfield && sfield.properties.targetFieldLabel) {
                                    xfield = xform.fields.find(ff => ff.label === sfield.properties.targetFieldLabel);
                                }
                                if (xfield) {
                                    const ffindex = xform.fields.findIndex(ff => ff.name === xfield.name);
                                    setSelectedFieldIndex(ffindex);
                                }
                            }
                        }
                    }
                } else {
                    setXformsList([]);
                }
            });
    };

    const loadIdGens = (sfield) => {
        netGet('/api/workflow/id/list')
            .then(response => response.json())
            .then(configs => {
                setSelectedIdGenIndex(-1);
                setSelectedIdgenValue('');
                setSelectedIdgenPreview('');
                if (configs && Array.isArray(configs)) {
                    setIdgenList(configs);
                    if (sfield?.properties) {
                        let config;
                        if (sfield.properties.targetId) {
                            config = configs.find(f => f.id === sfield.properties.targetId);
                        }
                        if (!config && sfield.properties.targetName) {
                            config = configs.find(f => f.name === sfield.properties.targetName);
                        }
                        if (config) {
                            let findex = configs.findIndex(f => f.name === config.name);
                            setSelectedIdGenIndex(findex);
                            setSelectedIdgenValue(config.name);
                            if ( config.properties?.value ) {
                                setSelectedIdgenPreview(config.properties.value);
                            }
                            
                        }
                    }
                } else {
                    setIdgenList([]);
                }
            });
    };

    /*
    const useFocus = () => {
        const htmlElRef = React.useRef(null)
        const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}
    
        return [ htmlElRef, setFocus ] 
    }

    const [nameRef, setVariableFocus] = useFocus();
    */
    const removeSpacesRE = /[\s'"]+/g;

    const computeVariableName = (label) => {
        if (typeof label === 'string' && label !== '') {
            let v = evalVariableName(label);
            // console.log('ComputeVariableName: ' + v);
            if (!editOnly) {
                setNameValue(v);
            }
        } else if (!editOnly) {
            setNameValue('');
        }
    };

    const evalVariableName = (label) => {
        if (typeof label === 'string' && label !== '') {
            let v = label.replace(removeSpacesRE, '');
            v = v.substring(0, 1).toLowerCase() + v.substring(1);
            let vn = 1;
            while (otherNames.includes(v)) {
                v = v + vn;
                vn++;
                if (vn > 100) {
                    break;
                }
            }
            return v;
        }
        return '';
    }

    const onOKClick = () => {
        if (typeof onSave === 'function') {
            // console.log('MY NAME: ' + nameValue + ', otherNames=' + JSON.stringify(otherNames));
            const myname = otherNames.includes(nameValue) ? evalVariableName(labelValue) : nameValue;
            let nfield = {
                name: myname,
                label: labelValue,
                description: descriptionValue,
                placeholder: placeholderValue,
                type: typeValue,
                required: requiredValue,
                properties: {
                    variableListType: variableListValue,
                    defvalue: defvalue,
                    other: otherValue
                }
            }
            
            if (typeValue === 'SELECTION' || typeValue === 'RADIOBUTTON_GROUP' || typeValue === 'CHECKBOX_GROUP') {
                const sd = [...selectionData];
                nfield['placeholder'] = '';
                nfield['properties'] = {
                    selectionData: sd,
                    selectionListType: variableListValue, // compatibility
                    variableListType: variableListValue,
                    orientation: orientationValue,
                    other: otherValue,
                };
                if ( (variableListValue === 'DOCUMENTS' || variableListValue === 'DOCUMENTS_ONLY') && docTypeValue ) {
                    nfield['properties']['categs'] = docTypeValue;
                } else if ( variableListValue === 'DOC_UNIT'  && docUnitTypeValue ) {
                    nfield['properties']['duType'] = docUnitTypeValue;
                }
            }
            if (typeValue === 'PREDEFINED') {
                const p = PREDEFINED_FIELDS.find(item => item.name === variableListValue);
                if (p) {
                    nfield['properties']['predefined'] = p;
                }
            }
            if ( typeValue === 'TEXTFIELD' ) {
                if ( textTranslate ) {
                    nfield['properties']['translate'] = textTranslateValue;
                } else {
                    nfield['properties']['translate'] = 'NONE';
                }
                if ( textFieldMaxChars >= 0 ) {
                    nfield['properties']['maxChars'] = textFieldMaxChars;
                } else {
                    nfield['properties']['maxChars'] = -1;
                }
            }
            if ( typeValue === 'TEXTFIELD' && variableListValue === 'fromFormField') {
                if ( selectedFormIndex >= 0 && Array.isArray(xformsList) && selectedFormIndex < xformsList.length) {
                    const xform = xformsList[selectedFormIndex];
                    if ( xform ) {
                        nfield['properties']['targetFormName'] = xform.name;
                        nfield['properties']['targetFormLabel'] = xform.label;
                        if ( selectedFieldIndex >= 0 && Array.isArray(xform.fields) && selectedFieldIndex < xform.fields.length ) {
                            const xfield = xform.fields[selectedFieldIndex];
                            if ( xfield ) {
                                nfield['properties']['targetFieldName'] = xfield.name;
                                nfield['properties']['targetFieldLabel'] = xfield.label;
                            }
                        }
                    }
                }                
            }
            if ( typeValue === 'TEXTFIELD' && variableListValue === 'idGenerator') {
                if ( selectedIdgenValue && selectedIdgenValue !== '' ) {
                    const gen = idgenList.find( g => g.name === selectedIdgenValue);
                    if ( gen ) {
                        nfield['properties']['targetId'] = gen.id;
                        nfield['properties']['targetName'] = gen.name;
                    }
                }
            }
            if ( typeValue === 'FILE_UPLOAD' ) {
                nfield['properties']['maxFiles'] = fileUploadMaxFiles;
            }
            console.log('Save FIELD: ' + JSON.stringify(nfield));
            onSave(nfield);
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };

    const isValid = (value) => {
        return value && typeof value === 'string' && value.length > 0;
    };

    const isFormValid = (label, varName, fieldType, varType, selData) => {
        let v = isValid(label) && isValid(varName);
        if (!v) {
            return false;
        }
        if ((varType === 'Custom' || varType === 'CUSTOM' || varType === '') && (fieldType === 'SELECTION' || fieldType === 'RADIOBUTTON_GROUP' || fieldType === 'CHECKBOX_GROUP')) {
            return Array.isArray(selData) && selData.length > 0;
        }
        return true;
    };

    const nameChanged = (event) => {
        setNameValue(event.target.value);
        setOkEnabled(isFormValid(labelValue, event.target.value, typeValue, variableListValue, selectionData));
    };

    const labelChanged = (event) => {
        setLabelValue(event.target.value);
        computeVariableName(event.target.value);
        const n = evalVariableName(event.target.value);
        const v = isFormValid(event.target.value, n, typeValue, variableListValue, selectionData);
        setOkEnabled(v);
    };

    const descriptionChanged = (event) => {
        setDescriptionValue(event.target.value);
    };

    const placeholderChanged = (event) => {
        setPlaceholderValue(event.target.value);
    };

    const loadVariableList = (type) => {
        let vl = [];
        switch (type) {
            case 'TEXTFIELD':
            case 'TEXTAREA':
                vl = TEXT_VARIABLES;
                setVariableListLabel('Data Source *');
                break;
            case 'SELECTION':
                vl = SELECTION_VARIABLES;
                setVariableListLabel('Item Data Source *');
                setAddDialogTitle(selectionDialogTitle);
                setAddLabel(selectionAddLabel);
                setDeleteLabel(selectionDeleteLabel);
                setItemHeaderLabel(selectionHeaderLabel);
                break;
            case 'RADIOBUTTON_GROUP':
                vl = GROUP_VARIABLES;
                setVariableListLabel('Item Data Source *');
                setAddDialogTitle(radioGroupTitle);
                setAddLabel(radioAddLabel);
                setDeleteLabel(radioDeleteLabel);
                setItemHeaderLabel(radioHeaderLabel);
                break;
            case 'CHECKBOX_GROUP':
                vl = GROUP_VARIABLES;
                setVariableListLabel('Item Data Source *');
                setAddDialogTitle(checkboxGroupTitle);
                setAddLabel(checkboxAddLabel);
                setDeleteLabel(checkboxDeleteLabel);
                setItemHeaderLabel(checkboxHeaderLabel);
                break;
            case 'FILE_UPLOAD':
                vl = UPLOAD_VARIABLES;
                setVariableListLabel('Target *');
                break;
            case 'DATE_PICKER':
                vl = DATE_VARIABLES;
                setVariableListLabel('Data Source *');
                break;
            default:
                vl = OTHER_VARIABLES;
                setVariableListLabel('Data Source *');
                break;
        }
        setVariableList(vl);
        setVariableEnabled(vl.length > 1);
        return vl;
    };

    const typeChanged = (event) => {
        const v = event.target.value;
        setTypeValue(v);
        // setPlaceholderEnabled(event.target.value === 'TEXT' || event.target.value === 'TEXTAREA');
        setPlaceholderEnabled(false);
        const vl = loadVariableList(v);
        setVariableListValue(vl.length > 0 ? vl[0].value : '');
        setCustomSelectionEnabled(true);
        setNameDisabled(false);
        computeVariableName(labelValue);
        setOrientationEnabled(v === 'CHECKBOX_GROUP' || v === 'RADIOBUTTON_GROUP');
        const fv = isFormValid(labelValue, evalVariableName(labelValue), v, '', selectionData);
        setOkEnabled(fv);
        // setVariableEnabled(v === 'PREDEFINED');
        // setVariableLabelVisible(v === 'PREDEFINED');
    }

    const requiredChanged = (event) => {
        setRequiredValue(event.target.checked);
    }

    const handleSelectionClicked = (event, index) => {
        setSelectionIndex(index);
        setDeleteSelectionEnabled(index >= 0);
        setMoveDownItemEnabled(index >= 0 && index < selectionData.length - 1);
        setMoveUpItemEnabled(index > 0);
    };

    const handleAddSelectionClick = () => {
        setSelectionDialogOpen(true);
    };

    const handleAddTreeSelectionClick = () => {
        setTreeSelectionDialogOpen(true);
    };

    const handleEditTreeSelectionClick = () => {
        if ( selectedNodeKey ) {
            const p = findTreeItem(selectedNodeKey, treeSelectionData, false);
            if ( p ) {
                setParentNode(p);
                const pp = findTreeItem(p.parentKey, treeSelectionData, false);
                setEditParentNode(pp);
            } else {
                setEditParentNode(null);
            }
        } else {
            setEditParentNode(null);
        }
        setTreeEditSelectionDialogOpen(true);
    };

    const handleDeleteSelectionClick = () => {
        if (selectionIndex >= 0) {
            let sd = [...selectionData];
            sd.splice(selectionIndex, 1);
            setSelectionData(sd);
            const v = isFormValid(labelValue, nameValue, typeValue, variableListValue, sd);
            // check if other field has been removed
            let hasOther = false;
            sd.forEach(item => {
                if (typeof item.other === 'boolean' && item.other) {
                    hasOther = true;
                }
            });
            if (!hasOther) {
                setOtherValue(null);
            }
            setOkEnabled(v);
            setDeleteSelectionEnabled(false);
            setEditSelectionEnabled(false);
            setMoveDownItemEnabled(false);
            setMoveUpItemEnabled(false);
        }
    };

    const handleDeleteTreeSelectionClick = () => {
        if (selectedNodeKey) {
            // console.log('Selected key: ' + selectedNodeKey);
            let sd = [...selectionData];
            let tsd = [...treeSelectionData];
            const ti = findTreeItem(selectedNodeKey, tsd, false);
            if (ti) {
                let children = tsd;
                // console.log('Delete entry: Parent key: ' + ti.parentKey);
                if (ti.parentKey) {
                    const p = findTreeItem(ti.parentKey, tsd, false);
                    if (p) {
                        //  console.log('Parent: ' + JSON.stringify(p));
                        children = p.children;
                        let index = children.findIndex(item => item.key === ti.key);
                        if (index >= 0) {
                            children.splice(index, 1);
                        }
                        p.children = children;
                    }
                } else {
                    let index = children.findIndex(item => item.key === ti.key);
                    if (index >= 0) {
                        children.splice(index, 1);
                    }
                }
                let schildren = sd;
                if (ti.parentKey) {
                    const ps = findTreeItem(ti.parentKey, sd, true);
                    if (ps) {
                        schildren = ps.children;
                        let index = schildren.findIndex(item => item.value === ti.key);
                        // console.log('Delete 1SD index = ' + index);
                        if (index >= 0) {
                            schildren.splice(index, 1);
                        }
                        ps.children = schildren;
                    }
                } else {
                    let index = schildren.findIndex(item => item.value === ti.key);
                    // console.log('Delete 2SD index = ' + index);
                    if (index >= 0) {
                        schildren.splice(index, 1);
                    }
                }
                setSelectionData(sd);
                setTreeSelectionData(tsd);
                const v = isFormValid(labelValue, nameValue, typeValue, variableListValue, sd);
                setOkEnabled(v);
                setDeleteSelectionEnabled(false);
                setEditSelectionEnabled(false);
                setMoveDownItemEnabled(false);
                setMoveUpItemEnabled(false);
            }

        }
    };

    const moveUpSelectionHandler = () => {
        if (selectionIndex > 0) {
            let sd = [...selectionData];
            const p = sd[selectionIndex - 1];
            const n = selectionIndex - 1;
            sd[selectionIndex - 1] = sd[selectionIndex];
            sd[selectionIndex] = p;
            setSelectionData(sd);
            setSelectionIndex(n);
            setMoveUpItemEnabled(n > 0);
            setMoveDownItemEnabled(n >= 0 && n < sd.length - 1);
        }
    };

    const moveDownSelectionHandler = () => {
        if (selectionIndex >= 0 && selectionIndex < selectionData.length - 1) {
            let sd = [...selectionData];
            const p = sd[selectionIndex + 1];
            const n = selectionIndex + 1;
            sd[selectionIndex + 1] = sd[selectionIndex];
            sd[selectionIndex] = p;
            setSelectionData(sd);
            setSelectionIndex(n);
            setMoveUpItemEnabled(n > 0);
            setMoveDownItemEnabled(n >= 0 && n < sd.length - 1);
        }
    };

    const moveUpTreeSelectionHandler = () => {
        if (selectedNodeKey) {
            let sd = [...selectionData];
            let tsd = [...treeSelectionData];
            const ti = findTreeItem(selectedNodeKey, tsd, false);
            if (ti) {
                let children = tsd;
                console.log('Parent key: ' + ti.parentKey);
                if (ti.parentKey) {
                    const p = findTreeItem(ti.parentKey, tsd, false);
                    if (p) {
                        //  console.log('Parent: ' + JSON.stringify(p));
                        children = p.children;
                        let index = children.findIndex(item => item.key === ti.key);
                        const prev = children[index - 1];
                        const n = index - 1;
                        children[n] = children[index];
                        children[index] = prev;
                        p.children = children;
                        setMoveUpItemEnabled(n > 0);
                        setMoveDownItemEnabled(n >= 0 && n < children.length - 1);
                    }
                } else {
                    let index = children.findIndex(item => item.key === ti.key);
                    const prev = children[index - 1];
                    const n = index - 1;
                    children[n] = children[index];
                    children[index] = prev;
                    setMoveUpItemEnabled(n > 0);
                    setMoveDownItemEnabled(n >= 0 && n < children.length - 1);
                }
                let schildren = sd;
                if (ti.parentKey) {
                    const ps = findTreeItem(ti.parentKey, sd, true);
                    if (ps) {
                        schildren = ps.children;
                        let index = schildren.findIndex(item => item.value === ti.key);
                        const prev = schildren[index - 1];
                        const n = index - 1;
                        schildren[n] = schildren[index];
                        schildren[index] = prev;
                        ps.children = schildren;
                    }
                } else {
                    let index = schildren.findIndex(item => item.value === ti.key);
                    const prev = schildren[index - 1];
                    const n = index - 1;
                    schildren[n] = schildren[index];
                    schildren[index] = prev;
                }
                setSelectionData(sd);
                setTreeSelectionData(tsd);
            }

        }
    }

    const moveDownTreeSelectionHandler = () => {
        if (selectedNodeKey) {
            let sd = [...selectionData];
            let tsd = [...treeSelectionData];
            const ti = findTreeItem(selectedNodeKey, tsd, false);
            if (ti) {
                let children = tsd;
                console.log('Parent key: ' + ti.parentKey);
                if (ti.parentKey) {
                    const p = findTreeItem(ti.parentKey, tsd, false);
                    if (p) {
                        //  console.log('Parent: ' + JSON.stringify(p));
                        children = p.children;
                        let index = children.findIndex(item => item.key === ti.key);
                        const next = children[index + 1];
                        const n = index + 1;
                        children[n] = children[index];
                        children[index] = next;
                        p.children = children;
                        setMoveUpItemEnabled(n > 0);
                        setMoveDownItemEnabled(n >= 0 && n < children.length - 1);
                    }
                } else {
                    let index = children.findIndex(item => item.key === ti.key);
                    const next = children[index + 1];
                    const n = index + 1;
                    children[n] = children[index];
                    children[index] = next;
                    setMoveUpItemEnabled(n > 0);
                    setMoveDownItemEnabled(n >= 0 && n < children.length - 1);
                }
                let schildren = sd;
                if (ti.parentKey) {
                    const ps = findTreeItem(ti.parentKey, sd, true);
                    if (ps) {
                        schildren = ps.children;
                        let index = schildren.findIndex(item => item.value === ti.key);
                        const next = schildren[index + 1];
                        const n = index + 1;
                        schildren[n] = schildren[index];
                        schildren[index] = next;
                        ps.children = schildren;
                    }
                } else {
                    let index = schildren.findIndex(item => item.value === ti.key);
                    const next = schildren[index + 1];
                    const n = index + 1;
                    schildren[n] = schildren[index];
                    schildren[index] = next;
                }
                setSelectionData(sd);
                setTreeSelectionData(tsd);
            }

        }
    }

    const dialogSaveHandler = (categ, value, label, defvalue, other) => {
        setSelectionDialogOpen(false);
        let sd = [...selectionData];
        if (typeValue === 'RADIOBUTTON_GROUP' && typeof defvalue === 'boolean' && defvalue) {
            // reset default
            sd.forEach(item => {
                item.defvalue = false;
            });
        }
        const target = selectionData.find(item => item.categ === categ && item.value === value);
        const isOther = typeof other === 'boolean' ? other : false;
        let tval = target ? value + '2' : value;
        const entry = { categ: categ, value: tval, label: label, defvalue: typeof defvalue === 'boolean' ? defvalue : false, other: isOther };
        if (typeValue === 'RADIOBUTTON_GROUP' && typeof defvalue === 'boolean' && defvalue) {
            setDefvalue(entry.value);
        }
        if (isOther) {
            setOtherValue(entry.value);
        }
        sd.push(entry);
        setSelectionData(sd);
        const v = isFormValid(labelValue, nameValue, labelValue, variableListValue, sd);
        setOkEnabled(v);
        console.log('New entry: ' + JSON.stringify(entry));
    };

    const dialogCancelHandler = () => {
        setSelectionDialogOpen(false);
    };

    const treeDialogSaveHandler = (item) => {
        setTreeSelectionDialogOpen(false);
        let sd = [...selectionData];
        let tsd = [...treeSelectionData];
        const p = findTreeItem(selectedNodeKey, tsd, false);
        const ps = findTreeItem(selectedNodeKey, sd, true);
        if (p && (p.itemType === 'radio' || p.itemType === 'check')) {
            if (!p.children) {
                p.children = [];
            }
            p.children.push(loadTreeItem(item, item.name, p.key, []));
        } else {
            tsd.push(loadTreeItem(item, item.name, null, []));
        }
        if (ps && (ps.itemType === 'radio' || ps.itemType === 'check')) {
            if (!ps.children) {
                ps.children = [];
            }
            ps.children.push({
                value: item.name,
                label: item.label,
                itemType: item.itemType,
                defvalue: item.defvalue,
                other: item.other,
                otherLabel: item.otherLabel,
                comp: item.comp,
                compName: item.compName,
            });
        } else {
            sd.push({
                value: item.name,
                label: item.label,
                itemType: item.itemType,
                defvalue: item.defvalue,
                other: item.other,
                otherLabel: item.otherLabel,
                comp: item.comp,
                compName: item.compName,
            });
        }
        setTreeSelectionData(tsd);
        setSelectionData(sd);
    };

    const treeEditDialogSaveHandler = (item) => {
        setTreeEditSelectionDialogOpen(false);
        let sd = [...selectionData];
        let tsd = [...treeSelectionData];
        const p = findTreeItem(selectedNodeKey, tsd, false);
        if ( p ) {
            if ( p.parentKey ) {
                const pp = findTreeItem(p.parentKey, tsd, false);
                if ( pp && Array.isArray(pp.children)) {
                    const pindex = pp.children.findIndex(item => item.key === p.key);
                    if ( pindex >= 0 ) {
                        const pchildren = item.itemType === 'leaf' ? [] : p.children;
                        pp.children[pindex] = loadTreeItem(item, item.name, p.parentKey, pchildren);
                    }
                }
            } else {
                const pindex = tsd.findIndex( item => item.key === p.key);
                if ( pindex >= 0 ) {
                    const pchildren = item.itemType === 'leaf' ? [] : p.children;
                    tsd[pindex] = loadTreeItem(item, item.name, null, pchildren);
                }
            }
        }

        const ps = findTreeItem(selectedNodeKey, sd, true);

        if (ps ) {
            if ( p?.parentKey ) {
                const pp = findTreeItem(p.parentKey, sd, true);
                if ( pp && Array.isArray(pp.children) ) {
                    const pindex = pp.children.findIndex(item => item.value === ps.value);
                    if ( pindex >= 0 ) {
                        pp.children[pindex] = {
                            value: item.name,
                            label: item.label,
                            itemType: item.itemType,
                            other: item.other,
                            otherLabel: item.otherLabel,
                            defvalue: item.defvalue,
                            comp: item.comp,
                            compName: item.compName,
                            children: item.itemType === 'leaf' ? [] : ps.children,
                            orientation: item.orientation,
                        };
                    }
                }
            } else {
                const pindex = sd.findIndex( item => item.value === ps.value);
                if ( pindex >= 0 ) {
                    sd[pindex] = {
                        value: item.name,
                        label: item.label,
                        itemType: item.itemType,
                        other: item.other,
                        otherLabel: item.otherLabel,
                        defvalue: item.defvalue,
                        comp: item.comp,
                        compName: item.compName,
                        children: item.itemType === 'leaf' ? [] : ps.children,
                        orientation: item.orientation,
                    };
                }
            }
        } 
        setTreeSelectionData(tsd);
        setSelectionData(sd);
    };

    const findTreeItem = (key, children, isValue) => {
        if (Array.isArray(children)) {
            for (let i = 0; i < children.length; i++) {
                const child = children[i];
                if (isValue ? child.value === key : child.key === key) {
                    return child;
                }
                if (Array.isArray(child.children)) {
                    const ci = findTreeItem(key, child.children, isValue);
                    if (ci) {
                        return ci;
                    }
                }
            }
        }

    };

    const treeDialogCancelHandler = () => {
        setTreeSelectionDialogOpen(false);
        setTreeEditSelectionDialogOpen(false);
    };

    const handleSelectedNode = (event) => {
        setSelectedNodeKey(event.value);

        const p = findTreeItem(event.value, treeSelectionData, false);
        // console.log('PARENT: ' + JSON.stringify(p));
        if ( p?.itemType === 'check' || p?.itemType === 'radio' ) {
            setParentNode(p);
        } else {
            setParentNode(null);
        }

        if (event.value) {
            setDeleteSelectionEnabled(true);
            setEditSelectionEnabled(true);
            const tsd = [...treeSelectionData];
            const ti = findTreeItem(event.value, tsd, false);
            if (ti) {
                let children = tsd;
                // console.log('Parent key: ' + ti.parentKey);
                if (ti.parentKey) {
                    const p = findTreeItem(ti.parentKey, tsd, false);
                    if (p) {
                        //  console.log('Parent: ' + JSON.stringify(p));
                        children = p.children;
                        const index = children.findIndex(item => item.key === ti.key);
                        console.log('Index = ' + index);
                        setMoveUpItemEnabled(index > 0);
                        setMoveDownItemEnabled(index < children.length - 1);
                    }
                } else {
                    const index = children.findIndex(item => item.key === ti.key);
                    console.log('Index2 = ' + index);
                    setMoveUpItemEnabled(index > 0);
                    setMoveDownItemEnabled(index < children.length - 1);
                }
            } else {

                setMoveUpItemEnabled(false);
                setMoveDownItemEnabled(false);
            }

        } else {
            setDeleteSelectionEnabled(false);
            setEditSelectionEnabled(false);
            setMoveUpItemEnabled(false);
            setMoveDownItemEnabled(false);
        }
    }

    const variableListChanged = (event) => {
        // console.log('variableListValue: ' + event.target.value);

        setVariableListValue(event.target.value);
        setCustomSelectionEnabled(event.target.value === 'CUSTOM');
        /*
        let nv = nameValue;
        let sd = selectionData;
        if (event.target.value !== 'CUSTOM') {
            setNameValue(event.target.value);
            setSelectionData([]);
            sd = [];
        } else {
            console.log('labelValue: ' + labelValue);
            computeVariableName(labelValue);
            nv = evalVariableName(labelValue);
        }
        //setNameDisabled(event.target.value !== 'Custom');
        
        const v = isFormValid(labelValue, nv, typeValue, event.target.value, sd);
        setOkEnabled(v);
        */
    };

    const orientationChanged = (event) => {
        setOrientationValue(event.target.value);
    };

    const moreIconClick = (event) => {
        setMoreDialogOpen(true);
    };

    const moreDialogSave = (moreOptions) => {
        setMoreDialogOpen(false);
        if (moreOptions?.name) {
            setNameValue(moreOptions.name);
        }
    };

    const handleFormItemClick = (index) => {
        const oldv = selectedFormIndex;
        setSelectedFormIndex(index);
        if ( index >= 0 && index !== oldv ) {
            setSelectedFieldIndex(-1);
        }
    };

    const handleFieldItemClick = (index) => {
        setSelectedFieldIndex(index);
    };

    const idGeneratorChanged = (event) => {
        setSelectedIdgenValue(event.target.value);
        const c = idgenList.find( i => i.name === event.target.value);
        if ( c?.properties?.value ) {
            setSelectedIdgenPreview(c.properties.value);
        }
    };

    const handleAddGeneratorClick = () => {
        setIdGeneratorDialogOpen(true);
    };

    const handleAddGeneratorSave = (idName, idLabel, idPrefix) => {
        setIdGeneratorDialogOpen(false);
        netPost('/api/workflow/id/create', {
            label: idLabel,
            name: idName,
            prefix: idPrefix,
            digits: 8,
        }).then(response => response.json())
            .then(config => {
                console.log('Generator: ' + JSON.stringify(config));
                if ( config ) {
                    netGet('/api/workflow/id/list')
                        .then(response => response.json())
                        .then(configs => {
                            setSelectedIdGenIndex(-1);
                            setSelectedIdgenValue(config.name);
                            if ( config.properties?.value ) {
                                setSelectedIdgenPreview(config.properties.value);
                            } else {
                                setSelectedIdgenPreview('');
                            }
                            if (configs && Array.isArray(configs)) {
                                setIdgenList(configs);
                                let findex = configs.findIndex(f => f.name === config.name);
                                setSelectedIdGenIndex(findex);
                            } else {
                                setIdgenList([]);
                            }
                        });
                }
            }).catch(error => {
                console.log('ERROR creating ID Generator: ' + error);
            });
    };

    return (
        <Dialog
            maxWidth={'1100px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    minWidth: 800,
                    minHeight: 780,
                    maxHeight: 1000,

                    width: 1100,
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>{title}</DialogTitle>
            <DialogContent>
                <FormGroup>
                    <Box sx={{ display: 'grid', gridTemplateColumns: '50% 50%' }}>

                        <Box sx={{ paddingTop: '2ex', paddingBottom: '2ex', paddingRight: '1em', alignSelf: 'center' }}>
                            <FormControl size="small" fullWidth>
                                <InputLabel id="form-type-label" sx={{ visibility: !selectTypeVisible ? 'visible' : 'hidden' }}>Type *</InputLabel>
                                <Select
                                    labelId="form-type-label"
                                    id="type-select"
                                    value={typeValue}
                                    label="Type *"
                                    onChange={typeChanged}
                                    displayEmpty={true}
                                    renderValue={(selected) => {
                                        if (!selected) {
                                            if (selectTypeVisible) {
                                                return 'Select type';
                                            } else {
                                                return 'Select';
                                            }
                                        }
                                        return TYPE_LABELS[selected];
                                    }}
                                    onOpen={() => setSelectTypeVisible(false)}
                                    required={true}
                                >
                                    <MenuItem value={'CHECKBOX'}>{TYPE_LABELS['CHECKBOX']}</MenuItem>
                                    <MenuItem value={'CHECKBOX_GROUP'}>{TYPE_LABELS['CHECKBOX_GROUP']}</MenuItem>
                                    <MenuItem value={'DATE_PICKER'}>{TYPE_LABELS['DATE_PICKER']}</MenuItem>
                                    <MenuItem value={'DATETIME_PICKER'}>{TYPE_LABELS['DATETIME_PICKER']}</MenuItem>
                                    {/* <MenuItem value={'PREDEFINED'}>{TYPE_LABELS['PREDEFINED']}</MenuItem> */}
                                    <MenuItem value={'FILE_UPLOAD'}>{TYPE_LABELS['FILE_UPLOAD']}</MenuItem>
                                    <MenuItem value={'RADIOBUTTON_GROUP'}>{TYPE_LABELS['RADIOBUTTON_GROUP']}</MenuItem>
                                    <MenuItem value={'SELECTION'}>{TYPE_LABELS['SELECTION']}</MenuItem>
                                    <MenuItem value={'TEXTAREA'}>{TYPE_LABELS['TEXTAREA']}</MenuItem>
                                    <MenuItem value={'TEXTFIELD'}>{TYPE_LABELS['TEXTFIELD']}</MenuItem>
                                    <MenuItem value={'TIME_PICKER'}>{TYPE_LABELS['TIME_PICKER']}</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{ paddingTop: '0', paddingLeft: '1em', gridColumnStart: '2', display: 'flex', alignItems: 'center', alignContent: 'stretch', alignSelf: 'center' }} >
                            <Box sx={{ visibility: variableEnabled ? 'visible' : 'hidden', flexGrow: 1 }}>
                                <FormControl size="small" fullWidth>
                                    <InputLabel id="form-source-label">{variableListLabel}</InputLabel>
                                    <Select
                                        labelId="var-list-label"
                                        id="var-list-select"
                                        value={variableListValue ? variableListValue : ''}
                                        label={variableListLabel}
                                        onChange={variableListChanged}
                                        required={true}
                                        renderValue={(selected) => {
                                            if (!selected) {
                                                return 'Select';
                                            }
                                            const v = variableList.find(item => item.value === selected)?.label;
                                            return selected === 'CUSTOM' ? v : <strong>{v}</strong>;
                                        }}
                                    >
                                        {variableList.map((pf, pindex) => <MenuItem value={pf.value} key={`${pf.value}-${pindex}`} >{pf.value === 'CUSTOM' ? pf.label : <strong>{pf.label}</strong>}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Box>
                            
                            <Box sx={{ paddingLeft: '1ex' }}>
                                <Tooltip title="Advanced Options..." onClick={moreIconClick}>
                                    <IconButton aria-label="More...">
                                        <MoreIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <MoreOptionsDialog open={moreDialogOpen} onCancel={() => setMoreDialogOpen(false)} otherNames={otherNames} onSave={moreDialogSave} fieldName={nameValue} />
                        </Box>
                        {/* 
                        <Box sx={{ paddingTop: '2ex', gridColumnEnd: 'span 2' }}>
                            <TextField label="Variable Name" value={nameValue} onChange={nameChanged} size="small" InputLabelProps={{ shrink: nameValue && nameValue !== '' }}
                                required={true} fullWidth sx={{ visibility: nameDisabled ? 'hidden' : 'visible' }} />
                        </Box>
                        */}
                        <Box sx={{ paddingTop: '2ex', gridColumnStart: '1', gridColumnEnd: 'span 2' }}>
                            <TextField label="Label" value={labelValue} onChange={labelChanged} size="small"
                                fullWidth required={true} />
                        </Box>

                        <Box sx={{ paddingTop: '3ex', gridColumnStart: '1', gridColumnEnd: 'span 2' }}>
                            <TextField label="Description" value={descriptionValue} onChange={descriptionChanged} size="small"
                                multiline fullWidth
                                rows={3} />
                        </Box>
                        {placeholderEnabled &&
                            <Box sx={{ paddingTop: '3ex', gridColumnStart: '1', gridColumnEnd: 'span 2' }}>
                                <TextField label="Placeholder" value={placeholderValue} onChange={placeholderChanged} size="small"
                                    fullWidth />
                            </Box>
                        }
                        {typeValue === 'SELECTION' && customSelectionEnabled &&
                            <Box sx={{ gridColumnStart: '1', gridColumnEnd: 'span 2' }}>
                                <TableContainer component={Paper} sx={{ marginTop: '3ex', height: '11.5em' }}>
                                    <Table sx={{ minWidth: 500 }} aria-label="selection table" >
                                        <TableHead>
                                            <TableRow sx={{ paddingTop: 0, paddingBotton: 0 }}>
                                                <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', fontWeight: 'bold' }}>{itemHeaderLabel}</TableCell>
                                                <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', fontWeight: 'bold', width: '10em' }}>Default Value</TableCell>
                                                <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', fontWeight: 'bold', width: '10em' }}>Other Field</TableCell>
                                                {/* 
                                                <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', fontWeight: 'bold' }}>Selection Value</TableCell>
                                                <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', width: '8em', fontWeight: 'bold' }}>Category</TableCell>
                                                */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectionData && selectionData.map((row, index) => (
                                                <TableRow
                                                    key={`FS${index}`}
                                                    onClick={(event) => handleSelectionClicked(event, index)}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    selected={selectionIndex == index}
                                                >
                                                    <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem' }}>{row.label}</TableCell>
                                                    <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', paddingLeft: '1ex', width: '10em', textAlign: 'center' }}>{row.defvalue && <CheckIcon />}</TableCell>
                                                    <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', paddingLeft: '1ex', width: '10em', textAlign: 'center' }}>{row.other && <CheckIcon />}</TableCell>
                                                    {/* 
                                                    <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem' }}>{row.value}</TableCell>
                                                    <TableCell component="th" scope="row" sx={{ width: '8em', paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem' }}>
                                                        {row.categ}
                                                    </TableCell>
                                                    */}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box sx={{ paddingTop: '0px', paddingLeft: '0px' }}>
                                    <Tooltip title={addLabel} >
                                        <IconButton color="primary" onClick={handleAddSelectionClick} disabled={!customSelectionEnabled}>
                                            <AddBoxIcon fontSize="inherit" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={deleteLabel}>
                                        <IconButton disabled={!deleteSelectionEnabled} onClick={handleDeleteSelectionClick} >
                                            <DeleteIcon fontSize="inherit" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Move Up">
                                        <IconButton color="inherit" disabled={!moveUpItemEnabled} sx={{ marginLeft: '1em' }} onClick={moveUpSelectionHandler}>
                                            <MoveUpIcon color="inherit" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Move Down">
                                        <IconButton color="inherit" disabled={!moveDownItemEnabled} onClick={moveDownSelectionHandler}>
                                            <MoveDownIcon color="inherit" />
                                        </IconButton>
                                    </Tooltip>
                                    <AddSelectionValueDialog title={addDialogTitle} onSave={dialogSaveHandler} onCancel={dialogCancelHandler}
                                        open={selectionDialogOpen} />
                                </Box>
                            </Box>
                        }
                        {(typeValue === 'RADIOBUTTON_GROUP' || typeValue === 'CHECKBOX_GROUP') && customSelectionEnabled &&
                            <Box sx={{ gridColumnStart: '1', gridColumnEnd: 'span 2', padding: 1, paddingTop: 3, paddingBottom: 2 }}>
                                <TreeTable value={treeSelectionData} tableStyle={{ width: '100%' }} emptyMessage={<span></span>}
                                    selectionMode="single" selectionKeys={selectedNodeKey} onSelectionChange={handleSelectedNode} metaKeySelection={false} scrollable scrollHeight="250px">
                                    <Column field="name" header={itemHeaderLabel} alignHeader="left" style={{ padding: 0, paddingLeft: '1ex', textAlign: 'left', width: '70%' }}
                                        headerStyle={{ padding: '0.9ex', width: '70%', fontSize: '90%' }} expander ></Column>
                                    <Column field="def" header="Default Value" align="center" headerStyle={{ padding: '0.9ex', fontSize: '90%' }}></Column>
                                    <Column field="other" header="Other Field" align="center" headerStyle={{ padding: '0.9ex', fontSize: '90%' }}></Column>
                                </TreeTable>
                                <Box sx={{ paddingTop: 1, paddingLeft: '0px' }}>
                                    <Tooltip title={addLabel} >
                                        <IconButton color="primary" onClick={handleAddTreeSelectionClick} disabled={!customSelectionEnabled}>
                                            <AddBoxIcon fontSize="inherit" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Edit Item">
                                        <IconButton color="secondary" disabled={!editSelectionEnabled} onClick={handleEditTreeSelectionClick} >
                                            <SettingsIcon fontSize="inherit" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={deleteLabel}>
                                        <IconButton color="error" disabled={!deleteSelectionEnabled} onClick={handleDeleteTreeSelectionClick} >
                                            <DeleteIcon fontSize="inherit" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Move Up">
                                        <IconButton color="inherit" disabled={!moveUpItemEnabled} sx={{ marginLeft: '1em' }} onClick={moveUpTreeSelectionHandler}>
                                            <MoveUpIcon color="inherit" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Move Down">
                                        <IconButton color="inherit" disabled={!moveDownItemEnabled} onClick={moveDownTreeSelectionHandler}>
                                            <MoveDownIcon color="inherit" />
                                        </IconButton>
                                    </Tooltip>
                                    <AddTreeSelectionValueDialog title={addDialogTitle} onSave={treeDialogSaveHandler} onCancel={treeDialogCancelHandler}
                                        open={treeSelectionDialogOpen} parent={parentNode} />
                                    <AddTreeSelectionValueDialog title={editDialogTitle} onSave={treeEditDialogSaveHandler} onCancel={treeDialogCancelHandler}
                                        open={treeEditSelectionDialogOpen} parent={editParentNode} value={parentNode} />
                                </Box>
                            </Box>
                        }
                        {typeValue === 'TEXTFIELD' && variableListValue === 'fromFormField' && 
                            <Box sx={{ display: 'grid', gridTemplateColumns: '50% 1fr', gap: '10px 10px', gridColumnStart: '1', gridColumnEnd: 'span 2', height: '230px', position: 'relative',
                                marginBottom: '3ex', marginTop: '2ex', justifyContent: 'stretch'
                             }}>
                                <Box>
                                    <Typography>Other Forms:</Typography>
                                    <Box sx={{ height: '200px', padding: 2, paddingTop: 1, paddingBottom: 1, border: '1px solid rgba(0,0,0,0.2)', borderRadius: '4px', overflow: 'auto' }}>

                                        <List sx={{ minHeight: '2em' }}>
                                            {
                                                xformsList.map((xform, index) => (
                                                    <ListItem
                                                        key={'mwwa-' + index}
                                                        sx={{ padding: 0, paddingTop: 0, paddingBottom: 0, alignItems: 'baseline' }}
                                                    >
                                                        <ListItemButton selected={selectedFormIndex === index} onClick={(event) => handleFormItemClick(index)}
                                                            sx={{ padding: '1px', paddingLeft: '1px', fontStyle: 'italic' }}>
                                                            <ListItemText>{xform.label}</ListItemText>
                                                        </ListItemButton>
                                                    </ListItem>
                                                )
                                                )
                                            }
                                        </List>
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography>Fields:</Typography>
                                    <Box sx={{ height: '200px', padding: 2, paddingTop: 1, paddingBottom: 1, border: '1px solid rgba(0,0,0,0.2)', borderRadius: '4px', overflow: 'auto' }}>

                                        <List sx={{ minHeight: '2em' }}>
                                            { selectedFormIndex >= 0 && xformsList[selectedFormIndex] && xformsList[selectedFormIndex].fields && 
                                                xformsList[selectedFormIndex].fields.map((xfield, index) => (
                                                    <ListItem
                                                        key={'mwwa-' + index}
                                                        sx={{ padding: 0, paddingTop: 0, paddingBottom: 0, alignItems: 'baseline' }}
                                                    >
                                                        <ListItemButton selected={selectedFieldIndex === index} onClick={(event) => handleFieldItemClick(index)}
                                                            sx={{ padding: '1px', paddingLeft: '1px' }}>
                                                            <ListItemText>{xfield.label}</ListItemText>
                                                        </ListItemButton>
                                                    </ListItem>
                                                )
                                                )
                                            }
                                        </List>
                                    </Box>
                                </Box>
                                <Box sx={{gridColumnStart: '1', gridColumnEnd: 'span 2', display: 'flex'}} >
                                    <Typography sx={{paddingRight: '1em'}}>Selected Source Field:</Typography>
                                    <Typography sx={{fontWeight: 'bold'}}>{selectedFormIndex >= 0 && xformsList[selectedFormIndex] && xformsList[selectedFormIndex].label}</Typography>
                                    <Typography sx={{fontWeight: 'bold', paddingLeft: '1ex', paddingRight: '1ex'}} >{selectedFormIndex >= 0 && selectedFieldIndex >= 0 && xformsList[selectedFormIndex] && 
                                        xformsList[selectedFormIndex].fields[selectedFieldIndex] && '/'}</Typography>
                                    <Typography sx={{fontWeight: 'bold'}}>{selectedFormIndex >= 0 && selectedFieldIndex >= 0 && xformsList[selectedFormIndex] && 
                                        xformsList[selectedFormIndex].fields[selectedFieldIndex] && xformsList[selectedFormIndex].fields[selectedFieldIndex].label }</Typography>
                                </Box>
                            </Box>
                        }
                        {typeValue === 'TEXTFIELD' && variableListValue === 'idGenerator' && 
                            <React.Fragment>
                                <Box sx={{ flexGrow: 1, paddingTop: '1em' }}>
                                    <FormControl size="small" fullWidth>
                                        <InputLabel id="form-idgen-label">ID Generator</InputLabel>
                                        <Select
                                            labelId="var-idgen-label"
                                            id="var-idgen-select"
                                            value={selectedIdgenValue ? selectedIdgenValue : ''}
                                            label="ID Generator"
                                            onChange={idGeneratorChanged}
                                            required={true}
                                            renderValue={(selected) => {
                                                if (!selected) {
                                                    return 'Select';
                                                }
                                                const v = idgenList.find(item => item.name === selected)?.label;
                                                return v;
                                            }}
                                            size="small"
                                        >
                                            {idgenList.map((idgen, pindex) => <MenuItem value={idgen.name} key={`${idgen.name}-${pindex}`} >{idgen.label}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box sx={{paddingTop: '1em', paddingLeft: '1ex'}}>
                                    <Tooltip title="Add Generator" >
                                        <IconButton onClick={handleAddGeneratorClick}>
                                            <AddBoxIcon fontSize="inherit" />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                                <Box sx={{paddingTop: '1.5em', gridColumnStart: '1'}}>
                                    <TextField label="Preview" size="small" value={selectedIdgenPreview} inputProps={{readOnly: true}} fullWidth />
                                </Box>
                                <Box sx={{paddingTop: '1.5em', paddingBotton: '2em', paddingLeft: '1ex', gridColumnStart: '1', gridColumnEnd: 'span 2'}}>
                                    
                                </Box>
                                <AddIdGeneratorDialog open={idGeneratorDialogOpen} onCancel={() => setIdGeneratorDialogOpen(false)}
                                        onSave={handleAddGeneratorSave} />
                            </React.Fragment>
                        }
                        {typeValue === 'SELECTION' && (variableListValue === 'DOCUMENTS' || variableListValue === 'DOCUMENTS_ONLY') &&
                            <Box sx={{ visibility: variableEnabled ? 'visible' : 'hidden', gridColumnStart: '1', gridColumnEnd: 'span 2', paddingTop: 3 }}>
                                <FormControl size="small" fullWidth>
                                    <InputLabel id="form-doctype-label">Document Categories</InputLabel>
                                    <Select
                                        labelId="form-doctype-label"
                                        id="form-doctype-select"
                                        value={docTypeValue}
                                        multiple
                                        label="Document Categories"
                                        onChange={event => {
                                            const {
                                                target: { value },
                                            } = event;
                                            let nval = typeof value === 'string' ? value.split(',') : value;
                                            setDocTypeValue(nval);
                                        }}
                                        required={true}
                                    >
                                        {DOC_TYPES.map((dt, dtindex) => <MenuItem value={dt.value} key={`${dt.value}-${dtindex}`} >{dt.label}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Box>

                        }
                        {typeValue === 'SELECTION' && variableListValue === 'DOC_UNIT' &&
                            <Box sx={{ visibility: variableEnabled ? 'visible' : 'hidden', gridColumnStart: '1', gridColumnEnd: 'span 2', paddingTop: 3 }}>
                                <FormControl size="small" fullWidth>
                                    <InputLabel id="form-doctype-label">Type</InputLabel>
                                    <Select
                                        labelId="form-doctype-label"
                                        id="form-doctype-select"
                                        value={docUnitTypeValue}
                                        label="Type"
                                        onChange={event => setDocUnitTypeValue(event.target.value)}
                                        required={true}
                                    >
                                        {DOC_UNIT_TYPES.map((dt, dtindex) => <MenuItem value={dt.value} key={`${dt.value}-${dtindex}`} >{dt.label}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Box>

                        }
                        {typeValue === 'FILE_UPLOAD' &&
                            
                            <Box sx={{ paddingTop: '2ex', gridColumnStart: '1', gridColumnEnd: 'span 2', paddingTop: 3 }}>
                                <Typography>Maximum Number of Files: </Typography>
                                <CustomNumberInput size="small" sx={{width: '20em'}} 
                                value={fileUploadMaxFiles} onChange={(event,val) => setFileUploadMaxFiles(val)} />
                            </Box>
                        }
                        {typeValue === 'TEXTFIELD' && variableListValue === 'CUSTOM' && 
                            <React.Fragment>
                                <Box sx={{ paddingTop: '2ex', gridColumnStart: '1', gridColumnEnd: 'span 2', paddingTop: 3 }}>
                                    <Typography>Maximum Number of Characters: </Typography>
                                    <CustomNumberInput size="small" sx={{ width: '20em' }}
                                        value={textFieldMaxChars} onChange={(event, val) => setTextFieldMaxChars(val)} />
                                </Box>
                                <Box sx={{ paddingTop: '2ex', gridColumnStart: '1', gridColumnEnd: 'span 2', paddingTop: 3 }}>
                                    <FormControlLabel control={<Checkbox checked={textTranslate} onChange={event => setTextTranslate(event.target.checked)} />} label="Translate To" />
                                    { textTranslate && 
                                        <RadioGroup
                                            row
                                            sx={{paddingLeft: 4}}
                                            name="translate-radio-buttons-group"
                                            aria-labelledby="translate-radio-buttons-group-label"
                                            value={textTranslateValue}
                                            onChange={event => setTextTranslateValue(event.target.value)}
                                        >
                                            <FormControlLabel value="UPPER" control={<Radio />} label="Uppercase" />
                                            <FormControlLabel value="LOWER" control={<Radio />} label="Lowercase" />
                                        </RadioGroup>}
                                </Box>
                            </React.Fragment>
                        }
                        <Box sx={{ paddingTop: '2ex' }}>
                            <FormControlLabel control={<Checkbox checked={requiredValue} onChange={requiredChanged} />} label="Required" />
                        </Box>

                        <Box sx={{ paddingTop: '3ex', visibility: orientationEnabled ? 'visible' : 'hidden' }}>
                            <FormControl>
                                <FormLabel id="direction-radio-buttons-group-label">Orientation</FormLabel>
                                <RadioGroup
                                    row
                                    name="direction-radio-buttons-group"
                                    aria-labelledby="direction-radio-buttons-group-label"
                                    value={orientationValue}
                                    onChange={orientationChanged}
                                >
                                    <FormControlLabel value="H" control={<Radio />} label="Horizontal" />
                                    <FormControlLabel value="V" control={<Radio />} label="Vertical" />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                        
                    </Box>
                </FormGroup>
            </DialogContent>
            <DialogActions sx={{ paddingRight: 'calc(8px + 2ex)', paddingBlock: 'calc(8px + 1ex)', m: 1 }} >
                <Button variant="outlined" onClick={onOKClick} sx={{ minWidth: '7em' }} disabled={!okEnabled}>OK</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{ minWidth: '7em' }}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}

function FormFields(props) {
    const {
        formIndex,
        formsList,
        saveEnabled,
        onChange,
        updateFields,
        ...other
    } = props;

    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const [form, setForm] = React.useState(null);


    React.useEffect(() => {
        if (formsList && formIndex >= 0) {
            setForm(formsList[formIndex]);
        }
    }, [formIndex, formsList, updateFields]);

    const handleRowClick = (index) => {
        setSelectedIndex(index);
    };

    return (
        <Box sx={{ padding: '1ex' }}>
            <Box sx={{ paddingTop: '2ex' }}>
                <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>{form?.label}</Typography>
            </Box>
            <Box sx={{ paddingTop: '2ex', paddingBottom: '3ex' }}>
                <Typography sx={{ fontSize: '15px' }}>{form?.description}</Typography>
            </Box>
            {form &&
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Field Name</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Label</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Placeholder</TableCell>
                                <TableCell>Required</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {form && Array.isArray(form.fields) &&
                                form.fields.map((field, index) => (
                                    <TableRow selected={selectedIndex == index}
                                        onClick={handleRowClick(index)}
                                        key={`${field.name}${index}`}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>{field.name}</TableCell>
                                        <TableCell>{field.type}</TableCell>
                                        <TableCell>{field.label}</TableCell>
                                        <TableCell>{field.description}</TableCell>
                                        <TableCell>{field.placeholder}</TableCell>
                                        <TableCell>{field.required && <CheckIcon />}</TableCell>
                                    </TableRow>
                                ))

                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </Box>

    );


}

function WorkflowForms(props) {

    const [formsList, setFormsList] = React.useState([]);
    const [updateFormsList, setUpdateFormsList] = React.useState(0);
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const [selectedForm, setSelectedForm] = React.useState(null);
    const [contextMenu, setContextMenu] = React.useState(null);
    const [checkoutEnabled, setCheckoutEnabled] = React.useState(false);
    const [saveFormEnabled, setSaveFormEnabled] = React.useState(false);
    const [checkinEnabled, setCheckinEnabled] = React.useState(false);
    const [addFormEnabled, setAddFormEnabled] = React.useState(true);
    const [deleteFormEnabled, setDeleteFormEnabled] = React.useState(false);
    const [modified, setModified] = React.useState([]);
    const [updateForm, setUpdateForm] = React.useState(0);
    const [updateFormId, setUpdateFormId] = React.useState(-1);

    const [formDialogOpen, setFormDialogOpen] = React.useState(false);
    const [formDialogTemplate, setFormDialogTemplate] = React.useState([]);
    const [formDialogName, setFormDialogName] = React.useState('');
    const [formDialogDescription, setFormDialogDescription] = React.useState('');
    const [formDialogTitle, setFormDialogTitle] = React.useState('Create Form');

    const [attributesEditEnabled, setAttributesEditEnabled] = React.useState(false);
    const [attributesEditDialogOpen, setAttributesEditDialogOpen] = React.useState(false);
    const [attributesEditDialogName, setAttributesEditDialogName] = React.useState('');
    const [attributesEditDialogDescription, setAttributesEditDialogDescription] = React.useState('');
    const [attributesEditDialogTitle, setAttributesEditDialogTitle] = React.useState('Edit Form Properties');


    const [checkinDialogOpen, setCheckinDialogOpen] = React.useState(false);
    const [checkinDialogTitle, setCheckinDialogTitle] = React.useState('Checkin Form');
    const [checkinDialogFormName, setCheckinDialogFormName] = React.useState('');

    const [revokeDialogOpen, setRevokeDialogOpen] = React.useState(false);
    const [revokeDialogTitle, setRevokeDialogTitle] = React.useState('Revoke Checkout');

    const [formDeleteOpen, setFormDeleteOpen] = React.useState(false);
    const [formDeleteName, setFormDeleteName] = React.useState('');
    const [formSaveIndex, setFormSaveIndex] = React.useState(-1);

    const [formSaveOpen, setFormSaveOpen] = React.useState(false);
    const [formSaveName, setFormSaveName] = React.useState('');

    const [addFieldEnabled, setAddFieldEnabled] = React.useState(false);
    const [editFieldEnabled, setEditFieldEnabled] = React.useState(false);
    const [deleteFieldEnabled, setDeleteFieldEnabled] = React.useState(false);

    const [fieldCreateDialogOpen, setFieldCreateDialogOpen] = React.useState(false);
    const [fieldCreateDialogTitle, setFieldCreateDialogTitle] = React.useState('Add Field');
    const [fieldCreateDialogField, setFieldCreateDialogField] = React.useState(null);

    const [fieldEditDialogOpen, setFieldEditDialogOpen] = React.useState(false);
    const [fieldEditDialogTitle, setFieldEditDialogTitle] = React.useState('Edit Field');
    const [fieldEditDialogField, setFieldEditDialogField] = React.useState(null);

    const [fieldNames, setFieldNames] = React.useState([]);
    const [otherFieldNames, setOtherFieldNames] = React.useState([]);

    const [fieldContextMenu, setFieldContextMenu] = React.useState(null);
    const [updateFields, setUpdateFields] = React.useState(0);
    const [selectedFieldIndex, setSelectedFieldIndex] = React.useState(1);
    const [selectedField, setSelectedField] = React.useState(null);
    const [moveUpFieldEnabled, setMoveUpFieldEnabled] = React.useState(false);
    const [moveDownFieldEnabled, setMoveDownFieldEnabled] = React.useState(false);
    const [previewEnabled, setPreviewEnabled] = React.useState(false);
    const [exportEnabled, setExportEnabled] = useState(true);

    const [previewFormOpen, setPreviewFormOpen] = useState(false);
    const [exportFormOpen, setExportFormOpen] = useState(false);
    const [exportFormFiles, setExportFormFiles] = useState([]);

    const theme = useTheme();

    React.useEffect(() => {
        loadInstances();
    }, [updateFormsList]);

    React.useEffect(() => {
        if (updateFormId >= 0) {
            netGet('/api/workflow/form/' + updateFormId)
                .then(response => response.json())
                .then(form => {
                    if (form && form.id) {
                        // console.log('Updating Workflow Definition: ' + def.id);
                        let slist = formsList.map((r) => {
                            if (r.id === form.id) {
                                return form;
                            }
                            return r;
                        });
                        setFormsList(slist);
                        // loadWorkflowDialogTemplates(slist);
                        const cform = slist.find((item) => item.id === form.id);
                        if (cform) {
                            const i = slist.indexOf(cform);
                            // console.log('Found form to update ' + cform + ' at index ' + i);
                            updateSelectedItem(cform, i);
                        }
                    }
                }).catch(error => {
                    console.log('Error fetching definition: ' + error);
                });
        }
    }, [updateForm, updateFormId]);



    const workflowMessage = (message) => {
        let wevent = message.data;
        console.log('Received workflow message: ' + wevent.action + ' : ' + wevent.type + ' : ' + wevent.id);
        if (wevent.type === 'Form') {
            if (wevent.action === 'DELETE') {
                let dindex = -1;
                const form = formsList.find((item, index) => {
                    if (item.id === wevent.id) {
                        dindex = index;
                        return true;
                    }
                });
                if (form && dindex >= 0) {
                    let wlist = [...formsList];
                    wlist.splice(dindex, 1);
                    setFormsList(wlist);
                    let mods = [];
                    modified.forEach(mindex => {
                        if (mindex < dindex) {
                            mods.push(mindex);
                        } else {
                            mods.push(mindex - 1);
                        }
                    });
                    setModified(mods);
                    // loadWorkflowDialogTemplates(wlist);
                    if (dindex < wlist.length) {
                        const nform = wlist[dindex];
                        selectTheForm(nform, dindex, mods);
                    } else {
                        setSelectedField(-1);
                        setSelectedForm(null);
                        setSelectedIndex(-1);
                        setPreviewEnabled(false);
                        setCheckoutEnabled(false);
                        setDeleteFormEnabled(false);
                        setCheckinEnabled(false);
                        setAttributesEditEnabled(false);
                        setSaveFormEnabled(false);
                    }
                }
            } else if (wevent.action === 'NEW') {
                loadInstances(wevent.id);
                // setUpdateWorkflowList(updateWorkflowList => updateWorkflowList + 1);
            } else if (wevent.action === 'CHECKOUT' || wevent.action === 'REVOKE_CHECKOUT') {

                let cform = formsList.find((item) => item.id === wevent.previousId);
                console.log('Updating form from server: ' + cform?.name);
                if (cform) {
                    cform.id = wevent.id;
                    setUpdateForm(updateForm => updateForm + 1);
                    setUpdateFormId(wevent.id);

                }
            } else {
                const form = formsList.find((item) => item.id === wevent.id);
                const findex = formsList.findIndex(item => item.id === wevent.id);
                if (form && !modified.includes(findex)) {
                    // try not to overwrite modifications
                    setUpdateForm(updateForm => updateForm + 1);
                    setUpdateFormId(wevent.id);
                }
            }
        }
    };

    MessageHooks["workflow"]["Forms"] = workflowMessage;


    const loadInstances = (selectId) => {
        netGet('/api/workflow/form/list')
            .then(response => response.json())
            .then(data => {
                if (data && Array.isArray(data)) {
                    console.log('New Forms: ' + data.length);
                    const ofm = Array.isArray(formsList) ? [...formsList] : [];
                    
                    let fm = [];
                    let mods = [];
                    data.sort((a,b) => a.label.localeCompare(b.label)).forEach((item, index) => {
                        const oindex = ofm.findIndex(oitem => oitem.id === item.id);
                        if (modified.includes(oindex)) {
                            fm.push(ofm[oindex]);
                            mods.push(index);
                        } else {
                            fm.push(item);
                        }
                    });
                    setFormsList(fm);
                    setModified(mods);
                    if (selectId) {
                        const sindex = fm.findIndex(item => item.id === selectId);
                        /*
                        let sindex = -1;
                        let sdef = data.find((item, i) => {
                            if (item.id === selectId) {
                                sindex = i;
                                return true;
                            }
                            return false;
                        });
                        */
                        if (sindex >= 0) {
                            const sform = fm[sindex];
                            selectTheForm(sform, sindex, mods);
                        }
                    }
                }
            });
    };


    const handleContextMenu = (event, index) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
        if (index >= 0) {
            handleItemClick(index);
        }
    };

    const handleFieldContextMenu = (event, index) => {
        event.preventDefault();
        setFieldContextMenu(
            fieldContextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
        if (index >= 0) {
            handleFieldClick(index);
        }
    };

    const handleContextMenuClose = () => {
        setContextMenu(null);
    };

    const handleFieldContextMenuClose = () => {
        setFieldContextMenu(null);
    };

    const selectTheForm = (form, index, mods) => {
        const canModify = typeof form.user === 'string' && form.user.length > 0 && form.user === UserInfo.info.name;
        setSelectedIndex(index);
        setSelectedFieldIndex(-1);
        if (index >= 0) {
            if (form) {
                setSelectedForm(form);
                setAddFieldEnabled(canModify);
                setPreviewEnabled(true);
                setSaveFormEnabled(mods.includes(index));
            } else {
                setSelectedForm(null);
                setPreviewEnabled(false);
                setSaveFormEnabled(false);
            }
        } else {
            setSelectedForm(null);
            setPreviewEnabled(false);
            setSaveFormEnabled(false);
        }
        const haslock = typeof form.user === 'string' && form.user.length > 0;
        if (haslock) {
            setCheckoutEnabled(false);
            setDeleteFormEnabled(false);
            setCheckinEnabled(canModify);
            setAttributesEditEnabled(canModify);
            setSaveFormEnabled(mods.includes(index));
        } else {
            setCheckoutEnabled(true);
            setDeleteFormEnabled(true);
            setCheckinEnabled(false);
            setSaveFormEnabled(false);
            setAttributesEditEnabled(false);
        }
    };

    const handleItemClick = (index) => {
        /*
        if ( saveRequired(selectedIndex) ) {
            const def = workflowList[selectedIndex];
            if (def) {
                setWorkflowSaveName(def.label);
                setWorkflowSaveIndex(selectedIndex);
                setWorkflowSaveOpen(true);
            }
        }
        */
        const canModify = isLockedByMe(index);
        setSelectedIndex(index);
        setSelectedFieldIndex(-1);
        if (index >= 0) {
            const form = formsList[index];
            if (form) {
                setSelectedForm(form);
                setAddFieldEnabled(canModify);
                setPreviewEnabled(true);
                if (Array.isArray(form.fields)) {
                    setFieldNames(form.fields.map(field => field.name));
                }
            } else {
                setSelectedForm(null);
                setPreviewEnabled(false);
            }

        } else {
            setSelectedForm(null);
            setPreviewEnabled(false);
        }

        if (isLocked(index)) {
            setCheckoutEnabled(false);
            setDeleteFormEnabled(false);
            setCheckinEnabled(canModify);
            setAttributesEditEnabled(canModify);
            setSaveFormEnabled(canModify && modified.includes(index));
        } else {
            setCheckoutEnabled(true);
            setDeleteFormEnabled(true);
            setCheckinEnabled(false);
            setSaveFormEnabled(false);
            setAttributesEditEnabled(false);
        }
    };

    const updateSelectedItem = (form, index) => {
        const locked = typeof form.user === 'string' && form.user.length > 0;
        const lockedByMe = typeof form.user === 'string' && form.user.length > 0 && form.user === UserInfo.info.name;
        setSelectedIndex(index);
        setSelectedForm(form);
        if (locked) {
            setCheckoutEnabled(false);
            setDeleteFormEnabled(false);
            setCheckinEnabled(lockedByMe /* && !saveRequired(index) */);
            // etSaveWorkflowEnabled(saveRequired(index));
            // setUndoSaveEnabled(saveRequired(index));
            setAttributesEditEnabled(lockedByMe);
            setAddFieldEnabled(lockedByMe);
        } else {
            setCheckoutEnabled(true);
            setDeleteFormEnabled(true);
            setCheckinEnabled(false);
            // setSaveWorkflowEnabled(false);
            // setUndoSaveEnabled(false);
            setAttributesEditEnabled(false);
            setAddFieldEnabled(false);
        }
    };

    const saveFormHandler = () => {
        unmarkModified();
        updateServerForm(formsList[selectedIndex]);
    };

    const formChangeHandler = (modified, index) => {
        if (modified) {
            markModified();
            setSaveFormEnabled(true);
            setCheckoutEnabled(false);
            setDeleteFormEnabled(false);
            setCheckinEnabled(false);
        } else {
            setSaveFormEnabled(false);
            handleItemClick(selectedIndex);
            unmarkModified();
        }
    };

    const handleCheckinAction = () => {
        setCheckinDialogOpen(true);
    };

    const handleCheckinSave = (message) => {
        setCheckinDialogOpen(false);
        if (selectedIndex >= 0) {
            serverCheckin(selectedIndex, message);
        }
    };

    const handleCheckinCancel = () => {
        setCheckinDialogOpen(false);
    };

    const handleEditFormPropertiesAction = () => {
        const form = formsList[selectedIndex];
        if (form) {
            setAttributesEditDialogName(form.label);
            setAttributesEditDialogDescription(form.description);
            setAttributesEditDialogOpen(true);
        }
    };

    const attributesEditDialogCancelHandler = () => {
        handleContextMenuClose();
        setAttributesEditDialogOpen(false);
    };

    const attributesEditDialogCreateHandler = (name, description) => {
        handleContextMenuClose();
        setAttributesEditDialogOpen(false);
        if (typeof name === 'string' && name.length > 0) {
            // generate a unique name based on the label 
            // it would be safer to use an UUID but this way the name is more user friendly when examining the DB directly
            const timestamp = Math.floor(new Date().getTime() / 100);
            let form = formsList[selectedIndex];
            if (form) {
                form.label = name;
                form.description = description;
                updateServerForm(form);
            }
            /*
            const suffix = timestamp.toString(16);
            const id = `${name.replace(/\s+/gm, '_')}_${suffix}`.toUpperCase();
            console.log('name: ' + name + ',id: ' + id);
            let newForm = {
                name: id,
                label: name,
                description: description,
                flag: 0,
                category: 'STD',
                fields: [],
            }
            addServerForm(newForm);
            */
        }
    };

    const handleCreateFormAction = () => {
        setFormDialogName('');
        setFormDialogDescription('');
        setFormDialogOpen(true);
        /*
        netGet('/api/doc/roots?sort=alpha')
            .then(response => response.json())
            .then(docs => {
                if (Array.isArray(docs)) {
                    console.log('Got ' + docs.length + ' documents.');
                    setDocList(docs);
                    setWorkflowDialogOpen(true);
                }
            }).catch(error => {
                console.log('Error fetching profiles: ' + error);
            });
        */
    };

    const formDialogCancelHandler = () => {
        setFormDialogOpen(false);
    };

    const formDialogCreateHandler = (name, description) => {
        handleContextMenuClose();
        setFormDialogOpen(false);
        if (typeof name === 'string' && name.length > 0) {
            // generate a unique name based on the label 
            // it would be safer to use an UUID but this way the name is more user friendly when examining the DB directly
            const timestamp = Math.floor(new Date().getTime() / 100);
            const suffix = timestamp.toString(16);
            const id = `${name.replace(/\s+/gm, '_')}_${suffix}`.toUpperCase();
            console.log('name: ' + name + ',id: ' + id);
            let newForm = {
                name: id,
                label: name,
                description: description,
                flag: 0,
                category: 'STD',
                fields: [],
            }
            addServerForm(newForm);
        }
    };

    const addServerForm = (form) => {
        netPost('/api/workflow/form', form)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not create form: status = ' + resp.status);
                }
                /*
                else {
                    unmarkModified();
                }
                */
            });
    };

    const updateServerForm = (form) => {
        netPost('/api/workflow/form/update', form)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not update form: status = ' + resp.status);
                } else {
                    unmarkModified();
                }
            });
    };

    const serverCheckout = (index) => {
        const form = formsList[index];
        netPost('/api/workflow/form/checkout', form)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not checkout form: status = ' + resp.status);
                }
            });
    };

    const serverCheckin = (index, msg) => {
        const form = formsList[index];
        const message = msg ? msg : '';
        const payload = { form: form, message: message };
        netPost('/api/workflow/form/checkin', payload)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not checkin form: status = ' + resp.status);
                }
            });
    };

    const serverRevokeCheckout = (index) => {
        const form = formsList[index];
        netPost('/api/workflow/form/revoke_checkout', form)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not revoke checkout form: status = ' + resp.status);
                }
            });
    };

    const serverDelete = (form) => {
        netFetch('/api/workflow/form/' + form.id, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + keycloak.token
            }
        })
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not delete form: status = ' + resp.status);
                }
            });
    };

    const handleMenuCheckin = () => {
        handleContextMenuClose();
        handleCheckinAction();
    };

    const handleMenuDeleteForm = () => {
        handleContextMenuClose();
        handleDeleteFormAction();
    };

    const handleDeleteFormAction = () => {
        const form = formsList[selectedIndex];
        setFormDeleteName(form.label);
        setFormDeleteOpen(true);
    }

    const deleteFormSave = () => {
        setFormDeleteOpen(false);
        const form = formsList[selectedIndex];
        serverDelete(form);
    };

    const deleteFormCancel = () => {

        setFormDeleteOpen(false);

    };


    const markModified = () => {
        const m = [...modified];
        if (!m.includes(selectedIndex)) {
            m.push(selectedIndex);
        }
        setModified(m);

        setSaveFormEnabled(true);
        // setUndoSaveEnabled(true);
        // modified inhibites checkin
        setCheckinEnabled(false);
    };

    const unmarkModified = () => {
        const m = modified.filter(item => item !== selectedIndex);
        console.log('Modified: ' + JSON.stringify(m));
        setModified(m);
        setCheckinEnabled(isLockedByMe(selectedIndex));
        setSaveFormEnabled(false);
        // setUndoSaveEnabled(false);
    };

    const lockIconColor = (index) => {
        const form = formsList[index];
        // console.log('index: ' + index + ', user=' + def.user);
        if (form.user && form.user !== '') {
            if (form.user === UserInfo.info.name) {
                return 'green';
            } else {
                return '#cc0000';
            }
        }
        return 'transparent';
    };

    const lockIconLabel = (index) => {
        const form = formsList[index];
        if (form && form.user && form.user !== '') {
            return 'Locked by ' + form.user;
        }
        return '';
    };

    const isLockedByMe = (index) => {
        const form = formsList[index];
        return typeof form.user === 'string' && form.user.length > 0 && form.user === UserInfo.info.name;
    };

    const isLocked = (index) => {
        let form = formsList[index];
        return typeof form.user === 'string' && form.user.length > 0;
    };

    const handleCheckoutAction = () => {
        if (selectedIndex >= 0) {
            serverCheckout(selectedIndex);
        }
    };

    const handleMenuCheckout = () => {
        handleContextMenuClose();
        handleCheckoutAction();
    };

    const handleMenuRevokeCheckout = () => {
        handleContextMenuClose();
        handleRevokeCheckoutAction();
    };

    const handleRevokeCheckoutAction = () => {
        setRevokeDialogOpen(true);

    };

    const handleRevokeCheckoutSave = () => {
        setRevokeDialogOpen(false);
        if (selectedIndex >= 0) {
            unmarkModified();
            serverRevokeCheckout(selectedIndex);
        }
    };

    const handleRevokeCheckoutCancel = () => {
        setRevokeDialogOpen(false);
    }

    const doUnmarkModified = (index) => {
        const m = modified.filter(item => item !== index);
        setModified(m);
        // setCheckinEnabled(isLockedByMe(index));
    };

    const modifiedColor = (index) => {
        return saveRequired(index) ? 'black' : 'transparent';
    };

    const modifiedLabel = (index) => {
        return saveRequired(index) ? 'Modified' : null;
    }

    const saveRequired = (index) => {
        return modified.includes(index);
    };

    const saveFormCancel = () => {
        setFormSaveOpen(false);
        // just keep the current state it may work out or may loose changes later
    };

    const saveFormSave = (index) => {
        setFormSaveOpen(false);
        doUnmarkModified(index);
        // saveToServer(formsList[index]);
    };

    const saveFormDiscard = (index) => {
        setFormSaveOpen(false);
        const form = formsList[index];
        if (form && typeof form.saveSteps !== 'undefined') {
            // put back the steps
            form.fields = [...form.saveFields];
            delete form.saveFields;
            // report we have no changes
            formChangeHandler(false, index);
        }
    }


    const handleAddFieldAction = () => {
        handleFieldContextMenuClose();
        setOtherFieldNames(fieldNames);
        setFieldCreateDialogOpen(true);
    };

    const handleEditFieldAction = () => {
        handleFieldContextMenuClose();
        computeOtherFieldNames(formsList[selectedIndex], selectedFieldIndex);
        setFieldEditDialogOpen(true);
    };

    const deleteFieldHandler = () => {
        handleFieldContextMenuClose();
        if (selectedFieldIndex >= 0) {
            let form = formsList[selectedIndex];
            let sd = [...form.fields];
            sd.splice(selectedFieldIndex, 1);
            form.fields = sd;
            handleItemClick(selectedIndex);
            markModified();
            setSelectedFieldIndex(-1);
            handleFieldClick(-1);
        }

    };

    const fieldCreateDialogSaveHandler = (nfield) => {
        setFieldCreateDialogOpen(false);
        let form = formsList[selectedIndex];
        if (form) {
            if (!Array.isArray(form.fields)) {
                form.fields = [];
            }
            // console.log('New field: ' + JSON.stringify(nfield));
            form.fields.push(nfield);
            // formsList[selectedIndex] = nform;
            // setFormsList(formsList);
            handleItemClick(selectedIndex);
            markModified();
            const sfi = form.fields.length - 1;
            setSelectedFieldIndex(sfi);
            handleFieldClick(sfi);
        }

    };

    const fieldCreateDialogCancelHandler = () => {
        setFieldCreateDialogOpen(false);
    };

    const fieldEditDialogSaveHandler = (efield) => {
        setFieldEditDialogOpen(false);
        const form = formsList[selectedIndex];
        if (form) {
            if (Array.isArray(form.fields) && selectedFieldIndex < form.fields.length) {
                form.fields[selectedFieldIndex] = efield;
                handleItemClick(selectedIndex);
                handleFieldClick(selectedFieldIndex);
                markModified();
            }
        }
    }

    const fieldEditDialogCancelHandler = () => {
        setFieldEditDialogOpen(false);
    };

    const moveUpFieldHandler = () => {
        handleFieldContextMenuClose();
        const form = formsList[selectedIndex];
        const canEdit = isLockedByMe(selectedIndex);
        if (canEdit && form && Array.isArray(form.fields)) {
            const p = form.fields[selectedFieldIndex - 1];
            const n = selectedFieldIndex - 1;
            form.fields[selectedFieldIndex - 1] = form.fields[selectedFieldIndex];
            form.fields[selectedFieldIndex] = p;
            handleItemClick(selectedIndex);
            markModified();
            setSelectedFieldIndex(n);
            setMoveUpFieldEnabled(canEdit && n > 0);
            setMoveDownFieldEnabled(canEdit && n < form.fields.length - 1);
        }
    };

    const moveDownFieldHandler = () => {
        handleFieldContextMenuClose();
        const form = formsList[selectedIndex];
        const canEdit = isLockedByMe(selectedIndex);
        if (canEdit && form && Array.isArray(form.fields)) {
            const p = form.fields[selectedFieldIndex + 1];
            const n = selectedFieldIndex + 1;
            form.fields[selectedFieldIndex + 1] = form.fields[selectedFieldIndex];
            form.fields[selectedFieldIndex] = p;
            handleItemClick(selectedIndex);
            markModified();
            setSelectedFieldIndex(n);
            setMoveUpFieldEnabled(canEdit && n > 0);
            setMoveDownFieldEnabled(canEdit && n < form.fields.length - 1);
        }
    };

    const removeArrayItem = (arr, value) => {
        const index = arr.indexOf(value);
        if (index > -1) {
            arr.splice(index, 1);
        }
        return arr;
    };

    const computeOtherFieldNames = (form, fieldIndex) => {
        if (Array.isArray(form?.fields) && fieldIndex > -1) {
            const fn = form.fields.map(f => f.name);
            const field = form.fields[fieldIndex];
            if (field) {
                const ofn = fn.filter(n => n !== field.name);
                setOtherFieldNames(ofn);
            } else {
                setOtherFieldNames(fn);
            }
            setFieldNames(fn);
        }
    }

    const handleFieldClick = (index) => {
        const canEdit = isLockedByMe(selectedIndex);
        if (index >= 0) {
            // console.log('field index = ' + index);
            setSelectedFieldIndex(index);

            if (index >= 0 && selectedIndex >= 0 && canEdit) {
                setAddFieldEnabled(true);

                setEditFieldEnabled(index > -1);
                setDeleteFieldEnabled(index > -1);

            } else {
                setEditFieldEnabled(false);
                setDeleteFieldEnabled(false);
                setAddFieldEnabled(false);
            }
            const form = formsList[selectedIndex];
            if (form && Array.isArray(form.fields)) {
                setMoveUpFieldEnabled(canEdit && index > 0);
                setMoveDownFieldEnabled(canEdit && index < form.fields.length - 1);
                const field = form.fields[index];
                setSelectedField(field);
                computeOtherFieldNames(form, index);
            } else {
                setSelectedField(null);
            }

        } else {
            setEditFieldEnabled(false);
            setDeleteFieldEnabled(false);
            setAddFieldEnabled(canEdit);
        }
    };

    const previewHandler = () => {
        setPreviewFormOpen(true);
    };

    const previewSave = (result) => {
        setPreviewFormOpen(false);
        console.log('Preview Result: ' + JSON.stringify(result));
    };

    const exportHandler = () => {
        if ( selectedForm ) {
            const label = selectedForm.label.replace(/[^a-zA-Z0-9]+/g, '_').toUpperCase() + '_' + Math.trunc(selectedForm.id) + '_' + getRandomInt(1000) + '.xml';
            const ff = {
                url: '/api/workflow/form/export/' + Math.trunc(selectedForm.id),
                name: label,
            };
            const eff = [ff];
            setExportFormFiles(eff);
        }
        setExportFormOpen(true);
    };


    return (
        <Box sx={{ height: '100%', width: '100%', display: 'grid', gridTemplateColumns: '25% 3px 1fr', gridTemplateRows: '100%' }}>
            <Box sx={{ position: 'relative', width: '100%', height: '100%', padding: '1ex', paddingTop: '2ex', display: 'grid', gridTemplateRows: 'min-content min-content minmax(10px,1fr) min-content' }}>
                <Typography align="center" sx={{ paddingTop: '8px', paddingBottom: '2ex', fontSize: '18px', fontWeight: theme.typography.fontWeightBold }}>Forms</Typography>
                <TextField
                    id="mwa-search"
                    type="search"
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />

                <Box
                    sx={{ position: 'relative', minHeight: '2em', overflowY: 'auto' }}
                    onContextMenu={(event) => handleContextMenu(event, -1)}
                >

                    <List sx={{ minHeight: '2em' }}>
                        {
                            formsList.map((form, index) => (
                                <ListItem
                                    key={'mwwa-' + index}
                                    sx={{ padding: 0, paddingTop: 0, paddingBottom: 0, alignItems: 'baseline' }}
                                    onContextMenu={(event) => handleContextMenu(event, index)}
                                >
                                    <Box sx={{ alignItems: 'baseline', float: 'left', color: lockIconColor(index), fontSize: '12px' }}>
                                        <Tooltip title={lockIconLabel(index)}>
                                            <span>
                                                <LockIcon fontSize="inherit" />
                                            </span>
                                        </Tooltip>
                                    </Box>
                                    <Box sx={{ alignItems: 'baseline', float: 'left', color: modifiedColor(index), fontSize: '12px', paddingTop: '0.5ex' }}>
                                        <Tooltip title={modifiedLabel(index)}>
                                            <Typography sx={{ paddingLeft: '4px' }}>*</Typography>
                                        </Tooltip>
                                    </Box>
                                    <ListItemButton selected={selectedIndex === index} onClick={(event) => handleItemClick(index)}
                                        sx={{ paddingLeft: '4px', fontStyle: 'italic' }}>
                                        <ListItemText>{form.label}</ListItemText>
                                    </ListItemButton>
                                </ListItem>
                            )
                            )
                        }
                    </List>
                    <Menu
                        open={contextMenu !== null}
                        onClose={handleContextMenuClose}
                        anchorReference="anchorPosition"
                        anchorPosition={
                            contextMenu !== null
                                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                                : undefined
                        }
                    >
                        <MenuItem onClick={handleMenuCheckout} disabled={!checkoutEnabled}>Checkout</MenuItem>
                        <MenuItem onClick={handleMenuRevokeCheckout} disabled={!checkinEnabled}>Revoke Checkout</MenuItem>
                        <MenuItem onClick={saveFormHandler} disabled={!saveFormEnabled}>Save</MenuItem>
                        <MenuItem onClick={handleMenuCheckin} disabled={!checkinEnabled}>Checkin</MenuItem>
                        <MenuItem onClick={handleCreateFormAction} disabled={!addFormEnabled}>Add</MenuItem>
                        <MenuItem onClick={handleEditFormPropertiesAction} disabled={!attributesEditEnabled}>Properties</MenuItem>
                        <MenuItem onClick={handleMenuDeleteForm} disabled={!deleteFormEnabled}>Delete</MenuItem>
                    </Menu>

                    <FormCheckinDialog open={checkinDialogOpen} onSave={handleCheckinSave} onCancel={handleCheckinCancel}
                        title={checkinDialogTitle} formName={checkinDialogFormName} />

                    <FormRevokeCheckoutDialog open={revokeDialogOpen} onSave={handleRevokeCheckoutSave} title={revokeDialogTitle}
                        onCancel={handleRevokeCheckoutCancel} workflowName={checkinDialogFormName} />

                </Box>
                <Box sx={{ padding: '1ex', whiteSpace: 'nowrap', overflowX: 'hidden' }} color={theme.palette.primary.main}>
                    <Tooltip title="Checkout Form">
                        <IconButton color="inherit" disabled={!checkoutEnabled} onClick={handleCheckoutAction} sx={{ paddingLeft: '2px', paddingRight: '6px' }}>
                            <OutputIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Revoke Checkout Form">
                        <IconButton color="inherit" disabled={!checkinEnabled} onClick={handleRevokeCheckoutAction} sx={{ paddingLeft: '6px', paddingRight: '6px' }}>
                            <ReplayIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Save Form">
                        <IconButton color="inherit" disabled={!saveFormEnabled} sx={{ paddingLeft: '6px', paddingRight: '6px' }} onClick={saveFormHandler}>
                            <SaveAltIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Checkin Form">
                        <IconButton color="inherit" disabled={!checkinEnabled} onClick={handleCheckinAction} sx={{ paddingLeft: '6px', paddingRight: '6px' }}>
                            <ExitToAppIcon color="inherit" sx={{ transform: 'rotate(180deg)' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Create a Form">
                        <IconButton color="inherit" sx={{ marginLeft: '1em', paddingLeft: '6px', paddingRight: '6px' }} disabled={!addFormEnabled} onClick={handleCreateFormAction} >
                            <AddIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit Form Properties">
                        <IconButton color="inherit" sx={{ paddingLeft: '2px', paddingRight: '6px' }} disabled={!attributesEditEnabled} onClick={handleEditFormPropertiesAction}>
                            <EditAttributesIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Form">
                        <IconButton color="inherit" disabled={!deleteFormEnabled} onClick={handleDeleteFormAction} sx={{ paddingLeft: '6px', paddingRight: '6px' }}>
                            <DeleteIcon color="inherit" />
                        </IconButton>
                    </Tooltip>

                    <CreateFormDialog open={formDialogOpen} onSave={formDialogCreateHandler} onCancel={formDialogCancelHandler}
                        name={formDialogName} description={formDialogDescription}
                        title={formDialogTitle} />

                    <FormDeleteDialog title="Delete Form" open={formDeleteOpen} formName={formDeleteName}
                        onSave={deleteFormSave} onCancel={deleteFormCancel} />
                    {/*
                    <WorkflowSaveDialog title="Save Form" open={formSaveOpen} workflowName={formSaveName} workflowIndex={formSaveIndex}
                        onSave={saveFormSave} onCancel={saveFormCancel} onDiscard={saveFormDiscard} />
                    */
                    }
                    <CreateFormDialog editOnly={true} open={attributesEditDialogOpen} onSave={attributesEditDialogCreateHandler} onCancel={attributesEditDialogCancelHandler}
                        name={attributesEditDialogName} description={attributesEditDialogDescription}
                        title={attributesEditDialogTitle} />
                </Box>
            </Box>

            <Divider orientation="vertical" />


            <Box sx={{ position: 'relative', width: '100%', height: '100%', paddingBottom: '1ex', display: 'grid', gridTemplateRows: 'minmax(10px,1fr) min-content' }}>

                <Box sx={{ overflowY: 'auto', height: '100%', width: '100%' }}>
                    {
                        selectedForm &&
                        <Box sx={{ padding: '1ex' }}>
                            <Box sx={{ paddingTop: '2ex' }}>
                                <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>{selectedForm?.label}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '2ex', paddingBottom: '3ex' }}>
                                <Typography sx={{ fontSize: '15px' }}>{selectedForm?.description}</Typography>
                            </Box>

                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} size="small" aria-label="a form table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell component="th" sx={{ fontWeight: 'bold' }}>Label</TableCell>
                                            <TableCell component="th" sx={{ fontWeight: 'bold' }}>Description</TableCell>
                                            <TableCell component="th" sx={{ fontWeight: 'bold' }}>Type</TableCell>
                                            <TableCell component="th" sx={{ fontWeight: 'bold' }}>Required</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Array.isArray(selectedForm.fields) &&
                                            selectedForm.fields.map((field, index) => (
                                                <TableRow selected={selectedFieldIndex === index}
                                                    onClick={() => handleFieldClick(index)}
                                                    key={`${field.name}${index}`}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    onContextMenu={(event) => handleFieldContextMenu(event, index)}
                                                >
                                                    <TableCell>{field.label}</TableCell>
                                                    <TableCell>{field.description}</TableCell>
                                                    <TableCell>{TYPE_LABELS[field.type]}</TableCell>
                                                    <TableCell>{field.required && <CheckIcon />}</TableCell>
                                                </TableRow>
                                            ))

                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Box>


                    }
                    <Menu
                        open={fieldContextMenu !== null}
                        onClose={handleFieldContextMenuClose}
                        anchorReference="anchorPosition"
                        anchorPosition={
                            fieldContextMenu !== null
                                ? { top: fieldContextMenu.mouseY, left: fieldContextMenu.mouseX }
                                : undefined
                        }
                    >
                        <MenuItem onClick={handleAddFieldAction} disabled={!addFieldEnabled}>Add Field</MenuItem>
                        <MenuItem onClick={handleEditFieldAction} disabled={!editFieldEnabled}>Edit Field</MenuItem>
                        <MenuItem onClick={deleteFieldHandler} disabled={!deleteFieldEnabled}>Delete Field</MenuItem>
                        <MenuItem onClick={moveUpFieldHandler} disabled={!moveUpFieldEnabled}>Move Up Field</MenuItem>
                        <MenuItem onClick={moveDownFieldHandler} disabled={!moveDownFieldEnabled}>Move Down Field</MenuItem>
                    </Menu>
                </Box>



                <Box sx={{ padding: '1ex', whiteSpace: 'nowrap', overflowX: 'hidden' }} color={theme.palette.primary.main}>
                    <Tooltip title="Add Field">
                        <IconButton color="inherit" disabled={!addFieldEnabled} onClick={handleAddFieldAction}>
                            <AddIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit Field">
                        <IconButton color="inherit" disabled={!editFieldEnabled} onClick={handleEditFieldAction}>
                            <ModeEditIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Field">
                        <IconButton color="inherit" disabled={!deleteFieldEnabled} sx={{ marginLeft: '1em' }} onClick={deleteFieldHandler}>
                            <DeleteIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Move Up Field">
                        <IconButton color="inherit" disabled={!moveUpFieldEnabled} sx={{ marginLeft: '1em' }} onClick={moveUpFieldHandler}>
                            <MoveUpIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Move Down Field">
                        <IconButton color="inherit" disabled={!moveDownFieldEnabled} onClick={moveDownFieldHandler}>
                            <MoveDownIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Preview">
                        <IconButton color="inherit" disabled={!previewEnabled} sx={{ marginLeft: '1em' }} onClick={previewHandler}>
                            <PreviewIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Export Form">
                        <IconButton color="inherit" disabled={!exportEnabled} sx={{ marginLeft: '1em' }} onClick={exportHandler}>
                            <FileDownloadIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                </Box>

                <CreateFieldDialog open={fieldCreateDialogOpen} onSave={fieldCreateDialogSaveHandler} onCancel={fieldCreateDialogCancelHandler}
                    title={fieldCreateDialogTitle} otherNames={otherFieldNames} />
                <CreateFieldDialog open={fieldEditDialogOpen} onSave={fieldEditDialogSaveHandler} onCancel={fieldEditDialogCancelHandler}
                    title={fieldEditDialogTitle} editOnly={true} field={selectedField} otherNames={otherFieldNames} />

                <EvaluateFormDialog open={previewFormOpen} form={selectedForm} onSave={previewSave} onCancel={() => setPreviewFormOpen(false)} preview={true} />
                <ExportFormDialog open={exportFormOpen} form={selectedForm} onExport={() => setExportFormOpen(false)} onCancel={() => setExportFormOpen(false)} 
                    files={exportFormFiles} title={selectedForm ? `Export Form "${selectedForm.label}"` : 'Export Form'} downloadLabel="Export" />
            </Box>

        </Box>
    );
}

function WorkflowFormLists(props) {

    const theme = useTheme();

    return (
        <Box sx={{ height: '100%', width: '100%', display: 'grid', gridTemplateColumns: '25% 3px 1fr', gridTemplateRows: '100%' }}>
            <Box sx={{ position: 'relative', width: '100%', height: '100%', padding: '1ex', paddingTop: '2ex', display: 'grid', gridTemplateRows: 'min-content min-content minmax(10px,1fr) min-content' }}>
                <Typography align="center" sx={{ paddingTop: '8px', paddingBottom: '2ex', fontSize: '18px', fontWeight: theme.typography.fontWeightBold }}>Selection Lists</Typography>
                <TextField
                    id="mwa-search"
                    type="search"
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
        </Box>
    );

}

export { WorkflowForms, WorkflowFormLists, EvaluateFormDialog };