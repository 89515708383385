import React, { Component, useState, useCallback, useEffect, useLayoutEffect } from "react";
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import debounce from 'lodash.debounce';
import { v4 as uuidv4 } from 'uuid';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { UserInfo, netGet, netPost, registerInitHook } from "../network";
import { ProcessLogViewer } from "./process";
import { ProcessOptionsDialog } from "./management-workflow-dialogs";
import { openPdfDocument, openDCRBinder }  from "../webviewer";
import FileDownloader from "../file-download";
import { SelectPdfDraftDialog, UploadFileDialog, SimpleUploadFileDialog, ViewDraftOutputDialog, AbortConfirmDialog } from "./workflow-select-draft";
import WorkflowModule from "./workflow";
import { ReactXMLEditor } from "components/xxe/xeditor/xeditor";
import { en } from "@faker-js/faker";

const DEFAULT_APPROVAL_DRAFT_LABEL = "Review Approval Draft";
const CANDIDATE_DRAFT_LABEL = "Edit Candidate Draft";
const REVIEW_CANDIDATE_DRAFT_LABEL = 'Review Candidate Draft';
const ANNOTATE_CANDIDATE_DRAFT_LABEL = 'Annotate Candidate Draft';
const OEM_REVISION_COPY_LABEL = 'Annotate OEM DCR';
const REVIEW_OEM_DCR_LABEL = 'Review OEM DCR';
const ANNOTATE_OEM_DCR_LABEL = 'Annotate OEM DCR';

var globalApproveBinder;

function DownloadFilesDialog(props) {
    const {
        title = 'Download Files',
        open,
        files,
        onCancel,
        onDone,
        ...other
    } = props;

    const [downloaded, setDownloaded] = useState(false);

    useEffect( () => {
        if ( open && files ) {
            console.log('DOWN DIALOG: ' + JSON.stringify(files));
            setDownloaded(false);
        }

    },[open,files]);

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if (downloaded) {
            if (typeof onDone === 'function') {
                onDone();
            }
        } else {
            if (typeof onCancel === 'function') {
                onCancel();
            }
        }
    };

    const handleDone = () => {
        setDownloaded(true);
    };


    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                  minWidth: 900,
                  maxHeight: 600
                }
              }}
        >
            <DialogTitle sx={{fontWeight: 'bold'}}>{title}</DialogTitle>
            <DialogContent>
                <Box sx={{paddingTop: 2, paddingBottom: 3}}>
                    <Typography sx={{color: 'gray', fontSize: '11pt'}}>
                        {Array.isArray(files) && files.map( f => f.name).reduce( (a, b) =>  a !== '' ? a + ', ' + b : b, '')}
                    </Typography>
                </Box>
                <FileDownloader files={files} onDone={handleDone} />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onCancelClick} sx={{minWidth: '7em'}}>Close</Button>
            </DialogActions>
        </Dialog>

    );

}

/*
    returns a Promise to whether the step can be claimed based on the action (promise with true or false value)
*/
const CanActionClaim = (props) => {
    const { stepInstance,
        workflowInstance,
        workflowDef,
        dcrPortfolio,
        oemPortfolio,
        action,
    } = props;

    // currently only using stepInstance and action, but may require other variables
    
    const peerReviewDcr = () => {
        return new Promise((resolve, reject) => {
            netPost('/api/workflow/step/inst/info', stepInstance)
                .then(response => response.json())
                .then(info => {
                    // console.log('Opening Review DCR files ' + JSON.stringify(info.file));
                    if (Array.isArray(info.documents) && info.documents.length > 0) {
                        // is there any PEER_REVIEW binders?
                        // already filtered by document in the backend if in context
                        // console.log('info.documents = ' + JSON.stringify(info.documents));
                        const res = info.documents
                            .map(doc => {
                                if (Array.isArray(doc.binders)) {
                                    const validBinders = doc.binders.filter(b => b.properties?.approvalStatus === 'PEER_REVIEW' && b.properties?.peerReviewer === UserInfo.info.name);
                                    const r = Array.isArray(validBinders) && validBinders.length > 0;
                                    // console.log('PEER can claim = ' + r);
                                    return r;
                                } else {
                                    return false;
                                }
                            })
                            .reduce(
                                (accumulator, currentValue) => accumulator || currentValue,
                                false
                            );
                        // console.log('Examining PEER reviews = ' + res);
                        resolve(res);
                    } else if (Array.isArray(info.file) && info.file.length >= 1) {
                        // console.log('PEER REVIEW file can claim = true');
                        resolve(true);
                    } else {
                        // console.log('PEER REVIEW default can claim = false');
                        resolve(false);
                    }
                });
        });
    };

    const updateDcr = () => {
        return new Promise((resolve, reject) => {
            netPost('/api/workflow/step/inst/info', stepInstance)
                .then(response => response.json())
                .then(info => {
                    // console.log('Opening Review DCR files ' + JSON.stringify(info.file));
                    if (Array.isArray(info.documents) && info.documents.length > 0) {
                        // is there any conditionally approved binders?
                        // already filtered by document in the backend if in context
                        const res = info.documents
                            .map(doc => {
                                if (Array.isArray(doc.binders)) {
                                    const validBinders = doc.binders.filter(b => {
                                        const otherSubs = Array.isArray(b.properties?.otherSubmitters) ? b.properties.otherSubmitters : [];
                                        return b.properties?.approvalStatus === 'COND_APPROVED' && (b.properties?.submitter === UserInfo.info.name || otherSubs.includes(UserInfo.info.name));
                                    });
                                    return Array.isArray(validBinders) && validBinders.length > 0;
                                } else {
                                    return false;
                                }
                            })
                            .reduce(
                                (accumulator, currentValue) => accumulator || currentValue,
                                false
                            );
                        // console.log('UPDATE DCR can claim = ' + res);
                        resolve(res);
                    } else if (Array.isArray(info.file) && info.file.length >= 1) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
        });
    };

    const reviewDcr = () => {
        return new Promise((resolve, reject) => {
            netPost('/api/workflow/step/inst/info', stepInstance)
                .then(response => response.json())
                .then(info => {
                    // console.log('Opening Review DCR files ' + JSON.stringify(info.file));
                    if (Array.isArray(info.documents) && info.documents.length > 0) {
                        // is there any binders not in peer review or conditionally approved?
                        // already filtered by document in the backend if in context
                        const res = info.documents
                            .map(doc => {
                                if (Array.isArray(doc.binders)) {
                                    const validBinders = doc.binders.filter(b => b.properties?.approvalStatus !== 'COND_APPROVED' && b.properties?.approvalStatus !== 'PEER_REVIEW');
                                    return Array.isArray(validBinders) && validBinders.length > 0;
                                } else {
                                    return false;
                                }
                            })
                            .reduce(
                                (accumulator, currentValue) => accumulator || currentValue,
                                false
                            );
                        resolve(res);
                    } else if (Array.isArray(info.file) && info.file.length >= 1) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
        });
    };

    const actionMap = {
        PEER_REVIEW_DCR: peerReviewDcr,
        UPDATE_DCR: updateDcr,
        REVIEW_DCR: reviewDcr,
        APPROVE_DCR: reviewDcr,
    };

    if ( typeof actionMap[action?.name] === 'function' ) {
        // If there's special case, evaluate if user can claim step
        // Function can return a promise with boolean value
        return actionMap[action.name]();
    }
    // can claim by default 
    return true;
};

function ChooseSupplementsDialog(props) {
    const {
        open,
        supplements,
        onCancel,
        onSave,
        ...other
    } = props;

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if ( typeof onCancel === 'function' ) {
            onCancel();
        }
    };

    const handleFileClick = (supp) => {
        if (supp) {
            if ( typeof onSave === 'function' ) {
                onSave(supp);
            }
        }
        onClose();
    }


    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                  minWidth: 500,
                  maxHeight: 600
                }
              }}
        >
            <DialogTitle sx={{fontWeight: 'bold'}}>Choose Supplement to Edit</DialogTitle>
            <DialogContent>
                {Array.isArray(supplements) &&
                    <Box>
                        <Box><Typography sx={{ fontWeight: 'bold' }}>Supplements</Typography></Box>
                        <List>
                            {supplements.map(item => (
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => handleFileClick(item)}>
                                        <ListItemIcon>
                                            <OpenInNewIcon fontSize="0.92em" fontWeight="bold" />
                                        </ListItemIcon>
                                        <ListItemText primary={`${item.number}: ${item.title}`} />
                                    </ListItemButton>
                                </ListItem>
                            )
                            )
                            }
                        </List>
                    </Box>
                }
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onCancelClick} sx={{minWidth: '7em'}}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}

function ChooseReviewDCRBindersDialog(props) {
    const {
        open,
        documents,
        onCancel,
        onSave,
        conditional=false, // include only COND_APPROVE binders, takes precedence over 'peer'
        peer=false, // include only PEER_REVIEW binders
        refPortfolio,
        ...other
    } = props;

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if ( typeof onCancel === 'function' ) {
            onCancel();
        }
    };

    const handleFileClick = (binder) => {
        /*
        WorkflowModule.pageIndexChange(1);
        const { annotationManager } = pdftronInstance.Core;
        annotationManager.setCurrentUser(UserInfo.info.name);
        pdftronInstance.UI.loadDocument('/file/' + UserInfo.info.sessionId + file);
        */
        if (Array.isArray(binder.files)) {
            if ( typeof onSave === 'function' ) {
                const isApprove = !peer;
                onSave(binder.binderId, isApprove, binder);
            }
            openDCRBinder(binder.files, refPortfolio);
            
        }
        onClose();
    }


    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                  minWidth: 500,
                  maxHeight: 600
                }
              }}
        >
            <DialogTitle sx={{fontWeight: 'bold'}}>Choose the DCR to Review</DialogTitle>
            <DialogContent>
                {Array.isArray(documents) &&
                    documents.map(doc => 
                        <Box>
                            <Box><Typography sx={{fontWeight: 'bold', fontSize: '110%'}}>{doc.title}</Typography></Box>
                            {doc.partName && doc.partTitle &&
                                <Box sx={{marginTop: '0.5ex'}}><Typography sx={{fontWeight: 'bold'}}>{`${doc.partName}: ${doc.partTitle}`}</Typography></Box>
                            }
                            <List>
                                {Array.isArray(doc.binders) && doc.binders.filter(b => {
                                    // console.log('binder: ' + b.name + ' = ' + JSON.stringify(b.properties?.otherSubmitters));
                                    const sub = b.properties?.submitter;
                                    const reviewer = b.properties?.peerReviewer;
                                    const otherSubs = Array.isArray(b.properties?.otherSubmitters) ? b.properties.otherSubmitters : [];
                                    let r = true;
                                    
                                    if (conditional) {
                                        r = b.properties?.approvalStatus === 'COND_APPROVED';
                                        if ( sub ) {
                                            r = r && (sub === UserInfo.info.name || otherSubs.includes(UserInfo.info.name));
                                        }
                                        // console.log(b.name + ': conditional=' + r + ', ' + b.properties.approvalStatus + ': ' + sub);
                                    } else if ( peer ) {
                                        r = b.properties?.approvalStatus === 'PEER_REVIEW';
                                        if ( reviewer ) {
                                            r = r && (reviewer === UserInfo.info.name);
                                        }
                                    } else {
                                        r = b.properties?.approvalStatus !== 'COND_APPROVED' && b.properties?.approvalStatus !== 'PEER_REVIEW';
                                    }
                                    return r;
                                }).map(item => (
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => handleFileClick(item)}>
                                            <ListItemIcon>
                                                <OpenInNewIcon fontSize="0.92em" fontWeight="bold" />
                                            </ListItemIcon>
                                            <ListItemText primary={`${item.name}: ${item.description}`} />
                                        </ListItemButton>
                                    </ListItem>
                                )
                                )
                                }
                            </List>
                        </Box>)
                }
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onCancelClick} sx={{minWidth: '7em'}}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}

function ChooseViewPdfDialog(props) {
    const {
        open,
        files,
        onCancel,
        previousDrafts,
        dcrPortfolio,
        oemPortfolio,
        ...other
    } = props;

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if ( typeof onCancel === 'function' ) {
            onCancel();
        }
    };

    const handleFileClick = (file, label, base) => {
        /*
        WorkflowModule.pageIndexChange(1);
        const { annotationManager } = pdftronInstance.Core;
        annotationManager.setCurrentUser(UserInfo.info.name);
        pdftronInstance.UI.loadDocument('/file/' + UserInfo.info.sessionId + file);
        */
        const isCand = typeof base !== 'undefined' ? base : false;
        openPdfDocument(file, label, previousDrafts, dcrPortfolio, oemPortfolio, isCand);
        onClose();
    }


    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                  minWidth: 500,
                  maxHeight: 600
                }
              }}
        >
            <DialogTitle sx={{fontWeight: 'bold'}}>Choose the PDF File to Open</DialogTitle>
            <DialogContent>
                <List>
                    { files.map( item => (
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => handleFileClick(item.path, item.label, item.base)}>
                                <ListItemIcon>
                                    <OpenInNewIcon fontSize="0.92em" fontWeight="bold"/>
                                </ListItemIcon>
                                <ListItemText primary={item.label} />
                            </ListItemButton>
                        </ListItem>
                    )
                    )
                    }
                </List>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onCancelClick} sx={{minWidth: '7em'}}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}

/**
 * Similar to WorkflowActionButtons but this is just to report
 * action status, usually just text lines.
 * @param {*} props 
 */
const WorkflowActionStatus = (props) => {
    const {
        stepInstance,
        workflowInstance,
        workflowDef,
        ...other
    } = props;

    const [userActionMap, setUserActionMap] = useState({});

    useEffect( () => {
        const userMap = {};

        userMap['APPROVE_CAND_DRAFT'] = approveCandDraftUserAction;
        userMap['APPROVE_DRAFT'] = approveCandDraftUserAction;
        userMap['PRE_APPROVE_DRAFT'] = approveCandDraftUserAction;
        setUserActionMap(userMap);
    },[]);

    const approveCandDraftUserAction = (action,index) => {
        const comp = [];
        if (action.name === 'APPROVE_CAND_DRAFT' || action.name === 'APPROVE_DRAFT' || action.name === 'PRE_APPROVE_DRAFT') {
            const candDraft = action.name === 'APPROVE_CAN_DRAFT' ? 'Candidate ' : '';
            const isMultiLock = stepInstance?.properties?.multiLock;
            
            if ( isMultiLock ) {
                const states = {};
                const results = action.properties?.actionResults;
                const myName = UserInfo.info.name;
                if (results) {
                    for (let username in results) {
                        if (results.hasOwnProperty(username)) {
                            if ( myName !== username ) {
                                const value = results[username];
                                if (value === 'APPROVE' || value === 'COND_APPROVE') {
                                    let vs = states[value];
                                    if (Array.isArray(vs)) {
                                        vs.push(username);
                                    } else {
                                        vs = [];
                                        vs.push(username);
                                        states[value] = vs;
                                    }
                                }
                            }
                        }
                    }
                    for(let st in states) {
                        const c = (
                            <Box sx={{display: 'flex', ml: 1, mt: 1, paddingLeft: '0.5ex'}}>
                                <Typography color={st === 'COND_APPROVE' ? 'warning' : 'success'} >{st === 'COND_APPROVE' ? candDraft + 'Draft Conditionally Approved' : candDraft + 'Draft Approved'}</Typography>
                                <Typography>{'\xa0by ' + states[st].reduce((a,c) => a==='' ? c : a + ', ' + c, '')}</Typography>
                            </Box>
                        );
                        comp.push(c);
                    }
                }
            }
        }
        return comp;
    };

    const createWorkflowActionStatus = () => {
        const buttons = [];
        if (stepInstance && (UserInfo.info.name === stepInstance.user || (Array.isArray(stepInstance.properties.stepUsers) && stepInstance.properties.stepUsers.includes(UserInfo.info.name)))) {
            if (stepInstance.properties?.actions && Array.isArray(stepInstance.properties.actions)) {
                // console.log('Get workflow actions: ' + JSON.stringify(stepInstance.properties.actions));
                const actions = stepInstance.properties.actions;
                actions.forEach((action, index) => {
                    // if ( action.properties?.exec === 'USER' ) {
                        if ( typeof userActionMap[action.name] === 'function' ) {
                            // console.log('Found USER action: ' + action.name);
                            const uas = userActionMap[action.name](action,index,actions);
                            if ( Array.isArray(uas) ) {
                                uas.forEach( b => buttons.push(b));
                            }
                        }
                    // }
                });

            }
        }
        return buttons;
    };

    return (
        <React.Fragment>
            {createWorkflowActionStatus()}
        </React.Fragment>
    );


}

const WorkflowActionButtons = (props) => {
    const {
        stepInstance,
        workflowInstance,
        workflowDef,
        dcrPortfolio,
        oemPortfolio,
        refPortfolio,
        ...other
    } = props;

    // all def steps including substeps
    const [allSteps, setAllSteps] = useState([]);
    // steps' SIDs at this level, excluding the nested subworkflows steps
    const [levelSteps, setLevelSteps] = useState([]);
    const [enterActionMap, setEnterActionMap] = useState({});
    const [exitActionMap, setExitActionMap] = useState({});
    const [userActionMap, setUserActionMap] = useState({});
    const [processInstId, setProcessInstId] = React.useState('');
    const [viewProcessLogsOpen, setViewProcessLogsOpen] = React.useState(false);
    const [dcrDocuments, setDcrDocuments] = React.useState([]);
    const [dcrDocumentReviewOpen, setDcrDocumentReviewOpen] = React.useState(false);
    const [dcrBinderId, setDcrBinderId] = React.useState('');
    const [currentDcr, setCurrentDcr] = useState(null);
    const [conditionallyOnly, setConditionallyOnly] = useState(false);
    const [peerReviewOnly, setPeerReviewOnly] = useState(false);
    const [viewPdfs, setViewPdfs] = React.useState([]);
    const [viewPdfOpen, setViewPdfOpen] = React.useState(false);
    const [previousDrafts, setPreviousDrafts] = React.useState([]);
    const [candidateDraftEnable, setCandidateDraftEnable] = React.useState(true);
    const [candidateDraftLabel, setCandidateDraftLabel] = React.useState(CANDIDATE_DRAFT_LABEL);
    const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);
    const [workPortfolio, setWorkPortfolio] = useState(null);
    const [downloadFiles, setDownloadFiles] = useState([]);
    const [uploadDlibOpen, setUploadDlibOpen] = React.useState(false);
    const [uploadDlibDescription, setUploadDlibDescription] = React.useState('');
    const [uploadDlibShelf, setUploadDlibShelf] = React.useState(null);
    const [currentAction, setCurrentAction] = useState(null);
    const [currentActionIndex, setCurrentActionIndex] = useState(-1);
    // const [viewDraftEnable, setViewDraftEnable] = useState(false);
    const [suppDocOpen, setSuppDocOpen] = useState(false);
    const [supplements, setSupplements] = useState([]);
    const [editSupplementDisabled, setEditSupplementDisabled] = useState(false);
    const [duId, setDuId] = useState(null);

    const [draftOptionsOpen, setDraftOptionsOpen] = React.useState(false);
    const [draftOptions, setDraftOptions] = React.useState({});
    const [editionOptionsFlag, setEditionOptionsFlag] = React.useState(false);
    const [viewDraftOutputOpen, setViewDraftOutputOpen] = React.useState(false);
    const [viewDraftEnable, setViewDraftEnable] = React.useState(true);
    const [approvalDraftEnable, setApprovalDraftEnable] = React.useState(true);
    const [approvalDraftLabel, setApprovalDraftLabel] = React.useState(DEFAULT_APPROVAL_DRAFT_LABEL);
    const [abortDialogOpen, setAbortDialogOpen] = React.useState(false);
    const [actionIndex, setActionIndex] = React.useState(-1);

    const [approveBinder, setApproveBinder] = React.useState(null);
    // const [forceUpdate, setForceUpdate] = useState(0);


    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 320,
            },
        },
    };

    useEffect( () => {
        const userMap = {};
        const enterMap = {};
        const exitMap = {};
        enterMap['RUN_EDITION_PAUSE'] = editionEnterAction;
        enterMap['RESUME_EDITION'] = editionEnterAction;
        // enterMap['OPEN_EDITION'] = editionEnterAction;
        userMap['APPROVE_DCR'] = approveDcrUserAction;
        userMap['REVIEW_DCR'] = reviewDcrUserAction;
        userMap['PEER_REVIEW_DCR'] = reviewDcrUserAction;
        userMap['UPDATE_DCR'] = reviewDcrUserAction;
        userMap['RESUBMIT_DCR'] = resubmitDcrUserAction;
        userMap['APPROVE_CAND_DRAFT'] = approveCandDraftUserAction;
        userMap['APPROVE_DRAFT'] = approveCandDraftUserAction;
        userMap['PRE_APPROVE_DRAFT'] = approveCandDraftUserAction;
        userMap['APPROVE_OEM_DCR'] = approveDraftOemUserAction;
        userMap['EDIT_EXTERNAL_DOC'] = editExternalUserAction;
        userMap['APPROVE_EXTERNAL_DOC'] = approveExternalDocUserAction;
        userMap['APPROVE_EXTERNAL_DOC_OWNER'] = approveExternalDocUserAction;
        userMap['APPROVE_EXTERNAL_DOC_CORP'] = approveExternalDocUserAction;
        userMap['CHECK_EXTERNAL_DOC'] = approveExternalDocUserAction;
        userMap['EDIT_DOC_UNIT'] = editDocUnitUserAction;
        userMap['EDIT_DOC'] = editDocUserAction;

        userMap['REVIEW_CAND_DRAFT'] = reviewCandidateDraftAction;
        userMap['ANNOT_CAND_DRAFT'] = reviewCandidateDraftAction;
        userMap['EDIT_CAND_DRAFT'] = reviewCandidateDraftAction;
        userMap['RUN_DRAFT'] = runDratUserAction;
        userMap['RUN_APPROVAL_DRAFT'] = runDratUserAction;
        userMap['CLOSE_EXTERNAL_DOC'] = signExternalDocUserAction;
        userMap['SIGN_EXTERNAL_DOC'] = signExternalDocUserAction;
        // userMap['UPLOAD'] = uploadUserAction;
        setEnterActionMap(enterMap);
        setUserActionMap(userMap);
        setExitActionMap(exitMap);
    },[]);

    useEffect(() => {
        if ( Array.isArray(workflowDef?.steps) ) {
            let lsteps = [];
            workflowDef.steps.forEach( item => {
                if ( ! item.sid ) {
                    item.sid = item.id;
                }
                if ( ! item.properties ) {
                    item.properties = {};
                }
                if ( ! item.properties.hnum ) {
                    item.properties.hnum = `${item.id + 1}`;
                }
                lsteps.push(item.sid);
            });
            setLevelSteps(lsteps);

            let asteps = [];
            loadAllSteps(asteps, workflowDef.steps);
            setAllSteps(asteps);
        }

    }, [workflowDef]);

    useEffect(() => {
        // console.log('Workflow instance changed: ' + JSON.stringify(workflowInstance));
        loadCurrentBinder();
        if ( workflowInstance?.properties?.part && dcrPortfolio ) {
            const part = workflowInstance.properties.part;
            netGet('/api/dlib/binder/list?did=' + dcrPortfolio.docId + '&name=' + encodeURIComponent(part.number) + '&type=PART')
                .then(response => response.json())
                .then(binders => {
                    // console.log('FOUND part DCR binders: ' + JSON.stringify(binders));
                    const binderIds = binders.map(b => b.id);
                    if (Array.isArray(binderIds) && binderIds.length > 0) {
                        const bfiles = [];
                        const dcrFiles = dcrPortfolio.properties?.files;
                        if (Array.isArray(dcrFiles)) {
                            dcrFiles.forEach( file => {
                                if ( file.properties?.partBinderId) {
                                    if ( binderIds.includes(file.properties.partBinderId)) {
                                        bfiles.push(file);
                                    } 
                                } else {    
                                    bfiles.push(file);
                                }
                            });
                            dcrPortfolio.properties.files = bfiles;
                        }
                    }
                }).catch(error => console.log('Error getting part binders: ' + error));
        }
        // console.log('WorkflowInstance changed: duId = ' + workflowInstance?.properties?.duId);
        setDuId(workflowInstance?.properties?.duId);
        setEditSupplementDisabled(getButtonRolesDisabled() || !workflowInstance?.properties?.duId);
        // console.log('Setting APPROVE BINDER = ' + JSON.stringify(workflowInstance?.properties?.approveBinder));
        setApproveBinder(workflowInstance?.properties?.approveBinder);
        globalApproveBinder = workflowInstance?.properties?.approveBinder;
        // setForceUpdate(forceUpdate => forceUpdate + 1);
    }, [workflowInstance, dcrPortfolio]);

    useEffect(() => {
        // console.log('Type of startDate: ' + typeOf(si.startDate) + ' = ' + si.startDate);
        if (Array.isArray(stepInstance?.properties?.actions)) {
            let needsInfo = false;
            stepInstance.properties.actions.forEach(item => {
                if ( item.name === 'APPROVE_EXTERNAL_DOC' ) {
                    needsInfo = true;
                    setViewDraftEnable(true);
                    netPost('/api/workflow/step/inst/info', stepInstance)
                        .then(response => response.json())
                        .then(info => {
                            if ( !(Array.isArray(info.files) && info.files.length > 0 ) ) {
                                setViewDraftEnable(false);
                            }
                        });
                } else if (item.name === 'RUN_DRAFT' || item.name === 'RUN_APPROVAL_DRAFT' || item.name === 'RUN_EDITION_PAUSE') {
                    if (item.properties) {
                        const options = { ...item.properties };
                        setDraftOptions(options);
                    }
                } else if ( item.name === 'VIEW_DRAFT' || item.name === 'VIEW_APPROVAL_DRAFT' ) {
                    needsInfo = true;
                    setApprovalDraftEnable(true);
                    setApprovalDraftLabel(DEFAULT_APPROVAL_DRAFT_LABEL);
                    setViewDraftEnable(true);
                    netPost('/api/workflow/step/inst/info', stepInstance)
                        .then(response => response.json())
                        .then(info => {
                            if ( !(Array.isArray(info.files) && info.files.length > 0 ) ) {
                                setViewDraftEnable(false);
                            }
                            if ( !(Array.isArray(info.file) && info.file.length > 0 ) ) {
                                setApprovalDraftEnable(false);
                            } else {
                                const ifile = info.file[0];
                                if ( typeof ifile?.fid === 'string' && ifile.fid.length > 0 ) {
                                    setApprovalDraftLabel(CANDIDATE_DRAFT_LABEL);
                                }
                            }
                        });
                } 
                /*
                else if ( item.name === 'REVIEW_CAND_DRAFT' || item.name === 'APPROVE_CAND_DRAFT' ) {
                    netPost('/api/workflow/step/inst/info', stepInstance)
                        .then(response => response.json())
                        .then(info => {
                            if ( Array.isArray(info.files) ) {
                                console.log('Got candidate draft: ' + info.files.length);
                            }
                        });
                }
                */
            });
            
        }
        // console.log('new stepInstance: ' + JSON.stringify(stepInstance?.properties?.name));
    },[stepInstance]);


    const loadAllSteps = (stepList, steps) => {
        if (Array.isArray(steps) && Array.isArray(stepList)) {
            steps.forEach(item => {
                stepList.push(item);
                if (item.name === 'CALL') {
                    if (item.properties?.substeps) {
                        loadAllSteps(stepList, item.properties.substeps);
                    }
                }
            });
        }
    };

    const loadCurrentBinder = () => {
        if ( workflowInstance?.properties ) {
            if ( workflowInstance.properties.approveBinderId ) {
                setDcrBinderId(workflowInstance.properties.approveBinderId);
                netGet('/api/dlib/binder/get/' + encodeURIComponent(workflowInstance.properties.approveBinderId))
                    .then(response => {
                        if ( response.ok) {
                            response.json().then( binder => {
                                setCurrentDcr({...binder});
                                // console.log('Current loaded DCR: ' + binder?.name + ': ' + binder?.description);
                            });
                        }
                    }).catch(error => console.log('Error getting binder: ' + error));
            }
        }
        
    };


    const getButtonRolesDisabled = () => {
        if ( ! stepInstance ) {
            return true;
        }
        // console.log('Current step: ' + instance.stepId);
        const stepDef = allSteps[workflowInstance.stepId];
        // console.log('Current workflow def: ' + JSON.stringify(workflowDef));
        // console.log('Current step def:' + workflowDef.id + ' : ' +  JSON.stringify(stepDef));
        // console.log('Current step inst: ' + JSON.stringify(stepInstance));
        // console.log('Current user: ' + JSON.stringify(UserInfo.info));
        if ( Array.isArray(stepInstance.properties.stepUsers) ) {
            // console.log('Active step: I have users...');
            if ( stepInstance.properties.stepUsers.includes(UserInfo.info.name) ) {
                return false;
            }
            // I can claim it if it's multiLock and my roles match any of step roles
            if (stepDef && stepDef.properties && stepDef.properties.multiLock && stepInstance.properties.stepUsers.length > 0) {
                if (stepDef.roles && stepDef.roles.length > 0) {
                    return !stepDef.roles.some((role) => UserInfo.info.roles.includes(role));
                } else {
                    return false;
                }
            } else if ( stepInstance.properties.stepUsers.length > 0 ) {
                return true;
            } else if (stepDef && stepDef.roles && stepDef.roles.length > 0) {
                return !stepDef.roles.some((role) => UserInfo.info.roles.includes(role));
            } else {
                return false;
            }
        } else {
            // console.log("Active step: I don't have users");
            if (stepDef && stepDef.roles && stepDef.roles.length > 0) {
                const ican = !stepDef.roles.some((role) => UserInfo.info.roles.includes(role));
                // console.log('I can claim: ' + ican);
                return ican;
            } else {
                return false;
            }
        }
        return true;
        // return stepInstance && UserInfo.info.name === stepInstance.user ? false : !(stepInstance && !stepInstance.user);
    };

    const getButtonDisabled = () => {
        
        let rd = getButtonRolesDisabled();
        

        // console.log('STEP TYPE: ' + stepInstance?.properties?.name);
        const buttonLabel = getButtonLabel();
        /*
        if ( stepInstance?.properties?.state === 'RUNNING' ) {
            return true;
        }
        */
        if ( stepInstance?.properties?.name === 'FORM' && buttonLabel === 'Go' ) {
            if ( Array.isArray(stepInstance.properties?.form?.fields) ) {
                const fields = stepInstance.properties.form.fields;
                for(let i=0; i<fields.length; i++) {
                    const field = fields[i];
                    if ( field.required ) {
                        if ( typeof field.value === 'undefined' ) {
                            return true;
                        }
                        if ( field.value === '' ) {
                            return true;
                        }
                    }
                }
            }
        }
        if ( stepInstance?.properties?.actions && buttonLabel === 'Go' ) {
            stepInstance.properties.actions.forEach( action => {
                if ( action.name === 'UPLOAD' && action.target === 'DCR' ) {
                    if ( typeof action.path !== 'string' ) {
                        rd = true;
                    }
                } else if ( action.name === 'UPLOAD' && action.target === 'DLUP') {
                    // console.log('DLUP files = ' + JSON.stringify(action.properties?.files));
                    if ( ! (Array.isArray(action.properties?.files) && action.properties.files.length > 0) ) {
                        rd = true;
                        // console.log('DLUP: disabled: ' + true);
                    } else {
                        // console.log('DLUP disabled = ' + false);
                        
                    }
                }
            });
        }

        return rd;
    };

    const getButtonLabel = () => {
        if ( ! stepInstance ) {
            return 'Claim';
        }
        const stepDef = allSteps[workflowInstance.stepId];
        // console.log('Current step:' + stepDef.name);
        // console.log('Instance: ' + JSON.stringify(stepInstance));
        /*
        if ( stepInstance?.properties?.state === 'RUNNING' ) {
            return 'Go';
        }
        */
        if ( Array.isArray(stepInstance.properties.stepUsers) ) {
            // console.log('Active step: I have users...');
            if (stepInstance.properties.stepUsers.includes(UserInfo.info.name)) {
                let verb = 'Go';
                /*
                if (Array.isArray(stepInstance?.properties?.actions)) {
                    stepInstance.properties.actions.forEach(action => {
                        if (action.name === 'RUN_DRAFT' || action.name === 'RUN_APPROVAL_DRAFT' || action.name === 'RUN_EDITION_PAUSE') {
                            verb = 'Process';
                        }
                    });
                }
                */
                return verb;
            }
            // I can claim it if it's multiLock and my roles match any of step roles
            if (stepDef && stepDef.properties && stepDef.properties.multiLock && stepInstance.properties.stepUsers.length > 0) {
                if (stepDef.roles && stepDef.roles.length > 0) {
                    return stepDef.roles.some((role) => UserInfo.info.roles.includes(role)) ? 'Claim' : 'Claim'; // Claim label anyway since it will be disabled if not relevant
                }
            }
        }
        return 'Claim';
    };

    const handleStartAction = (sinst, index, action, verb='START') => {
        netGet(`/api/workflow/action/complete/${workflowInstance.id}/${index}?action=${verb}&name=${action.name}` )
            .then(response => {
                if (!response.ok) {
                    console.log('Could not complete action: error = ' + response.status);
                    
                }
            });
    };

    const uploadUserAction = (action) => {
        const buttons = [];
        /*
        if (action.name === 'UPLOAD' && action.properties?.target === 'DCR') {
            // console.log('Found DCR actions: ' + JSON.stringify(action));
            const button = (
                <Button
                    variant="outlined"
                    onClick={() => handleUploadDCR(stepInstance, index)}
                    sx={{ mt: 1, mr: 1 }}
                    disabled={getButtonRolesDisabled()}
                >
                    Upload DCR File
                </Button>
            );
            buttons.push(button);
        } else if (action.name === 'UPLOAD' && action.properties?.target === 'DLUP') {
            // console.log('Found DCR actions: ' + JSON.stringify(action));
            const button = (
                <Button
                    variant="outlined"
                    onClick={() => handleUploadDLIB(stepInstance, index)}
                    sx={{ mt: 1, mr: 1 }}
                    disabled={getButtonRolesDisabled()}
                >
                    Upload Digital Library Files
                </Button>
            );
            buttons.push(button);
        }
        */
        return buttons;
    };

    const handleDraftOptions = (sinst, draftIndex) => {
        setDraftOptionsOpen(true);
    };

    const cancelDraftOptions = () => {
        setDraftOptionsOpen(false);
        setEditionOptionsFlag(false);
    }

    const saveDraftOptions = (options) => {
        setDraftOptionsOpen(false);
        setEditionOptionsFlag(false);
        setDraftOptions(options);
        if ( Array.isArray(stepInstance?.properties?.actions) ) {
            stepInstance.properties.actions.forEach( action => {
                if ( action.name === 'RUN_DRAFT' || action.name === 'RUN_APPROVAL_DRAFT' || action.name === 'RUN_EDITION_PAUSE') {
                    if ( action.properties ) {
                        action.properties = {...action.properties, ...options};
                    } else {
                        action.properties = { ...options};
                    }
                }
            });
        }
        netPost('/api/workflow/step/update' , stepInstance )
            .then(response => {
                if (!response.ok) {
                    console.log('Could not update step: error = ' + response.status);
                    
                }
            });
    };

    const handleStartDraftOrEdition = (sinst, index, action) => {
        netGet(`/api/workflow/action/complete/${workflowInstance.id}/${index}?action=START&name=${action.name}` )
            .then(response => {
                if (!response.ok) {
                    console.log('Could not complete action: error = ' + response.status);
                    
                }
            });
    };

    const handleAbortDraft = ( sinst, index) => {
        setAbortDialogOpen(true);
        setActionIndex(index);
        /*
        sinst.properties.completeAction = 'ABORT';
        console.log('Aborted step: ' + JSON.stringify(sinst) );
        netPost('/api/workflow/step/update' , sinst )
            .then(response => {
                if (!response.ok) {
                    console.log('Could not update step to abort: error = ' + response.status);
                    
                } else {
                    // this should abort the draft and put step back in unlocked state
                    setActionButtonDisabled(true);
                    netGet('/api/workflow/step/complete/' + instance.id)
                        .then(response => {
                            if (!response.ok) {
                                console.log('Could not complete step: error = ' + response.status);
                            }
                        });
                }
            });
        */
    };

    const abortConfirmSave = (index) => {
        setAbortDialogOpen(false);
        netGet(`/api/workflow/action/complete/${workflowInstance.id}/${index}?action=ABORT` )
            .then(response => {
                if (!response.ok) {
                    console.log('Could not abort action: error = ' + response.status);
                    
                }
            });
        /*
        stepInstance.properties.completeAction = 'ABORT';
        // console.log('Aborted step: ' + JSON.stringify(sinst) );
        netPost('/api/workflow/step/update' , stepInstance )
            .then(response => {
                if (!response.ok) {
                    console.log('Could not update step to abort: error = ' + response.status);
                    
                } else {
                    // this should abort the draft and put step back in unlocked state
                    setActionButtonDisabled(true);
                    netGet('/api/workflow/step/complete/' + workflowInstance.id)
                        .then(response => {
                            if (!response.ok) {
                                console.log('Could not complete step: error = ' + response.status);
                            }
                        });
                }
            });
        */
    }

    const viewDraftOutputSave = (target) => {
        setViewDraftOutputOpen(false);
        if ( target && target.path && target.label) {
            openPdfDocument(target.path, target.label, previousDrafts, dcrPortfolio, oemPortfolio, false, refPortfolio);
        }
    };

    const processRunDraftButtons = (action,index,actions) => {
        const buttons = [];
        const button = (
            <Button
                variant="outlined"
                onClick={() => handleDraftOptions(stepInstance, index)}
                sx={{ mt: 1, mr: 1, ml: 0}}
                disabled={getButtonRolesDisabled()}
            >
                Set Options
            </Button>
        );
        buttons.push(button);
        
        const button2 = (
            <Button
                variant="outlined"
                onClick={() => handleStartDraftOrEdition(stepInstance, index, action)}
                sx={{ mt: 1, mr: 1 }}
                disabled={getButtonRolesDisabled()}
            >
                Process Draft
            </Button>
        );
        buttons.push(button2);
        return buttons;

    }


    const runDratUserAction = (action,index,actions) => {
        const buttons = [];
        const praction = actions.find(a => a.name === 'PROMOTE_DRAFT');
        const hasNoPromote = ! praction || praction.name !== 'PROMOTE_DRAFT';
        if ( action.name === 'RUN_DRAFT'  || action.name === 'RUN_APPROVAL_DRAFT') {
            if (action.properties?.actionState === 'RUNNING') {
                // console.log('Found RunDraft action: ' + JSON.stringify(action));
                const button1 = (
                    <Button
                        variant="outlined"
                        sx={{ mt: 1, mr: 1 }}
                        disabled={true}
                        color="info"
                    >
                        In Progress
                    </Button>
                );
                buttons.push(button1);

                const button2 = (
                    <Button
                        variant="outlined"
                        onClick={() => handleAbortDraft(stepInstance, index)}
                        sx={{ mt: 1, mr: 1, ml: 0 }}
                        disabled={getButtonRolesDisabled()}
                    >
                        Abort Process
                    </Button>
                );
                buttons.push(button2);
                
                // if (hasNoPromote) {
                    const button3 = (
                        <Button
                            variant="outlined"
                            onClick={() => handleViewProcessLogs(stepInstance, index)}
                            sx={{ mt: 1, mr: 1 }}
                            disabled={getButtonRolesDisabled()}
                        >
                            View Process Log
                        </Button>
                    );
                    buttons.push(button3);
                // }
            } else if (action.properties?.actionState === 'FINISHED' || action.properties?.actionState === 'COMPLETED') {
                const button1 = (
                    <Button
                        variant="outlined"
                        sx={{ mt: 1, mr: 1 }}
                        disabled={false}
                        color="success"
                    >
                        Process Completed
                    </Button>
                );
                buttons.push(button1);
                // if (hasNoPromote) {
                    const button3 = (
                        <Button
                            variant="outlined"
                            onClick={() => handleViewProcessLogs(stepInstance, index)}
                            sx={{ mt: 1, mr: 1 }}
                            disabled={getButtonRolesDisabled()}
                        >
                            View Process Log
                        </Button>
                    );
                    buttons.push(button3);
                // }
                const rdb = processRunDraftButtons(action, index, actions);
                if (Array.isArray(rdb)) {
                    rdb.forEach(item => buttons.push(item));
                }
            } else if (action.properties?.actionState === 'FAILED' ) {
                const button1 = (
                    <Button
                        variant="outlined"
                        sx={{ mt: 1, mr: 1 }}
                        disabled={false}
                        color="error"
                    >
                        Process Failed
                    </Button>
                );
                buttons.push(button1);
                // if (hasNoPromote) {
                    const button3 = (
                        <Button
                            variant="outlined"
                            onClick={() => handleViewProcessLogs(stepInstance, index)}
                            sx={{ mt: 1, mr: 1 }}
                            disabled={getButtonRolesDisabled()}
                        >
                            View Process Log
                        </Button>
                    );
                    buttons.push(button3);
                // }
                const rdb = processRunDraftButtons(action, index, actions);
                if (Array.isArray(rdb)) {
                    rdb.forEach(item => buttons.push(item));
                }
            } else {
                /*
                const button = (
                    <Button
                        variant="outlined"
                        onClick={() => handleDraftOptions(stepInstance, index)}
                        sx={{ mt: 1, mr: 1, ml: 0}}
                        disabled={getButtonRolesDisabled()}
                    >
                        Set Options
                    </Button>
                );
                buttons.push(button);
                
                const button2 = (
                    <Button
                        variant="outlined"
                        onClick={() => handleStartDraftOrEdition(stepInstance, index, action)}
                        sx={{ mt: 1, mr: 1 }}
                        disabled={getButtonRolesDisabled()}
                    >
                        Process
                    </Button>
                );
                buttons.push(button2);
                */
               const rdb = processRunDraftButtons(action,index,actions);
               if ( Array.isArray(rdb) ) {
                rdb.forEach(item => buttons.push(item));
               }
            }
        }
        return buttons;
    };

    const handleViewProcessLogs = (sinst, index) => {
        const pid = workflowInstance.properties.jobId;
        // console.log("PID = " + pid);
        setProcessInstId(pid);
        setViewProcessLogsOpen(true)
    };

    const editionEnterAction = (action, index) => {
        const buttons = [];
        if (action.name === 'RUN_EDITION_PAUSE' || action.name === 'RESUME_EDITION' || action.name === 'OPEN_EDITION') {
            if (action.properties?.actionState === 'RUNNING') {
                // console.log('Found RunDraft action: ' + JSON.stringify(action));
                // abort action is not draft specific
                const button1 = (
                    <Button
                        variant="outlined"
                        sx={{ mt: 1, mr: 1 }}
                        disabled={true}
                        color="info"
                    >
                        In Progress
                    </Button>
                );
                buttons.push(button1);
                const button3 = (
                    <Button
                        variant="outlined"
                        onClick={() => handleViewProcessLogs(stepInstance, index)}
                        sx={{ mt: 1, mr: 1 }}
                        disabled={getButtonRolesDisabled()}
                    >
                        {action.name === 'RUN_EDITION_PAUSE' || action.name === 'OPEN_EDITION' ? 'View Process Log' : 'View Signing Log'}
                    </Button>
                );
                buttons.push(button3);
            } else if (action.properties?.actionState === 'FINISHED' || action.properties?.actionState === 'COMPLETED') {
                const button1 = (
                    <Button
                        variant="outlined"
                        sx={{ mt: 1, mr: 1 }}
                        disabled={false}
                        color="success"
                    >
                        Process Completed
                    </Button>
                );
                buttons.push(button1);
                const button3 = (
                    <Button
                        variant="outlined"
                        onClick={() => handleViewProcessLogs(stepInstance, index)}
                        sx={{ mt: 1, mr: 1 }}
                        disabled={getButtonRolesDisabled()}
                    >
                        {action.name === 'RUN_EDITION_PAUSE' || action.name === 'OPEN_EDITION' ? 'View Process Log' : 'View Signing Log'}
                    </Button>
                );
                buttons.push(button3);
            } else if (action.properties?.actionState === 'FAILED') {
                const button1 = (
                    <Button
                        variant="outlined"
                        sx={{ mt: 1, mr: 1 }}
                        disabled={false}
                        color="error"
                    >
                        Process Failed
                    </Button>
                );
                buttons.push(button1);
                const button3 = (
                    <Button
                        variant="outlined"
                        onClick={() => handleViewProcessLogs(stepInstance, index)}
                        sx={{ mt: 1, mr: 1 }}
                        disabled={getButtonRolesDisabled()}
                    >
                        {action.name === 'RUN_EDITION_PAUSE' || action.name === 'OPEN_EDITION' ? 'View Process Log' : 'View Signing Log'}
                    </Button>
                );
                buttons.push(button3);
            }
        }
        return buttons;
    }

    const handleCloseExternalDoc = (sinst, index, action) => {
        handleStartAction(sinst, index, action);
    };

    const signExternalDocUserAction = (action, index) => {
        const buttons = [];
        if (action.name === 'CLOSE_EXTERNAL_DOC' || action.name === 'SIGN_EXTERNAL_DOC' ) {
            if (action.properties?.actionState === 'FINISHED' || action.properties?.actionState === 'COMPLETED') {
                const button1 = (
                    <Button
                        variant="outlined"
                        sx={{ mt: 1, mr: 1 }}
                        disabled={false}
                        color={'success'}
                    >
                        Document signed
                    </Button>
                );
                buttons.push(button1);

            } else {
                const button = (
                    <Button
                        variant="outlined"
                        onClick={() => handleCloseExternalDoc(stepInstance, index, action)}
                        sx={{ mt: 1, mr: 1 }}
                        disabled={getButtonRolesDisabled()}
                    >
                        Sign
                    </Button>
                );
                buttons.push(button);
            }
        }
        return buttons;
    }

    const candidateDraftUserAction = (action) => {
        const buttons = [];
        if (action.name === 'EDIT_CAND_DRAFT' || action.name === 'REVIEW_CAND_DRAFT' || action.name === 'ANNOT_CAND_DRAFT') {
            const button = (
                <Button
                    variant="outlined"
                    onClick={() => handleCandidateDraft(stepInstance)}
                    sx={{ mt: 1, mr: 1 }}
                    disabled={getButtonDisabled() || !candidateDraftEnable}
                >
                    {action.name === 'REVIEW_CAND_DRAFT' ? REVIEW_CANDIDATE_DRAFT_LABEL : ANNOTATE_CANDIDATE_DRAFT_LABEL}
                </Button>
            );
            buttons.push(button);
        }
        return buttons;
    }

    const editionUserAction = (action) => {
        const buttons = [];
        /*
        if (action.name === 'RUN_EDITION_PAUSE' || action.name === 'RESUME_EDITION' || action.name === 'OPEN_EDITION') {
            if (action.properties?.actionState === 'RUNNING') {
                // console.log('Found RunDraft action: ' + JSON.stringify(action));
                // abort action is not draft specific
                const button1 = (
                    <Button
                        variant="outlined"
                        sx={{ mt: 1, mr: 1 }}
                        disabled={true}
                        color="info"
                    >
                        In Progress
                    </Button>
                );
                buttons.push(button1);
                if (action.name === 'RUN_EDITION_PAUSE') {
                    const button2 = (
                        <Button
                            variant="outlined"
                            onClick={() => handleAbortDraft(stepInstance, index)}
                            sx={{ mt: 1, mr: 1, ml: 0 }}
                            disabled={getButtonRolesDisabled()}
                        >
                            Abort Process
                        </Button>
                    );
                    buttons.push(button2);
                }
                const button3 = (
                    <Button
                        variant="outlined"
                        onClick={() => handleViewProcessLogs(stepInstance, index)}
                        sx={{ mt: 1, mr: 1 }}
                        disabled={getButtonRolesDisabled()}
                    >
                        {action.name === 'RUN_EDITION_PAUSE' || action.name === 'OPEN_EDITION' ? 'View Process Log' : 'View Signing Log'}
                    </Button>
                );
                buttons.push(button3);
            } else if (action.properties?.actionState === 'FINISHED' || action.properties?.actionState === 'COMPLETED') {
                const button1 = (
                    <Button
                        variant="outlined"
                        sx={{ mt: 1, mr: 1 }}
                        disabled={false}
                        color="success"
                    >
                        Process Completed
                    </Button>
                );
                buttons.push(button1);
                const button3 = (
                    <Button
                        variant="outlined"
                        onClick={() => handleViewProcessLogs(stepInstance, index)}
                        sx={{ mt: 1, mr: 1 }}
                        disabled={getButtonRolesDisabled()}
                    >
                        {action.name === 'RUN_EDITION_PAUSE' || action.name === 'OPEN_EDITION' ? 'View Process Log' : 'View Signing Log'}
                    </Button>
                );
                buttons.push(button3);
            } else if (action.properties?.actionState === 'FAILED') {
                const button1 = (
                    <Button
                        variant="outlined"
                        sx={{ mt: 1, mr: 1 }}
                        disabled={false}
                        color="error"
                    >
                        Process Failed
                    </Button>
                );
                buttons.push(button1);
                const button3 = (
                    <Button
                        variant="outlined"
                        onClick={() => handleViewProcessLogs(stepInstance, index)}
                        sx={{ mt: 1, mr: 1 }}
                        disabled={getButtonRolesDisabled()}
                    >
                        {action.name === 'RUN_EDITION_PAUSE' || action.name === 'OPEN_EDITION' ? 'View Process Log' : 'View Signing Log'}
                    </Button>
                );
                buttons.push(button3);
            } else {
                if (action.name === 'RESUME_EDITION') {
                    const button = (
                        <Button
                            variant="outlined"
                            onClick={() => handleViewEditionOutput(stepInstance, index)}
                            sx={{ mt: 1, mr: 1 }}
                            disabled={getButtonRolesDisabled()}
                        >
                            View Edition
                        </Button>
                    );
                    buttons.push(button);
                    const button3 = (
                        <Button
                            variant="outlined"
                            onClick={() => handleStartDraftOrEdition(stepInstance, index, action)}
                            sx={{ mt: 1, mr: 1 }}
                            disabled={getButtonRolesDisabled()}
                        >
                            Sign
                        </Button>
                    );
                    buttons.push(button3);
                } else if (action.name === 'OPEN_EDITION') {
                    const button3 = (
                        <Button
                            variant="outlined"
                            onClick={() => handleStartDraftOrEdition(stepInstance, index, action)}
                            sx={{ mt: 1, mr: 1 }}
                            disabled={getButtonRolesDisabled()}
                        >
                            Process
                        </Button>
                    );
                    buttons.push(button3);

                } else { // RUN_EDITION
                    const button = (
                        <Button
                            variant="outlined"
                            onClick={() => handleEditionOptions(stepInstance, index)}
                            sx={{ mt: 1, mr: 1 }}
                            disabled={getButtonRolesDisabled()}
                        >
                            Set Options
                        </Button>
                    );
                    buttons.push(button);
                    const button2 = (
                        <Button
                            variant="outlined"
                            onClick={() => handleStartDraftOrEdition(stepInstance, index, action)}
                            sx={{ mt: 1, mr: 1 }}
                            disabled={getButtonRolesDisabled()}
                        >
                            Process
                        </Button>
                    );
                    buttons.push(button2);
                }
            }
        }
        */
        return buttons;
    };

    const handleApproveDCR = (sinst, index, action) => {
        handleStartAction(sinst, index, action);
    };

    const handleResubmitDCR = (sinst, index, action) => {
        handleStartAction(sinst, index, action);
    }

    const handleApproveConditionallyDCR = (sinst, index, action) => {
        handleStartAction(sinst, index, action, 'COND_APPROVE');
    };

    const handleApproveConditionallyCandDraft = (sinst, index, action) => {
        handleStartAction(sinst, index, action, 'COND_APPROVE');
    };

    const handleRejectDCR = (sinst, index, action) => {
        handleStartAction(sinst, index, action, 'REJECT');
    };

    const handleReviewDCR = (sinst, action, conditional=false, peer=false) => {
        netPost('/api/workflow/step/inst/info', sinst)
            .then(response => response.json())
            .then(info => {
                // console.log('Opening Review DCR files ' + JSON.stringify(info.file));
                if ( Array.isArray(info.documents) && info.documents.length > 0 ) {
                    setDcrDocuments(info.documents);
                    setConditionallyOnly(conditional);
                    setPeerReviewOnly(peer);
                    setDcrDocumentReviewOpen(true);
                } else if ( Array.isArray(info.file) && info.file.length >= 1 ) {
                    openDCRBinder(info.file, refPortfolio);   
                }
            }).catch( error => console.log('Error getting DCR info: ' + error));
    };

    

    const updateApproveBinder = (binderId, isApprove, binder) => {
        const winst = { ...workflowInstance};
        if ( workflowInstance.properties ) {
            winst['properties'] = { ...workflowInstance.properties};
        }
        if ( isApprove ) {
            winst.properties.approveBinderId = binderId;
            winst.properties.approveBinder = {
                id: binderId,
                name: binder.name,
                description: binder.description,
            };
        } else {
            winst.properties.binderId = binderId;
            winst.properties.binder = {
                id: binderId,
                name: binder.name,
                description: binder.description,
            };
        }
        console.log('Updating current binder: ' + binderId);
        netPost('/api/workflow/inst/update', winst)
            .then(response => {
                if (!response.ok) {
                    console.log('Could not complete action: error = ' + response.status);

                } else {

                }
            })
            .catch(error => console.log('Error updating DCR binderId: ' + error));
    };

    const updateApproveBinderAgain = (binderId, isApprove, binder) => {
        if ( isApprove ) {
            if ( binderId != globalApproveBinder?.id ) {
                console.log('Updating current binder again: ' + binderId);
                updateApproveBinder(binderId, isApprove, binder);
            }
        }
    };

    const handleReviewDCRSave = (binderId, isApprove, binder) => {
        setDcrBinderId(binderId)
        // console.log('setting binder: ' + binder);
        console.log('Current DCR: ' + binder?.name + ': ' + binder?.description);
        setCurrentDcr({...binder});
        updateApproveBinder(binderId, isApprove, binder);
        setTimeout(updateApproveBinderAgain, 6000, binderId, isApprove, binder);
    };

    const handleEditSupplement = (sinst, action) => {
        /*
        netPost('/api/workflow/step/inst/info', sinst)
            .then(response => response.json())
            .then(info => {
                // console.log('Opening Review DCR files ' + JSON.stringify(info.file));
                if ( Array.isArray(info.documents) && info.documents.length > 0 ) {
                    setDcrDocuments(info.documents);
                    setConditionallyOnly(conditional);
                    setPeerReviewOnly(peer);
                    setDcrDocumentReviewOpen(true);
                } else if ( Array.isArray(info.file) && info.file.length >= 1 ) {
                    openDCRBinder(info.file, refPortfolio);   
                }
            }).catch( error => console.log('Error getting DCR info: ' + error));
        */
        const winst = {...workflowInstance};
        let url = '/api/doc/supplements?doc=' + encodeURIComponent(winst.properties.document) + '&wid=' + winst.id ;
        if ( winst.properties.duId ) {
            url = url + '&duId=' + winst.properties.duId;
        }
        netGet(url)
            .then(response => response.json())
            .then( sups => {
                // console.log('Supplements: ' + JSON.stringify(sups));
                if ( Array.isArray(sups) ) {
                    if ( sups.length > 0 ) {
                        const supp = sups[0];
                        console.log('Choosen supplement: ' + JSON.stringify(supp));
                        let sname = supp.title;
                        if ( typeof supp.number === 'string' && supp.number.length > 0 ) {
                            sname = `${supp.number}: ${supp.title}`;
                        }
                        WorkflowModule.pageChange(<ReactXMLEditor path={supp.path} name={sname} />);
                    } 
                    /*
                    else {
                        setSupplements(sups);
                        setSuppDocOpen(true);
                    }
                    */
                }
            }).catch( error => console.log('Error getting supplements list: ' + error));
    };

    const handleEditSupplementSave = (supp) => {
        /*
        setDcrBinderId(binderId)
        const winst = {...workflowInstance};
        if ( isApprove ) {
            winst.properties.approveBinderId = binderId;
        } else {
            winst.properties.binderId = binderId;
        }
        netPost('/api/workflow/inst/update', winst)
            .then(response => {
                if (!response.ok) {
                    console.log('Could not complete action: error = ' + response.status);

                }
            })
            .catch(error => console.log('Error updating DCR binderId: ' + error));
        */
        console.log('Choosen supplement: ' + JSON.stringify(supp));
        WorkflowModule.pageChange(<ReactXMLEditor path={supp.path} name={`${supp.number}: ${supp.title}`} />);
    };

    const approveBinderDisabled=  () => {
        //console.log('Approve Binder Disabled = ' + approveBinder?.id + ' : ' + globalApproveBinder?.id );
        return getButtonRolesDisabled() || !(workflowInstance?.properties?.approveBinderId || globalApproveBinder?.id);
    };

    const approveDcrUserAction = (action, index) => {
        const buttons = [];
        if (action.name === 'APPROVE_DCR') {
            // loadCurrentBinder();
            if (action.properties?.actionState === 'FINISHED' || action.properties?.actionState === 'COMPLETED') {
                const rejected = action.properties?.actionResult === 'REJECTED';
                const condApproved = action.properties?.actionResult === 'COND_APPROVED';
                const button1 = (
                    <Button
                        variant="outlined"
                        sx={{ mt: 1, mr: 1 }}
                        disabled={false}
                        color={rejected ? 'error' : (condApproved ? 'warning' : 'success')}
                    >
                        {rejected ? 'DCR Rejected' : (condApproved ? 'DCR Conditionally Approved' : 'DCR Approved')}
                    </Button>
                );
                buttons.push(button1);

                const status = (
                    <Typography sx={{display: workflowInstance?.properties?.approveBinderId ? 'inline-block' : 'none',
                        position: 'relative', top: '0.5ex', paddingLeft: 1
                    }}>{workflowInstance?.properties?.approveBinder?.name ? 
                        `${workflowInstance.properties.approveBinder?.name}: ${workflowInstance.properties.approveBinder?.description}` : ''}</Typography>
                );
                buttons.push(status);

            } else {
                const button = (
                    <Button
                        variant="outlined"
                        onClick={() => handleReviewDCR(stepInstance, action)}
                        sx={{ mt: 1, mr: 1 }}
                        disabled={getButtonRolesDisabled()}
                    >
                        Review DCR
                    </Button>
                );
                buttons.push(button);
                const button2 = (
                    <Button
                        variant="outlined"
                        onClick={() => handleApproveDCR(stepInstance, index, action)}
                        sx={{ mt: 1, mr: 1 }}
                        disabled={approveBinderDisabled()}
                        color="success"
                    >
                        Approve
                    </Button>
                );
                buttons.push(button2);
                const button4 = (
                    <Button
                        variant="outlined"
                        onClick={() => handleApproveConditionallyDCR(stepInstance, index, action)}
                        sx={{ mt: 1, mr: 1 }}
                        disabled={approveBinderDisabled()}
                        color="warning"
                    >
                        Approve Conditionally
                    </Button>
                );
                buttons.push(button4);
                const button3 = (
                    <Button
                        variant="outlined"
                        onClick={() => handleRejectDCR(stepInstance, index, action)}
                        sx={{ mt: 1, mr: 1 }}
                        disabled={approveBinderDisabled()}
                        color="error"
                    >
                        Reject
                    </Button>
                );
                buttons.push(button3);
                const binder = workflowInstance?.properties?.approveBinder ? workflowInstance.properties.approveBinder : globalApproveBinder;
                const status = (
                    <Typography sx={{display: binder ? 'inline-block' : 'none', 
                        position: 'relative', top: '0.5ex', paddingLeft: 1}}>
                            {binder?.name ? 
                            `${binder?.name}: 
                            ${binder?.description}` : ''}</Typography>
                );
                buttons.push(status);
            }

        }
        return buttons;

    };

    const handleOpenDoc = (sinst) => {
        netPost('/api/workflow/step/inst/info', sinst)
            .then(response => response.json())
            .then(info => {
                console.log('Opening link ' + info.link);
                // window.open('https://test-2014.uniscope.cloud/ucs/#content/editor', 'UCS');
                /*
                if ( window.opener ) {
                    window.opener.location.href = info.link;
                    window.opener.focus();
                } else {
                    window.open(info.link, 'UCS');
                }
                */
                window.open(info.link, 'UCS');
            });
    };

    const editDocUserAction = (action,index) => {
        const buttons = [];
        if ( action.name === 'EDIT_DOC' ) {
            const button = (
                <Button
                    variant="outlined"
                    onClick={() => handleOpenDoc(stepInstance)}
                    sx={{ mt: 1, mr: 1 }}
                    disabled={getButtonDisabled()}
                >
                    Open Document
                </Button>
            );
            buttons.push(button);
        }
        return buttons;
    }

    const docUnitButtonDisabled = (action) => {

        if ( action?.properties?.source === 'CONTEXT' ) {
            const docUnitId = workflowInstance?.properties?.duId;
            return ( typeof docUnitId !== 'string' || docUnitId == '' );
        }

        return false;
    };

    const editDocUnitUserAction = (action,index) => {
        const buttons = [];
        if ( action.name === 'EDIT_DOC_UNIT' &&  action.properties?.target === 'SUPP') {
            const button = (
                <Button
                    variant="outlined"
                    onClick={() => handleEditSupplement(stepInstance, action)}
                    sx={{ mt: 1, mr: 1}}
                    disabled={editSupplementDisabled}
                >
                    Edit Supplement
                </Button>
            );
            buttons.push(button);
        }
        return buttons;
    };

    const reviewDcrUserAction = (action,index) => {
        const buttons = [];
        if ( action.name === 'REVIEW_DCR' || action.name === 'UPDATE_DCR' || action.name === 'PEER_REVIEW_DCR') {
            const button = (
                <Button
                    variant="outlined"
                    onClick={() => handleReviewDCR(stepInstance, action, action.name === 'UPDATE_DCR', action.name === 'PEER_REVIEW_DCR')}
                    sx={{ mt: 1, mr: 1}}
                    disabled={getButtonDisabled()}
                >
                    {action.name === 'UPDATE_DCR' ? 'Review & Update DCR' : 'Review DCR'}
                </Button>
            );
            buttons.push(button);
        }
        return buttons;
    };

    const resubmitDcrUserAction = (action,index) => {
        const buttons = [];
        if (  action.name === 'RESUBMIT_DCR') {
            if (action.properties?.actionState === 'FINISHED' || action.properties?.actionState === 'COMPLETED') {
                const button1 = (
                    <Button
                        variant="outlined"
                        sx={{ mt: 1, mr: 1 }}
                        disabled={false}
                        color="success"
                    >
                        DCR Resubmitted
                    </Button>
                );
                buttons.push(button1);

            } else {
                const button = (
                    <Button
                        variant="outlined"
                        onClick={() => handleResubmitDCR(stepInstance, index, action)}
                        sx={{ mt: 1, mr: 1 }}
                        disabled={getButtonDisabled()}
                    >
                        Resubmit DCR
                    </Button>
                );
                buttons.push(button);
            }
        }
        return buttons;
    };

    const handleExternalImport = (sinst, index, action) => {
        setUploadDlibOpen(true);
        setUploadDlibDescription('');
        setCurrentAction(action);
        setCurrentActionIndex(index);
        const upid = uuidv4();
        setUploadDlibShelf(upid);
    };

    const saveUploadDlib = (target, desc, shelfid) => {
        setUploadDlibOpen(false);
        console.log('Save upload target: ' + JSON.stringify(target));
        const actions = stepInstance.properties?.actions;
        if (actions) {
            let action = actions[currentActionIndex];
            if (action && Array.isArray(target) && target.length > 0) {
                action.properties.importFiles = target;
                netPost('/api/workflow/step/update', stepInstance)
                    .then(response => {
                        if (!response.ok) {
                            console.log('Could not update step form: error = ' + response.status);
                        } else {
                            handleStartAction(stepInstance, currentActionIndex, action, 'IMPORT');
                        }
                    });
                
            }
        }
    };

    const handleMasterChangeManagement = (sinst, index, action) => {
        netGet('/api/dlib/port?doc=masters&sts=MASTER&name=CAA')
        .then(response => response.json())
        .then(data => {
            if (Array.isArray(data.properties?.files) && data.properties?.document) {

                const urlBase = '/file/' + UserInfo.info.sessionId + '/opt/UCS/dlib/' + data.properties.document.path + '/';
                const files = data.properties.files.filter(f => f.properties?.CATEG === 'CHANGE_MGMT' && f.name.toLowerCase().endsWith('.docx')).map(file => {
                    return {
                        url: urlBase + file.path,
                        name: file.name,
                    };
                });
                console.log('MASTER FILES: ' + JSON.stringify(files));
                setDownloadFiles(files);
                setCurrentAction(action);
                setCurrentActionIndex(index);
                setDownloadDialogOpen(true);

            }
            // setWorkPortfolio(data);
        }).catch(error => {
            console.log('Error fetching dlib CAA master portfolio: ' + error);
        });
    }

    const handleReviewCAA = (sinst, action ) => {
        let document = workflowDef.attributes.document;
        if (!document) {
            document = workflowInstance.properties.document;
        }
        netGet('/api/dlib/port?doc=' + document + '&sts=OPEN&name=CAA')
            .then(response => response.json())
            .then(data => {
                if (Array.isArray(data.properties?.files) && data.properties?.document) {

                    const urlBase = '/opt/UCS/dlib/' + data.properties.document.path + '/';
                    const files = data.properties.files.filter(f => (f.properties?.CATEG === 'CAA_FORM' || f.properties?.CATEG === 'CHANGE_MGMT' ) && 
                        (f.properties?.STATE === 'FINAL' || f.properties?.STATE === 'LATEST') && f.name.toLowerCase().endsWith('.pdf')).map(file => {
                        return {
                            path: urlBase + file.path,
                            name: file.name,
                            formType: 'CAA',
                            formId: 'CAA',
                            title: 'Submission Package'
                        };
                    });
                    if (files.length > 0) {
                        console.log('MASTER FILES: ' + JSON.stringify(files));
                        openDCRBinder(files);
                    } 

                } 
            }).catch(error => {
                console.log('Change management file not available: ' + error);
            
            });
    };

    const handleExternalExport = (sinst, index, action) => {
        let document = workflowDef.attributes.document;
        if (!document) {
            document = workflowInstance.properties.document;
        }
        let part = workflowInstance.properties.part;
        // console.log('document = ' + document);
        // console.log('part = ' + JSON.stringify(part));
        console.log('EXT EXPORT ACTION: ' + JSON.stringify(action));
        // const url = `/api/doc/download/profile/${document}?pf=${action.properties?.target}`;
        setDownloadFiles([]);
        if ( action?.properties?.target === 'CHANGE_MGMT') {
            netGet('/api/dlib/port?doc=' + document + '&sts=OPEN&name=CAA')
                .then(response => response.json())
                .then(data => {
                    if (Array.isArray(data.properties?.files) && data.properties?.document) {

                        const urlBase = '/file/' + UserInfo.info.sessionId + '/opt/UCS/dlib/' + data.properties.document.path + '/';
                        const files = data.properties.files.filter(f => f.properties?.CATEG === 'CHANGE_MGMT' && 
                            (f.properties?.STATE === 'FINAL' || f.properties?.STATE === 'LATEST') && f.name.toLowerCase().endsWith('.docx')).map(file => {
                            return {
                                url: urlBase + file.path,
                                name: file.name,
                            };
                        });
                        if (files.length > 0) {
                            console.log('MASTER FILES: ' + JSON.stringify(files));
                            setDownloadFiles(files);
                            setCurrentAction(action);
                            setCurrentActionIndex(index);
                            setDownloadDialogOpen(true);
                        } else {
                            handleMasterChangeManagement(sinst, index, action);
                        }
        
                    } else {
                        handleMasterChangeManagement(sinst, index, action);
                    }                 
                }).catch( error => {
                    console.log('Change management file not available: ' + error);
                    handleMasterChangeManagement(sinst, index, action);
                });
            
        } else {
            netGet('/api/dlib/port?doc=' + document + '&sts=OPEN&name=WORK')
                .then(response => response.json())
                .then(data => {
                    // console.log('Work portfolio files: ' + JSON.stringify(data.properties?.files));
                    // console.log('Work portfolio doc: ' + JSON.stringify(data.properties?.document));
                    if (part?.number && data.id) {
                        netGet('/api/dlib/binder/list?did=' + data.docId + '&pid=' + data.id + '&name=' + encodeURIComponent(part.number) + '&type=PART&categ=WORK')
                            .then(response => response.json())
                            .then(binders => {
                                //  console.log('PART binders: ' + JSON.stringify(binders));
                                const partBinder = Array.isArray(binders) && binders.length > 0 ? binders[0] : null;
                                if (partBinder) {
                                    if (Array.isArray(data.properties?.files) && data.properties?.document) {

                                        const urlBase = '/file/' + UserInfo.info.sessionId + '/opt/UCS/dlib/' + data.properties.document.path + '/';
                                        const files = data.properties.files.filter(f => f.properties?.CATEG === 'CANDIDATE_DRAFT' && f.properties?.partBinderId === partBinder.id && f.name.toLowerCase().endsWith('.docx')).map(file => {
                                            return {
                                                url: urlBase + file.path,
                                                name: file.name,
                                            };
                                        });
                                        console.log('FILES: ' + JSON.stringify(files));
                                        setDownloadFiles(files);
                                        setCurrentAction(action);
                                        setCurrentActionIndex(index);
                                        setDownloadDialogOpen(true);
                                    }
                                }
                            }).catch(error => {
                                console.log('Error fetching dlib WORK portfolio: ' + error);
                            });
                    } else if (Array.isArray(data.properties?.files) && data.properties?.document) {

                        const urlBase = '/file/' + UserInfo.info.sessionId + '/opt/UCS/dlib/' + data.properties.document.path + '/';
                        const files = data.properties.files.filter(f => f.properties?.CATEG === 'CANDIDATE_DRAFT' && f.name.toLowerCase().endsWith('.docx')).map(file => {
                            return {
                                url: urlBase + file.path,
                                name: file.name,
                            };
                        });
                        console.log('FILES: ' + JSON.stringify(files));
                        setDownloadFiles(files);
                        setCurrentAction(action);
                        setCurrentActionIndex(index);
                        setDownloadDialogOpen(true);

                    }
                    setWorkPortfolio(data);
                }).catch(error => {
                    console.log('Error fetching dlib WORK portfolio: ' + error);
                });
        }
    };

    const handleExportDone = () => {
        setDownloadDialogOpen(false);
        // execute EDIT_EXTERNAL_DOC/EXPORT
        handleStartAction(stepInstance, currentActionIndex, currentAction, 'EXPORT');
    };

    const editExternalUserAction = (action,index) => {
        const buttons = [];
        if (  action.name === 'EDIT_EXTERNAL_DOC') {
            const state = action.properties?.actionState;
            const target = action.properties?.target;
            /*
            if (action.properties?.actionState === 'FINISHED' || action.properties?.actionState === 'COMPLETED') {
                const button1 = (
                    <Button
                        variant="outlined"
                        sx={{ mt: 1, mr: 1 }}
                        disabled={false}
                        color="success"
                    >
                        DCR Resubmitted
                    </Button>
                );
                buttons.push(button1);

            } else {
             */
            if ( target !== 'CAA_FORM') {
                const button = (
                    <Button
                        variant="outlined"
                        onClick={() => handleExternalExport(stepInstance, index, action)}
                        sx={{ mt: 1, mr: 1 }}
                        disabled={getButtonDisabled() || state === 'EXPORTED' || state === 'FINISHED'}
                    >
                        {target === 'CHANGE_MGMT' ? 'Export Change Management Form' : 'Export Document'}
                    </Button>
                );
                buttons.push(button);
            }
            let b2label = 'Import Document';
            if ( target === 'CHANGE_MGMT') {
                b2label = 'Import Change Management Form';
            } else if ( target === 'CAA_FORM' ) {
                b2label = 'Upload CAA Application for Approval/Revision Form';
            }
            const button2 = (
                <Button
                    variant="outlined"
                    onClick={() => handleExternalImport(stepInstance, index, action)}
                    sx={{ mt: 1, mr: 1 }}
                    disabled={getButtonDisabled() || !(state === 'EXPORTED' || state === 'IMPORT_FAILED' || target === 'CAA_FORM')}
                >
                    {b2label}
                </Button>
            );
            const message1 = (
                <Typography color="error" sx={{
                    fontSize: '85%', display: state === 'IMPORT_FAILED' ? 'inline' : 'none',
                    position: 'relative', top: '0.6ex'
                }}>Imcomplete import, please try again.</Typography>
            );
            const message2 = (
                <Typography color="success" sx={{
                    fontSize: '85%', display: state === 'FINISHED' ? 'inline' : 'none',
                    position: 'relative', top: '0.6ex'
                }}>Import successful.</Typography>
            );


            buttons.push(button2);
            buttons.push(message1);
            buttons.push(message2);
            // }
        }
        return buttons;
    };

    const handleApproveExternalDoc = (sinst, index, action) => {
        handleStartAction(sinst, index, action);
    };

    const handleApproveConditionallyExternalDoc = (sinst, index, action) => {
        handleStartAction(sinst, index, action, action.name === 'CHECK_EXTERNAL_DOC' ? 'RETURN' : 'COND_APPROVE');
    };

    const handleForwardExternalDoc = (sinst, index, action) => {
        handleStartAction(sinst, index, action, 'FORWARD');
    };

    const handleRejectExternalDoc = (sinst, index, action) => {
        handleStartAction(sinst, index, action, 'REJECT');
    };


    const approveExternalDocUserAction = (action, index) => {
        const buttons = [];
        if (action.name === 'APPROVE_EXTERNAL_DOC' || action.name === 'CHECK_EXTERNAL_DOC' ||
            action.name === 'APPROVE_EXTERNAL_DOC_OWNER' || action.name === 'APPROVE_EXTERNAL_DOC_CORP'
        ) {
            const isCheck = action.name === 'CHECK_EXTERNAL_DOC';
            const isCAA = action.properties?.target === 'CAA_PKG';
            if (action.properties?.actionState === 'FINISHED' || action.properties?.actionState === 'COMPLETED') {
                const condApproved = action.properties?.actionResult === 'COND_APPROVED' || action.properties?.actionResult === 'RETURNED';
                const rejected = action.properties?.actionResult === 'REJECTED';
                const forwarded = action.properties?.actionResult === 'FORWARDED';
                const button1 = (
                    <Button
                        variant="outlined"
                        sx={{ mt: 1, mr: 1 }}
                        disabled={false}
                        color={condApproved ? 'warning' : (rejected ? 'error' : 'success')}
                    >
                        {condApproved ? 'Approved conditionally' : (isCheck ? 'Checked' : (forwarded ? 'Forwarded to corporate approval' : (rejected ? 'Rejected' : 'Approved')))}
                    </Button>
                );
                buttons.push(button1);

            } else {
                const button = (
                    <Button
                        variant="outlined"
                        onClick={() => { if (isCAA){ handleReviewCAA(stepInstance, action);} else { handleCheckPdfDraft(stepInstance);} }}
                        sx={{ mt: 1, mr: 1 }}
                        disabled={getButtonRolesDisabled()}
                    >
                        {isCAA ? 'Review CAA Package' : 'Review Document'}
                    </Button>
                );
                buttons.push(button);
                const button2 = (
                    <Button
                        variant="outlined"
                        onClick={() => handleApproveExternalDoc(stepInstance, index, action)}
                        sx={{ mt: 1, mr: 1 }}
                        disabled={getButtonRolesDisabled()}
                        color="success"
                    >
                        {isCheck ? 'Checked' : 'Approve'}
                    </Button>
                );
                buttons.push(button2);
                const button4 = (
                    <Button
                        variant="outlined"
                        onClick={() => handleApproveConditionallyExternalDoc(stepInstance, index, action)}
                        sx={{ mt: 1, mr: 1 }}
                        disabled={getButtonRolesDisabled()}
                        color="warning"
                    >
                        {isCAA ? 'Return to package preparation' : 'Approve Conditionally'}
                    </Button>
                );
                buttons.push(button4);
                if ( action.name === 'APPROVE_EXTERNAL_DOC_OWNER') {
                    const button5 = (
                        <Button
                            variant="outlined"
                            onClick={() => handleForwardExternalDoc(stepInstance, index, action)}
                            sx={{ mt: 1, mr: 1 }}
                            disabled={getButtonRolesDisabled()}
                            color="info"
                        >
                            Forward to Corporate Approval
                        </Button>
                    );
                    buttons.push(button5);
                    const button6 = (
                        <Button
                            variant="outlined"
                            onClick={() => handleRejectExternalDoc(stepInstance, index, action)}
                            sx={{ mt: 1, mr: 1 }}
                            disabled={getButtonRolesDisabled()}
                            color="error"
                        >
                            Reject
                        </Button>
                    );
                    buttons.push(button6);
                }
            }
        }
        return buttons;

    };

    const handleCheckPdfDraft = (sinst) => {
        netPost('/api/workflow/step/inst/info', sinst)
            .then(response => response.json())
            .then(info => {
                const pfile = workflowInstance.properties?.pdfDraftFile;
                // console.log('Opening PDF file ' + pfile);
                if (Array.isArray(pfile)) {
                    // console.log('Opening PDF file array: ' + pfile.length);
                    if (pfile.length == 1) {

                        if (workflowInstance.properties.previousDrafts && workflowInstance.properties.previousDrafts.length > 0) {
                            const prevDraft = workflowInstance.properties.previousDrafts[workflowInstance.properties.previousDrafts.length - 1];
                            if (Array.isArray(prevDraft) && prevDraft.length > 0) {
                                const ppath = prevDraft[0].path;
                                // console.log('Found previous draft = ' + ppath);
                                const fpath = pfile[0].path;
                                if (fpath) {
                                    const flabel = pfile[0].label;
                                    openPdfDocument(fpath, flabel, workflowInstance.properties.previousDrafts, dcrPortfolio);
                                }
                            }
                        } else {

                            const fpath = pfile[0].path;
                            const flabel = pfile[0].label;
                            if (fpath) {
                                openPdfDocument(fpath, flabel, undefined, dcrPortfolio);
                            }
                        }
                    } else if (pfile.length > 1) {
                        setViewPdfs(pfile);
                        setPreviousDrafts(workflowInstance.properties.previousDrafts);
                        setViewPdfOpen(true);
                    }
                } else {
                    openPdfDocument(pfile, undefined, undefined, dcrPortfolio);
                }
            });
    };

    const handleCandidateDraft = (sinst) => {
        netPost('/api/workflow/step/inst/info', sinst)
            .then(response => response.json())
            .then(info => {
                // TODO: show only OEM and DCR files
                const pfile = workflowInstance.properties?.pdfDraftFile;
                // console.log('Opening PDF file ' + pfile);
                if (Array.isArray(pfile)) {
                    // console.log('Opening PDF file array: ' + pfile.length);
                    if (pfile.length == 1) {
                        const fpath = pfile[0].path;
                        const flabel = pfile[0].label;
                        const isCand = typeof pfile[0].base !== 'undefined' ? pfile[0].base : true;
                        if (fpath) {
                            openPdfDocument(fpath, flabel, undefined, dcrPortfolio, undefined, isCand);
                        }
                    } else if (pfile.length > 1) {
                        setViewPdfs(pfile);
                        setPreviousDrafts([]);
                        setViewPdfOpen(true);
                    }
                } else {
                    openPdfDocument(pfile, undefined, undefined, dcrPortfolio, undefined, true);
                }
            });
    };


    const handleEditOemCopy = (sinst) => {
        // TODO: show only OEM and DCR files
        const pfile = workflowInstance.properties?.oemCopyFile;
        // console.log('Opening PDF file ' + pfile);
        if (Array.isArray(pfile)) {
            // console.log('Opening PDF file array: ' + pfile.length);
            if (pfile.length == 1) {
                const fpath = pfile[0].path;
                const flabel = pfile[0].label;
                const isOem = typeof pfile[0].oem !== 'undefined' ? pfile[0].base : true;
                if (fpath) {
                    openPdfDocument(fpath, flabel, undefined, dcrPortfolio, undefined, false);
                }
            } else if (pfile.length > 1) {
                setViewPdfs(pfile);
                setPreviousDrafts([]);
                setViewPdfOpen(true);
            }
        } else {
            openPdfDocument(pfile, undefined, undefined, dcrPortfolio, undefined, false);
        }
           
    };

    
    const handleCheckOemCopy = (sinst) => {
        handleEditOemCopy(sinst);
    };

    const approveCandDraftExecuteButtons = (action, index) => {
        const buttons = [];
        const candDraft = action.name === 'APPROVE_CAN_DRAFT' ? 'Candidate ' : '';
        const button = (
            <Button
                variant="outlined"
                onClick={() => handleCheckPdfDraft(stepInstance)}
                sx={{ mt: 1, mr: 1 }}
                disabled={getButtonRolesDisabled()}
            >
                {`View ${candDraft}Draft`}
            </Button>
        );
        buttons.push(button);
        const button2 = (
            <Button
                variant="outlined"
                onClick={() => handleStartAction(stepInstance, index, action)}
                sx={{ mt: 1, mr: 1 }}
                disabled={getButtonRolesDisabled()}
                color="success"
            >
                Approve
            </Button>
        );
        buttons.push(button2);
        const button4 = (
            <Button
                variant="outlined"
                onClick={() => handleApproveConditionallyCandDraft(stepInstance, index, action)}
                sx={{ mt: 1, mr: 1 }}
                disabled={getButtonRolesDisabled()}
                color="warning"
            >
                Approve Conditionally
            </Button>
        );
        buttons.push(button4);

        return buttons;
    }

    const approveCandDraftUserAction = (action,index) => {
        const buttons = [];
        if (action.name === 'APPROVE_CAND_DRAFT' || action.name === 'APPROVE_DRAFT' || action.name === 'PRE_APPROVE_DRAFT') {
            const candDraft = action.name === 'APPROVE_CAN_DRAFT' ? 'Candidate ' : '';
            const isMultiLock = stepInstance?.properties?.multiLock;
            if ( isMultiLock ) {
                const username = UserInfo.info.name;
                const results = action.properties?.actionResults;
                let notExecd = false;
                if ( results ) {
                    const myResult = results[username];
                    if ( myResult === 'APPROVE' || myResult === 'COND_APPROVE' ) {
                        notExecd = false;
                        const condApproved = myResult === 'COND_APPROVE';
                        const button1 = (
                            <Button
                                variant="outlined"
                                sx={{ mt: 1, mr: 1 }}
                                disabled={false}
                                color={condApproved ? 'warning' : 'success'}
                            >
                                {condApproved ? `${candDraft}Draft Conditionally Approved` : `${candDraft}Draft Approved`}
                            </Button>
                        );
                        buttons.push(button1);
                    } else {
                        notExecd = true;
                    }
                } else {
                    notExecd = true;
                }
                if ( notExecd ) {
                    const sbuttons = approveCandDraftExecuteButtons(action,index);
                    if (Array.isArray(sbuttons) ) {
                        sbuttons.forEach( b => buttons.push(b));
                    }
                }
            } else {
                if (action.properties?.actionState === 'FINISHED' || action.properties?.actionState === 'COMPLETED') {
                    const condApproved = action.properties?.actionResult === 'COND_APPROVED';

                    const button1 = (
                        <Button
                            variant="outlined"
                            sx={{ mt: 1, mr: 1 }}
                            disabled={false}
                            color={condApproved ? 'warning' : 'success'}
                        >
                            {condApproved ? `${candDraft}Draft Conditionally Approved` : `${candDraft}Draft Approved`}
                        </Button>
                    );
                    buttons.push(button1);

                } else {
                    const sbuttons = approveCandDraftExecuteButtons(action,index);
                    if (Array.isArray(sbuttons) ) {
                        sbuttons.forEach( b => buttons.push(b));
                    }
                }
            }
        }
        return buttons;
    };

    const approveDraftOemUserAction = (action,index) => {
        const buttons = [];
        if (action.name === 'APPROVE_OEM_DCR') {
            if (action.properties?.actionState === 'FINISHED' || action.properties?.actionState === 'COMPLETED') {
                const button1 = (
                    <Button
                        variant="outlined"
                        sx={{ mt: 1, mr: 1 }}
                        disabled={false}
                        color="success"
                    >
                        {action.name === 'APPROVE_OEM_DCR' ? 'OEM DCR Approved' : 'Draft Approved'}
                    </Button>
                );
                buttons.push(button1);

            } else {
                const button = (
                    <Button
                        variant="outlined"
                        onClick={() => action.name === 'APPROVE_OEM_DCR' ? handleCheckOemCopy(stepInstance) : handleCheckPdfDraft(stepInstance)}
                        sx={{ mt: 1, mr: 1 }}
                        disabled={getButtonRolesDisabled()}
                    >
                        {action.name === 'APPROVE_OEM_DCR' ? 'View OEM DCR' : 'View Draft'}
                    </Button>
                );
                buttons.push(button);
                const button2 = (
                    <Button
                        variant="outlined"
                        onClick={() => handleStartAction(stepInstance, index, action)}
                        sx={{ mt: 1, mr: 1 }}
                        disabled={getButtonRolesDisabled()}
                    >
                        Approve
                    </Button>
                );
                buttons.push(button2);
            }
        }
        return buttons;
    };

    const reviewCandidateDraftAction = (action,index) => {

        const buttons = [];
        if ( action.name === 'EDIT_CAND_DRAFT' || action.name === 'REVIEW_CAND_DRAFT' || action.name === 'ANNOT_CAND_DRAFT') {
            const button = (
                <Button
                    variant="outlined"
                    onClick={() => handleCandidateDraft(stepInstance)}
                    sx={{ mt: 1, mr: 1}}
                    disabled={getButtonDisabled() || !candidateDraftEnable}
                >
                    {action.name === 'REVIEW_CAND_DRAFT' ? REVIEW_CANDIDATE_DRAFT_LABEL: ANNOTATE_CANDIDATE_DRAFT_LABEL}
                </Button>
            );
            buttons.push(button);
        }
        return buttons;
    };


    /* Array of generic workflow actions using the Action definition object */
    const createWorkflowActionButtons = () => {
        const buttons = [];
        if (stepInstance && (UserInfo.info.name === stepInstance.user || (Array.isArray(stepInstance.properties.stepUsers) && stepInstance.properties.stepUsers.includes(UserInfo.info.name)))) {
            if (stepInstance.properties?.actions && Array.isArray(stepInstance.properties.actions)) {
                // console.log('Get workflow actions: ' + JSON.stringify(stepInstance.properties.actions));
                const actions = stepInstance.properties.actions;
                actions.forEach((action, index) => {
                    if ( action.properties?.exec === 'ACTION' ) {
                        // ignore probably SEND_EMAIL 
                    } else if (action.properties?.exec === 'EXIT') {
                        // no buttons, actions executed silently on step movement
                        // maybe some status info on some actions later
                        if ( typeof exitActionMap[action.name] === 'function' ) {
                            const xas = exitActionMap[action.name](action,index,actions);
                            if ( Array.isArray(xas) ) {
                                xas.forEach( b => buttons.push(b));
                            }
                        }
                    } else if (action.properties?.exec === 'ENTER' || action.properties?.exec === 'CLAIM') {
                        if ( typeof enterActionMap[action.name] === 'function' ) {
                            const eas = enterActionMap[action.name](action,index,actions);
                            if ( Array.isArray(eas) ) {
                                eas.forEach( b => buttons.push(b));
                            }
                        }
                    } else {
                        if ( typeof userActionMap[action.name] === 'function' ) {
                            // console.log('Found USER action: ' + action.name);
                            const uas = userActionMap[action.name](action,index,actions);
                            if ( Array.isArray(uas) ) {
                                uas.forEach( b => buttons.push(b));
                            }
                        }
                    }
                    
                    /*



                        */
                });

            }
        }
        return buttons;
    };

    return (
        <React.Fragment>
            {createWorkflowActionButtons()}
            <ProcessLogViewer open={viewProcessLogsOpen} onClose={() => setViewProcessLogsOpen(false)} pid={processInstId} />
            <ChooseReviewDCRBindersDialog open={dcrDocumentReviewOpen} onCancel={() => setDcrDocumentReviewOpen(false)} documents={dcrDocuments} onSave={handleReviewDCRSave} 
                conditional={conditionallyOnly} peer={peerReviewOnly} refPortfolio={refPortfolio} />
            <ChooseViewPdfDialog open={viewPdfOpen} onCancel={() => setViewPdfOpen(false)} files={viewPdfs} 
                previousDrafts={previousDrafts} dcrPortfolio={dcrPortfolio} oemPortfolio={oemPortfolio} />
            <DownloadFilesDialog files={downloadFiles} open={downloadDialogOpen} onCancel={() => setDownloadDialogOpen(false)} title="Export Document"
                onDone={handleExportDone} />
            <SimpleUploadFileDialog open={uploadDlibOpen} onCancel={() => setUploadDlibOpen(false)} onSave={saveUploadDlib} workflowInstance={workflowInstance} stepInstance={stepInstance} 
                description={uploadDlibDescription} shelf={uploadDlibShelf} okLabel="Import"
                acceptFiles={ {'application/pdf': ['.pdf'], 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'], } }
                acceptLabel="Drag 'n' drop MS Word and PDF files here, or click to select files"
                 />
            <ChooseSupplementsDialog open={suppDocOpen} supplements={supplements} onCancel={() => setSuppDocOpen(false)}
                onSave={handleEditSupplementSave} />
            <ProcessOptionsDialog title={editionOptionsFlag ? 'Run Edition Options' : 'Run Draft Options'}
                open={draftOptionsOpen} onCancel={cancelDraftOptions} onSave={saveDraftOptions} options={draftOptions} isEdition={editionOptionsFlag} />
            <AbortConfirmDialog open={abortDialogOpen} onCancel={() => setAbortDialogOpen(false)} onAbort={abortConfirmSave} index={actionIndex} />
            <ViewDraftOutputDialog open={viewDraftOutputOpen} onCancel={() => setViewDraftOutputOpen(false)} onSave={viewDraftOutputSave}
                workflowInstance={workflowInstance} stepInstance={stepInstance} isEdition={false}/>
        </React.Fragment>
    );

}

export { WorkflowActionButtons, CanActionClaim, WorkflowActionStatus };