import React, { useRef, useEffect } from 'react';
import { createRoot } from 'react-dom/client';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckIcon from '@mui/icons-material/Check';
import WebViewer from '@pdftron/webviewer';
import { uxLicense } from './webviewer_license';
import { MessageHooks } from "../App";
import { UserInfo, netGet, netFetch, keycloak, getAuthHeaders } from "./network";
import postal from 'postal';
import './webviewer.css';

// last component is Uniscope's version
// change also the setupProxy.js to match version
const viewerVersion = '10.5.1.2';
const isWebComponent = false;

const DLIB_DIR = '/opt/UCS/dlib/';

var pdftronInstance;
var initializing = false;
var firstInstance = true;

var currentDocument = 'DUMMY';
var currentDocumentLabel = '';
var currentVersion;
var currentVersionNumber;
var currentPreviousVersions = [];
var currentBinderFiles = [];
var currentBinderLeftPath = '';
var currentBinderLeftReadOnly = true;
var dontClose = false;
var currentBinderRightPath = '';
var currentPortfolio;
var currentOemPortfolio;
var currentRefPortfolio;
var multiviewerEnabled = false;
var changingVersions = false;

var scheduledDocument = [];
var scheduledVersion = [];

var menuParent;
var versionsMenu = document.createElement('div');
var versionsMenuBtn = document.createElement('button');
var isMenuOpen = false;
var isCurrentDocumentReadOnly = false;
var isRightDocumentReadOnly = true;

let readOnlyWarningPara = document.createElement('p');
readOnlyWarningPara.innerHTML = 'This is a read-only document. You cannot add any annotations.';
readOnlyWarningPara.style = 'text-align: center';

const readOnlyWarningModalOptions = {
    dataElement: 'ucsReadOnlyWarningModal',
    header: {
        title: 'Warning',
        className: 'ucsReadOnlyWarningModal-header comments-counter',
        style: { 'padding-top': '0px', 'box-shadow': 'none' }
    },
    body: {
        className: 'ucsReadOnlyWarningModal-body note-text-preview preview-comment',
        style: { 'padding-top': '15px', 'box-shadow': 'none', 'font-size': '110%' },
        children: [readOnlyWarningPara],
    },
    footer: {
        className: 'ucsReadOnlyWarningModal-footer footer',
        style: { 'box-shadow': 'none' },
        children: [
            {
                title: 'Dismiss',
                button: true,
                style: {},
                className: 'modal-button confirm ok-btn',
                onClick: (e) => {
                    if (pdftronInstance) {
                        pdftronInstance.UI.closeElements(['ucsReadOnlyWarningModal']);
                    }
                }
            },
        ]
    }
};





const closeCustomMenu = () => {
    if (isMenuOpen && menuParent) {
        // menuParent.removeChild(versionsMenu);
        isMenuOpen = !isMenuOpen;
    }
};

const closeVersionsModal = () => {
    if (pdftronInstance) {
        pdftronInstance.UI.closeElements(['ucsRevisionsDialogModal']);
    }
}

const initWebViewer = () => {
    if (initializing) {
        return;
    }
    if (pdftronInstance) {
        return;
    }
    console.log('Initializing webviewer...');
    initializing = true;
    // sessionId most probably won't be ready at this time
    // console.log('My sessionId ' + UserInfo.info.sessionId);
    let container = document.getElementById('pdftron-viewer-container');
    container.innerHTML = '';
    let viewer = document.createElement('div');
    viewer.id = 'pdftron-viewer';
    viewer.classList.add('webviewer');
    container.appendChild(viewer);

    const webviewerPath = "/webviewer-" + viewerVersion;
    console.log('Opening webviewer at ' + webviewerPath);

    // and don't load any initial document 
    WebViewer(
        {
            licenseKey: uxLicense,
            path: webviewerPath,
            enableAnnotations: true,
            // fullAPI: true,
            disabledElements: ['annotationNoteConnectorLine'],
            enableAnnotationNumbering: true,
        },
        // document.getElementById('pdftron-viewer'),
        viewer,
    ).then((instance) => {

        console.log('On Webviewer...');
        // instance set here, it gets exported
        // should we use React's shared state instead
        pdftronInstance = instance;

        // instance.UI.enableNativeScrolling();
        //instance.UI.toggleElementVisibility('standardStampPanelButton');
        instance.UI.closeElements(['standardStampPanelButton', 'standardStampPanel']);
        instance.UI.openElements(['customStampPanelButton', 'customStampPanel']);
        instance.UI.disableElements(['toolbarGroup-Shapes', 'toolbarGroup-Edit', 'toolbarGroup-Insert', 
            'deleteCustomStampButton', 'standardStampPanel', 'standardStampPanelButton', 'rubberStampTabHeader', 'createCustomStampButton']);
        // , 'standardStampPanel', 'standardStampPanelButton', 'rubberStampTabHeader', 'createCustomStampButton'
        // instance.UI.disableElements(['downloadButton', 'printButton', 'saveAsButton']);
        // instance.UI.openElements(['customStampPanel']);
        // instance.UI.setSelectedTab('customStampPanelButton', 'rubberStampTab');
        

        // virtualizedLists seem to be buggy, disable them for now
        instance.UI.disableFeatures([instance.UI.Feature.NotesPanelVirtualizedList]);

        const { documentViewer, annotationManager, Annotations, Tools } = instance.Core;

        const stampTool = documentViewer.getTool('AnnotationCreateRubberStamp');

        if (firstInstance) {

            instance.UI.addCustomModal(readOnlyWarningModalOptions);
            
            let divRevisionsDialog = document.createElement('div');
            divRevisionsDialog.id = 'pdfviewer-revisions-dialog';
            // divRevisionsDialog.style = 'height: 28px; margin-top: 10px; margin-right: 20px';
            divRevisionsDialog.appendChild(versionsMenu);
            

            const revisionsDialogModalOptions = {
                dataElement: 'ucsRevisionsDialogModal',
                header: {
                    title: 'Choose Previous Draft or DCR',
                    className: 'ucsRevisionsDialogModal-header comments-counter',
                    style: { 'padding-top': '0px', 'box-shadow': 'none' }
                },
                body: {
                    className: 'ucsRevisionsDialogModal-body note-text-preview preview-comment',
                    style: { 'padding-top': '15px', 'box-shadow': 'none', 'font-size': '110%' },
                    children: [divRevisionsDialog],
                },
                footer: {
                    className: 'ucsRevisionsDialogModal-footer footer',
                    style: { 'box-shadow': 'none' },
                    children: [
                        {
                            title: 'Dismiss',
                            button: true,
                            style: {},
                            className: 'modal-button confirm ok-btn',
                            onClick: (e) => {
                                if (pdftronInstance) {
                                    pdftronInstance.UI.closeElements(['ucsRevisionsDialogModal']);
                                }
                            }
                        },
                    ]
                }
            };
            instance.UI.addCustomModal(revisionsDialogModalOptions);
            // const revisionsDialogRoot = createRoot(divRevisionsDialog);
            

            instance.UI.setHeaderItems(header => {
                header.push({
                    type: 'divider',
                    hidden: ['mobile'],
                    dataElement: 'previous-versions-menu-divider'
                });
                // menuParent = documentViewer.getScrollViewElement().parentElement;

                /*
                versionsMenu.classList.add('Overlay');
                versionsMenu.classList.add('FlyoutMenu');
                versionsMenu.style.padding = '0.8em';
                */

                /*
                const viewerEle = document.getElementById('pdftron-viewer');
                // first child is the viewer's iframe
                const iframe = viewerEle.firstElementChild;
                */
                /*
                // use the iframe's body since it's stable, we have to position it absolutely anyway
                menuParent = isWebComponent ? documentViewer.getScrollViewElement().parentElement : instance.UI.iframeWindow.document.body;
                // add a listener for outside clicks to dismiss menu
                menuParent.addEventListener('click', e => {
                    // console.log('Window click : ' + JSON.stringify(e.target));
                    if (isMenuOpen) {
                        if (!versionsMenu.contains(e.target) && !versionsMenuBtn.contains(e.target)) {
                            closeCustomMenu();
                        }
                    }
                });
                */
                const renderCustomMenu = () => {

                    versionsMenuBtn.classList.add('Button');
                    versionsMenuBtn.classList.add('ActionButton');
                    versionsMenuBtn.setAttribute('type', 'button');
                    versionsMenuBtn.setAttribute('aria-label', 'Drafts and DCRs');
                    const iconDiv = document.createElement('div');
                    iconDiv.classList.add('Icon');
                    iconDiv.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M10 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h5v2h2V1h-2v2zm0 15H5l5-6v6zm9-15h-5v2h5v13l-5-6v9h5c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/></svg>';
                    versionsMenuBtn.appendChild(iconDiv);

                    versionsMenuBtn.onclick = () => {
                        // console.log('Version Button Click...');
                        
                        pdftronInstance.UI.openElements(['ucsRevisionsDialogModal']);
                        // revisionsDialogRoot.render(<RevisionsDialog/>);

                        /* Testing
                        if (Array.isArray(currentPreviousVersions) && currentPreviousVersions.length > 0) {
                            console.log('isMenuOpen = ' + isMenuOpen + ', menu = ' + versionsMenu);
                            if (isMenuOpen) {
                                menuParent.removeChild(versionsMenu);
                            } else {
                                if (isWebComponent) {
                                    // let xpos = `${menuParent.clientWidth - (versionsMenuBtn.clientWidth + 84)}px`;
                                    let xpos = `${viewer.clientWidth - (versionsMenuBtn.clientWidth + 84)}px`;
                                    console.log('Parent width = ' + viewer.clientWidth + ', menu width = ' + versionsMenuBtn.clientWidth);
                                    console.log('Opening menu at left = ' + xpos);
                                    versionsMenu.style.left = xpos;
                                    versionsMenu.style.right = 'auto';
                                    // versionsMenu.style.top = '40px';
                                    versionsMenu.style.top = '40px';
                                    // versionsMenu.style.zIndex = '100';
                                    menuParent.appendChild(versionsMenu);

                                } else {
                                    let xpos = `${menuParent.clientWidth - (versionsMenuBtn.clientWidth + 84)}px`;
                                    //console.log('Parent width = ' + viewer.clientWidth + ', menu width = ' + versionsMenuBtn.clientWidth);
                                    //console.log('Opening menu at left = ' + xpos);
                                    versionsMenu.style.left = xpos;
                                    versionsMenu.style.right = 'auto';
                                    versionsMenu.style.top = '40px';
                                    menuParent.appendChild(versionsMenu);
                                }
                            }
                            isMenuOpen = !isMenuOpen;
                        } else {
                            if (isMenuOpen) {
                                menuParent.removeChild(versionsMenu);
                                isMenuOpen = !isMenuOpen;
                            }
                            // else do nothing, we don't have versions
                        }
                        */
                    };
                    return versionsMenuBtn;
                };

                const newCustomElement = {
                    type: 'customElement',
                    render: renderCustomMenu,
                    title: 'Drafts and DCRs',
                    dataElement: 'previous-versions-menu',
                };

                header.push(newCustomElement);

                header.push({
                    type: 'divider',
                    hidden: ['mobile']
                });
                header.push({
                    type: 'actionButton',
                    img: '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/></svg>',
                    title: 'Close PDF Viewer',
                    onClick: () => {
                        console.log('Closing PDF viewer...');
                        dontClose = false;
                        closePdfViewer();
                    }
                });
            });
            stampTool.setStandardStamps([
                'Approved',
                'Completed',
                'AsIs',
                'Draft',
                'Final',
              ]);
            const customStamps = [
                { title: "APPROVED", subtitle: "[By $currentUser at] HH:mm:ss, DD/MM/YYYY", 
                    color: new Annotations.Color('#33cc33'), bold: true, textColor: new Annotations.Color('#000000'), italic: true},
                { title: "REVIEWED", subtitle: "[By $currentUser at] HH:mm:ss, DD/MM/YYYY", color: new Annotations.Color('#3399ff'), bold: true, textColor: new Annotations.Color('#000000'), italic: true },
                { title: "CONDITIONALLY APPROVED", subtitle: "[By $currentUser at] HH:mm:ss, DD/MM/YYYY", color: new Annotations.Color('#ace600'), bold: true, textColor: new Annotations.Color('#000000'), italic: true},
                { title: "REJECTED", subtitle: "[By $currentUser at] HH:mm:ss, DD/MM/YYYY", color: new Annotations.Color('#ff4d4d'), bold: true, textColor: new Annotations.Color('#000000'), italic: true},
            ];
            stampTool.setCustomStamps(customStamps);
        }
        firstInstance = false;
        /*
        const highlightMouseUp = Tools.TextHighlightCreateTool.prototype.mouseLeftUp;
        const highlightMouseDown = Tools.TextHighlightCreateTool.prototype.mouseLeftDown;

        Tools.TextHighlightCreateTool.prototype.mouseLeftDown = function () {
            
            highlightMouseDown.apply(this, arguments);
        };

        Tools.TextHighlightCreateTool.prototype.mouseLeftUp = function () {
            if (this.annotation) {
                if (Tools.TextHighlightCreateTool.getDocumentViewers()[0] === documentViewer) {
                    this.annotation.StrokeColor = new Annotations.Color(0, 255, 255);
                    annotationManager.redrawAnnotation(this.annotation);
                    console.log('Changing highlight color in main docViewer...');
                } else {
                    console.log('NOT in main docViewer');
                }
            }
            highlightMouseUp.apply(this, arguments);
        };
        */

        instance.UI.addEventListener(instance.UI.Events.MULTI_VIEWER_READY, () => {
            // console.log('Got multiviewer ready event...');
            instance.Core.getDocumentViewers()[1].addEventListener('documentLoaded', () => {
                instance.UI.closeElements(['notesPanel']);
                instance.UI.openElements(['leftPanel', 'outlinesPanel']);
                instance.UI.disableElements(['toggleNotesButton']);
                // instance.UI.setNotesPanelSortStrategy(instance.UI.NotesPanelSortStrategy.CREATED_DATE, true);

                instance.UI.setCustomPanel({
                    uniscope: {
                        type: 'notesInLeftPanel',
                        notesInLeftPanel: true,
                    }
                });

                const docViewer1 = instance.Core.getDocumentViewers()[1];
                if ( isRightDocumentReadOnly ) {
                    instance.Core.getDocumentViewers()[1].enableReadOnlyMode();
                    registerSuppressTools(docViewer1);
                } else {
                    instance.Core.getDocumentViewers()[1].disableReadOnlyMode();
                }
                // instance.Core.getDocumentViewers()[1].disableAnnotations();
                changingVersions = false;

                
                /*
                const tool = docViewer1.getTool('AnnotationCreateSticky');
                tool.addEventListener('annotationAdded', (annotation) => {
                    console.log('Adding NEW annotation and removing: ' + JSON.stringify(annotation));
                    docViewer1.getAnnotationManager().deleteAnnotation(annotation, { force: true});
                });
                const tool4 = docViewer1.getTool('AnnotationCreateTextHighlight4');
                tool4.addEventListener('annotationAdded', (annotation) => {
                    console.log('Adding NEW TextHightlight4 annotation and removing: ' + JSON.stringify(annotation));
                    docViewer1.getAnnotationManager().deleteAnnotation(annotation, { force: true});
                });
                */

                /*
                instance.Core.getDocumentViewers()[1].addEventListener('mouseLeftDown', evt => {
                    
                   console.log('onMouseLeftDown');
                    evt.preventDefault();
                  });
                */

                setTimeout(() => instance.UI.openElements(['notesPanel', 'multiRightPanel', 'multiNotesPanel']), 100);
                // setTimeout(() => instance.UI.openElements(['notesPanel']), 200);
                setTimeout(() => instance.UI.setActiveLeftPanel('notesPanel'), 200);

                let auth = getAuthHeaders();
                netFetch('/api/webviewer/xfdf/' + UserInfo.info.sessionId + '?doc=' + currentVersion, {
                    method: 'GET',
                    headers: auth,
                }).then(response => {
                    response.text().then(xfdfString => {
                        // <xfdf><annots><text subject="Comment" page="0" color="#FFE6A2" ... /></annots></xfdf>
                        instance.Core.getDocumentViewers()[1].getAnnotationManager().importAnnotations(xfdfString);
                        // instance.Core.getDocumentViewers()[1].getAnnotationManager().enableReadOnlyMode();
                    });
                    instance.UI.openElements(['notesPanelButton']);
                });
            });
            instance.Core.getDocumentViewers()[1].addEventListener('documentUnloaded', () => {
                // console.log('changingVersions = ' + changingVersions);
                if (!changingVersions) {
                    instance.UI.disableFeatures([instance.UI.Feature.MultiViewerMode]);
                    multiviewerEnabled = false;
                    instance.UI.setCustomPanel({
                        uniscope: {
                            type: 'notesInLeftPanel',
                            notesInLeftPanel: false,
                        }
                    });
                    instance.UI.enableElements(['toggleNotesButton']);
                    instance.UI.openElements(['leftPanel', 'outlinesPanel', 'notesPanel']);
                    instance.UI.closeElements(['multiRightPanel', 'multiNotesPanel']);
                }
                changingVersions = false;
            });
            instance.Core.getDocumentViewers()[0].getAnnotationManager().disableReadOnlyMode();

            if (scheduledVersion.length > 0) {
                // console.log('Got schedule versions');
                const version = scheduledVersion.pop();
                if (version) {
                    if ( version.type === 'normal') {
                        loadRightPdfDocument(version.path, version.number);
                    } else {
                        loadPdfDocumentVersion(version.path, version.number);
                    }
                }
            }
        });


        documentViewer.addEventListener('documentLoaded', () => {

            instance.UI.setNotesPanelSortStrategy(instance.UI.NotesPanelSortStrategy.POSITION, false);
            if (isCurrentDocumentReadOnly) {
                documentViewer.enableReadOnlyMode();
            }
            instance.UI.enableElements(['toggleNotesButton']);
            // const notesPanel = isCurrentDocumentReadOnly ? 'multiNotesPanel' : 'notesPanel';
            instance.UI.openElements(['leftPanel', 'outlinesPanel', 'rightPanel', 'notesPanel', 'ribbons']);
            if (isCurrentDocumentReadOnly) {
                setTimeout(() => instance.UI.openElements(['notesPanel', 'multiNotesPanel']), 200);
            } else {
                instance.UI.enableElements(['ribbons', 'toolsHeader']);
                instance.UI.openElements(['toolsHeader']);
                instance.UI.setToolbarGroup('toolbarGroup-Annotate');
                // setTimeout(() => instance.UI.closeElements(["toolbarGroup-View"]), 100);
                // instance.UI.openElements(['ribbons', 'toolbarGroup-Annotate', 'toolsHeader']);
                setTimeout(() => instance.UI.openElements([pdftronInstance.UI.ToolbarGroup.ANNOTATE]), 400);
                setTimeout(() => instance.UI.openElements(['toolsHeader']), 300);
            }
            // console.log("Open ReadOnly Mode:" + isCurrentDocumentReadOnly);

            // load the annotation data
            // document load was triggered by user so UserInfo should be set by now
            let auth = getAuthHeaders();
            netFetch('/api/webviewer/xfdf/' + UserInfo.info.sessionId + '?doc=' + currentDocument, {
                method: 'GET',
                headers: auth,
            })
                .then(response => {

                    response.text().then(xfdfString => {
                        // <xfdf><annots><text subject="Comment" page="0" color="#FFE6A2" ... /></annots></xfdf>
                        annotationManager.importAnnotations(xfdfString);
                    });

                });
        });

        documentViewer.addEventListener('documentUnloaded', () => {
            instance.UI.disableFeatures([instance.UI.Feature.MultiViewerMode]);
            multiviewerEnabled = false;
            changingVersions = false;
            
            closePdfViewer();
            /*
            if ( dontClose && currentBinderRightPath !== '') {
                const right = currentBinderFiles.find(item => item.path === currentBinderRightPath);
                if (right) {
                    currentBinderLeftPath = currentBinderRightPath;
                    currentBinderRightPath = '';
                    isCurrentDocumentReadOnly = true;
                    loadLeftPdfDocument(currentBinderLeftPath, right.name, true);
                }
            }
            */
        });

        annotationManager.addEventListener('annotationChanged', function (a, b, e) {
            // If the event is triggered by importing then it can be ignored
            // This will happen when importing the initial annotations from the server or individual changes from other users
            if (e.info && e.info.imported) return;
            if (e.imported) return;

            annotationManager.exportAnnotationCommand().then(xfdfString => {
                // <xfdf>
                //    <add>
                //      <text subject="Comment" page="0" color="#FFE6A2" ... />
                //    </add>
                //    <modify />
                //    <delete />
                // </xfdf>
                const auth = getAuthHeaders();
                let myHeaders = { 'Content-Type': 'text/xml;charset=UTF-8', ...auth };
                // console.log('Annot update: ' + currentDocument + ' => ' + currentDocumentLabel);
                netFetch('/api/webviewer/annots/update/' + UserInfo.info.sessionId + '?doc=' + currentDocument, {
                    method: 'POST',
                    body: xfdfString,
                    headers: myHeaders,
                });
            });
        });

        postal.publish({
            topic: "webviewer.initialized",
            data: {
                instance: instance
            }
        });

        initializing = false;
    }).catch(error => {
        console.log('Error initializing PDFTron Webviewer: ' + error);
        initializing = false;
    });
}



const suppressAnnotation = (annotation) => {
    if (pdftronInstance) {
        const docViewer = pdftronInstance.Core.getDocumentViewers()[1];
        if (docViewer) {
            const annotManager = docViewer.getAnnotationManager();
            if (annotManager && annotManager.hasAnnotation(annotation)) {
                // console.log('Removing newly added annotation: ' + annotation.toolName);
                annotManager.deleteAnnotation(annotation, { force: true });
                pdftronInstance.UI.openElements([readOnlyWarningModalOptions.dataElement]);
            }
        }
    }

};

const registerToolSuppress = (docViewer, toolName) => {
    const tool = docViewer.getTool(toolName);
    if (tool) {
        tool.addEventListener('annotationAdded', suppressAnnotation);
    }
};

const registerSuppressTools = (docViewer) => {
    if (pdftronInstance) {
        const { Tools } = pdftronInstance.Core;

        const toolNames = [
            Tools.ToolNames.ARROW, Tools.ToolNames.ARROW2, Tools.ToolNames.ARROW3, Tools.ToolNames.ARROW4,
            Tools.ToolNames.CALLOUT, Tools.ToolNames.CALLOUT2, Tools.ToolNames.CALLOUT3, Tools.ToolNames.CALLOUT4,
            Tools.ToolNames.ELLIPSE, Tools.ToolNames.ELLIPSE2, Tools.ToolNames.ELLIPSE3, Tools.ToolNames.ELLIPSE4,
            Tools.ToolNames.FREEHAND, Tools.ToolNames.FREEHAND2, Tools.ToolNames.FREEHAND3, Tools.ToolNames.FREEHAND4,
            Tools.ToolNames.FREEHAND_HIGHLIGHT, Tools.ToolNames.FREEHAND_HIGHLIGHT2, Tools.ToolNames.FREEHAND_HIGHLIGHT3, Tools.ToolNames.FREEHAND_HIGHLIGHT4,
            Tools.ToolNames.FREETEXT, Tools.ToolNames.FREETEXT2, Tools.ToolNames.FREETEXT3, Tools.ToolNames.FREETEXT4,
            Tools.ToolNames.MARK_INSERT_TEXT, Tools.ToolNames.MARK_INSERT_TEXT2, Tools.ToolNames.MARK_INSERT_TEXT3, Tools.ToolNames.MARK_INSERT_TEXT4,
            Tools.ToolNames.MARK_REPLACE_TEXT, Tools.ToolNames.MARK_REPLACE_TEXT2, Tools.ToolNames.MARK_REPLACE_TEXT3, Tools.ToolNames.MARK_REPLACE_TEXT4,
            Tools.ToolNames.STICKY, Tools.ToolNames.STICKY2, Tools.ToolNames.STICKY3, Tools.ToolNames.STICKY4,
            Tools.ToolNames.SQUIGGLY, Tools.ToolNames.SQUIGGLY2, Tools.ToolNames.SQUIGGLY3, Tools.ToolNames.SQUIGGLY4,
            Tools.ToolNames.STRIKEOUT, Tools.ToolNames.STRIKEOUT2, Tools.ToolNames.STRIKEOUT3, Tools.ToolNames.STRIKEOUT4,
            Tools.ToolNames.HIGHLIGHT, Tools.ToolNames.HIGHLIGHT2, Tools.ToolNames.HIGHLIGHT3, Tools.ToolNames.HIGHLIGHT4,
            Tools.ToolNames.UNDERLINE, Tools.ToolNames.UNDERLINE2, Tools.ToolNames.UNDERLINE3, Tools.ToolNames.UNDERLINE4,
            Tools.ToolNames.STAMP, Tools.ToolNames.SIGNATURE, Tools.ToolNames.FILEATTACHMENT, Tools.ToolNames.EDIT,
            Tools.ToolNames.FORM_FILL_CROSS, Tools.ToolNames.FORM_FILL_CHECKMARK, Tools.ToolNames.FORM_FILL_DOT,
            Tools.ToolNames.LINE, Tools.ToolNames.LINE2, Tools.ToolNames.LINE3, Tools.ToolNames.LINE4,
            Tools.ToolNames.POLYGON, Tools.ToolNames.POLYGON2, Tools.ToolNames.POLYGON3, Tools.ToolNames.POLYGON4,
            Tools.ToolNames.POLYGON_CLOUD, Tools.ToolNames.POLYGON_CLOUD2, Tools.ToolNames.POLYGON_CLOUD3, Tools.ToolNames.POLYGON_CLOUD4,
            Tools.ToolNames.POLYLINE, Tools.ToolNames.POLYLINE2, Tools.ToolNames.POLYLINE3, Tools.ToolNames.POLYLINE4,
            Tools.ToolNames.RECTANGLE, Tools.ToolNames.RECTANGLE2, Tools.ToolNames.RECTANGLE3, Tools.ToolNames.RECTANGLE4,
        ];

        toolNames.forEach(toolName => registerToolSuppress(docViewer, toolName));



    }
};

const openPdfEditor = (changePage = true) => {
    if ( pdftronInstance ) {

    } else {
        initWebViewer();
    }
    if (changePage) {
        postal.publish({
            topic: "app.page.change.index",
            data: {
                index: 1
            }
        });
    }
}

const openDCRBinder = (binderFiles, refPortfolio) => {
    currentBinderFiles = [ ...binderFiles];
    currentRefPortfolio = refPortfolio;
    isCurrentDocumentReadOnly = true;
    isRightDocumentReadOnly = true;
    if (Array.isArray(currentBinderFiles) && currentBinderFiles.length > 0) {
        if (pdftronInstance) {
            loadDCRBinder();
        } else {
            scheduledDocument.push({ path: '', label: '', isBinder: true, isCandidate: false, refPortfolio: refPortfolio});
            initWebViewer();
        }
    }
};

const openPdfDocument = (path, label, previousDrafts, dcrPortfolio, oemPortfolio, isCandidate=false, refPortfolio) => {
    // console.log('previousDrafts: ' + JSON.stringify(previousDrafts));
    isCurrentDocumentReadOnly = false;
    isRightDocumentReadOnly = true;
    currentBinderLeftPath = '';
    currentBinderRightPath = '';
    if (pdftronInstance) {
        loadPdfDocument(path, label, previousDrafts, dcrPortfolio, oemPortfolio, isCandidate, refPortfolio);
    } else {
        scheduledDocument.push({ path: path, label: label, isCandidate: isCandidate, previousDrafts: previousDrafts, 
            dcrPortfolio: dcrPortfolio, oemPortfolio: oemPortfolio, refPortfolio: refPortfolio });
        initWebViewer();
    }
};

const openReadOnlyPdfDocument = (path, label) => {
    isCurrentDocumentReadOnly = true;
    isRightDocumentReadOnly = true;
    currentBinderLeftPath = '';
    currentBinderRightPath = '';
    if (pdftronInstance) {
        viewPdfDocument(path, label);
    } else {
        scheduledDocument.push({ path: path, label: label, readOnly: true });
        initWebViewer();
    }
};

const viewPdfDocument = (path, label) => {
    if (pdftronInstance) {
        const { Core } = pdftronInstance;
        currentDocument = path;
        if (label) {
            currentDocumentLabel = label;
        } else {
            currentDocumentLabel = '';
        }
        console.log('Opening webviewer in ReadOnly mode...');
        Core.getDocumentViewers()[0].enableReadOnlyMode();
        Core.getDocumentViewers()[0].getAnnotationManager().enableReadOnlyMode();
        Core.getDocumentViewers()[0].getDisplayModeManager().disableVirtualDisplayMode();
        pdftronInstance.UI.disableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);

        // UI.disableElements(['previous-versions-menu', 'previous-versions-menu-divider']);
        versionsMenuBtn.disabled = true;
        // pdftronInstance.UI.disableElements([pdftronInstance.UI.ToolbarGroup.ANNOTATE]);
        pdftronInstance.UI.disableElements(['ribbons', 'toolsHeader', 'stickyToolButton',
            'highlightToolButton', 'freeHandToolButton', 'freeHandHighlightToolButton', 'freeTextToolButton',
            'markInsertTextToolButton', 'markReplaceTextToolButton',
            'annotationCommentButton', 'annotationStyleEditButton', 'annotationDeleteButton',
            'textHighlightToolButton', 'textUnderlineToolButton', 'textSquigglyToolButton', 'textStrikeoutToolButton',
            'linkButton']);
        // load the main document
        loadPdfDocumentTask(path, label);
    }
};

const loadDCRBinder = () => {
    if (pdftronInstance) {
        const { Core } = pdftronInstance;
        currentDocument = currentBinderFiles[0].path;
        isCurrentDocumentReadOnly = false;
        dontClose = true;
        currentDocumentLabel = currentBinderFiles[0].name;
        currentBinderLeftPath = currentDocument;
        currentBinderRightPath = '';
        currentBinderLeftReadOnly = true;
        if ( currentBinderFiles.length > 1 || currentRefPortfolio) {
            buildDCRBinderMenu();
            versionsMenuBtn.disabled = false;
        } else {
            versionsMenuBtn.disabled = true;
        }
        /*
        console.log('Opening webviewer in ReadOnly mode...');
        Core.getDocumentViewers()[0].enableReadOnlyMode();
        Core.getDocumentViewers()[0].getAnnotationManager().enableReadOnlyMode();
        Core.getDocumentViewers()[0].getDisplayModeManager().disableVirtualDisplayMode();
        pdftronInstance.UI.disableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);
        pdftronInstance.UI.disableElements(['ribbons', 'toolsHeader', 'stickyToolButton',
            'highlightToolButton', 'freeHandToolButton', 'freeHandHighlightToolButton', 'freeTextToolButton',
            'markInsertTextToolButton', 'markReplaceTextToolButton',
            'annotationCommentButton', 'annotationStyleEditButton', 'annotationDeleteButton',
            'textHighlightToolButton', 'textUnderlineToolButton', 'textSquigglyToolButton', 'textStrikeoutToolButton',
            'linkButton']);
        loadPdfDocumentTask(currentDocument, currentDocumentLabel);
        */
        
        Core.getDocumentViewers()[0].disableReadOnlyMode();
        Core.getDocumentViewers()[0].getAnnotationManager().disableReadOnlyMode();
        Core.getDocumentViewers()[0].getDisplayModeManager().disableVirtualDisplayMode();
        pdftronInstance.UI.enableElements([pdftronInstance.UI.ToolbarGroup.ANNOTATE, 'ribbons', 'toolsHeader',
            'stickyToolButton', 'highlightToolButton', 'freeHandToolButton', 'freeHandHighlightToolButton', 'freeTextToolButton',
            'markInsertTextToolButton', 'markReplaceTextToolButton',
            'annotationCommentButton', 'annotationStyleEditButton', 'annotationDeleteButton',
            'textHighlightToolButton', 'textUnderlineToolButton', 'textSquigglyToolButton', 'textStrikeoutToolButton',
            'linkButton']);

        // load the main document
        loadPdfDocumentTask(currentDocument, currentDocumentLabel);
        
    }
}


const loadPdfDocument = (path, label, previousDrafts, dcrPortfolio, oemPortfolio, isCandidate, refPortfolio) => {
    if (pdftronInstance) {
        // console.log('A HAVE DCR: ' + JSON.stringify(dcrPortfolio));
        const { Core } = pdftronInstance;
        currentDocument = path;
        currentPortfolio = dcrPortfolio;
        currentOemPortfolio = oemPortfolio;
        currentRefPortfolio = refPortfolio;
        if (label) {
            currentDocumentLabel = label;
        } else {
            currentDocumentLabel = '';
        }
        let flabel;
        if (Array.isArray(previousDrafts) && previousDrafts.length > 0) {
            currentPortfolio = dcrPortfolio;
            currentOemPortfolio = oemPortfolio;
            currentRefPortfolio = refPortfolio;
            currentPreviousVersions = [];
            currentPreviousVersions = [...previousDrafts];
            currentPreviousVersions.reverse();
            //console.log('Previous drafts: ' + JSON.stringify(currentPreviousVersions));
            const hasVersions = buildVersionsMenu();
            if (hasVersions) {
                versionsMenuBtn.disabled = false;
                if (label) {
                    flabel = label + (isCandidate ? ' (Candidate Draft)' : ' (Latest Draft)');
                }
            } else {
                pdftronInstance.UI.disableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);
                // UI.disableElements(['previous-versions-menu', 'previous-versions-menu-divider']);
                versionsMenuBtn.disabled = true;
                if (label) {
                    flabel = label;
                }
            }
        } else if ( dcrPortfolio || oemPortfolio || refPortfolio) {
            
            currentPortfolio = dcrPortfolio;
            currentOemPortfolio = oemPortfolio;
            currentRefPortfolio = refPortfolio;
            currentPreviousVersions = [];
            const hasVersions = buildVersionsMenu();
            if (hasVersions) {
                versionsMenuBtn.disabled = false;
                if (label) {
                    flabel = label + (isCandidate ? ' (Candidate Draft)' : ' (Latest Draft)');
                }
            } else {
                pdftronInstance.UI.disableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);
                // UI.disableElements(['previous-versions-menu', 'previous-versions-menu-divider']);
                versionsMenuBtn.disabled = true;
                if (label) {
                    flabel = label;
                }
            }
        } else {
            pdftronInstance.UI.disableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);
            // UI.disableElements(['previous-versions-menu', 'previous-versions-menu-divider']);
            versionsMenuBtn.disabled = true;
            if (label) {
                flabel = label;
            }
        }
        Core.getDocumentViewers()[0].disableReadOnlyMode();
        Core.getDocumentViewers()[0].getAnnotationManager().disableReadOnlyMode();
        Core.getDocumentViewers()[0].getDisplayModeManager().disableVirtualDisplayMode();
        pdftronInstance.UI.enableElements([pdftronInstance.UI.ToolbarGroup.ANNOTATE, 'ribbons', 'toolsHeader',
            'stickyToolButton', 'highlightToolButton', 'freeHandToolButton', 'freeHandHighlightToolButton', 'freeTextToolButton',
            'markInsertTextToolButton', 'markReplaceTextToolButton',
            'annotationCommentButton', 'annotationStyleEditButton', 'annotationDeleteButton',
            'textHighlightToolButton', 'textUnderlineToolButton', 'textSquigglyToolButton', 'textStrikeoutToolButton',
            'linkButton']);

        // load the main document
        loadPdfDocumentTask(path, flabel);
    }
};

const loadPdfDocumentVersion = (path, vernum) => {
    // set the display mode
    const { Core } = pdftronInstance;
    // console.log('documentViewers: ' + Core.getDocumentViewers()[0].getDisplayModeManager().getDisplayMode());
    const dv = Core.getDocumentViewers();
    // console.log('documentViewers: ' + dv.length);
    const dm = Core.getDocumentViewers()[0].getDisplayModeManager().getDisplayMode();
    Core.getDocumentViewers()[1].getDisplayModeManager().setDisplayMode(new Core.DisplayMode(Core.getDocumentViewers()[1], dm.getMode()));
    if ( isRightDocumentReadOnly ) {
        Core.getDocumentViewers()[1].enableReadOnlyMode();
    } else {
        Core.getDocumentViewers()[1].disableReadOnlyMode();
    }
    Core.getDocumentViewers()[1].getDisplayModeManager().disableVirtualDisplayMode();
    Core.getDocumentViewers()[0].disableReadOnlyMode();
    let furl = encodeURI('/file/' + UserInfo.info.sessionId + path);
    let label = currentDocumentLabel;
    if (typeof currentDocumentLabel === 'string' && currentDocumentLabel.length > 0 && vernum) {
        // furl = '/file/alias/' + UserInfo.info.sessionId + path + '/' + currentDocumentLabel.replaceAll(/\//g, '_') + ' (' + vernum + ')';
        label = currentDocumentLabel + ' (' + vernum + ')';
    }
    currentVersion = path;
    currentVersionNumber = vernum;
    changingVersions = true;
    const options = { l: uxLicense, extension: 'pdf' };
    if ( label ) {
        options.filename = label;
    }
    Core.getDocumentViewers()[1].loadDocument(furl, options);
};

const loadLeftPdfDocument = (path, label, readOnly = false) => {
    const { Core } = pdftronInstance;
    // console.log('documentViewers: ' + Core.getDocumentViewers()[0].getDisplayModeManager().getDisplayMode());
    const dv = Core.getDocumentViewers();
    // console.log('documentViewers: ' + dv.length);
    dontClose = true;
    if ( !readOnly ) {
        Core.getDocumentViewers()[0].disableReadOnlyMode();
        Core.getDocumentViewers()[0].getAnnotationManager().disableReadOnlyMode();
        Core.getDocumentViewers()[0].getDisplayModeManager().disableVirtualDisplayMode();
        pdftronInstance.UI.enableElements([pdftronInstance.UI.ToolbarGroup.ANNOTATE, 'ribbons', 'toolsHeader',
            'stickyToolButton', 'highlightToolButton', 'freeHandToolButton', 'freeHandHighlightToolButton', 'freeTextToolButton',
            'markInsertTextToolButton', 'markReplaceTextToolButton',
            'annotationCommentButton', 'annotationStyleEditButton', 'annotationDeleteButton',
            'textHighlightToolButton', 'textUnderlineToolButton', 'textSquigglyToolButton', 'textStrikeoutToolButton',
            'linkButton']);

        // load the main document
        loadPdfDocumentTask(path, label, false);
    } else {
        Core.getDocumentViewers()[0].enableReadOnlyMode();
        Core.getDocumentViewers()[0].getAnnotationManager().enableReadOnlyMode();
        Core.getDocumentViewers()[0].getDisplayModeManager().disableVirtualDisplayMode();
        // pdftronInstance.UI.disableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);

        // UI.disableElements(['previous-versions-menu', 'previous-versions-menu-divider']);
        // versionsMenuBtn.disabled = true;
        // pdftronInstance.UI.disableElements([pdftronInstance.UI.ToolbarGroup.ANNOTATE]);
        pdftronInstance.UI.disableElements(['ribbons', 'toolsHeader', 'stickyToolButton',
            'highlightToolButton', 'freeHandToolButton', 'freeHandHighlightToolButton', 'freeTextToolButton',
            'markInsertTextToolButton', 'markReplaceTextToolButton',
            'annotationCommentButton', 'annotationStyleEditButton', 'annotationDeleteButton',
            'textHighlightToolButton', 'textUnderlineToolButton', 'textSquigglyToolButton', 'textStrikeoutToolButton',
            'linkButton']);
        // load the main document
        loadPdfDocumentTask(path, label, false);
    }
    /*
    const dm = Core.getDocumentViewers()[0].getDisplayModeManager().getDisplayMode();
    Core.getDocumentViewers()[1].getDisplayModeManager().setDisplayMode(new Core.DisplayMode(Core.getDocumentViewers()[1], dm.getMode()));
    if ( isRightDocumentReadOnly ) {
        Core.getDocumentViewers()[1].enableReadOnlyMode();
    } else {
        Core.getDocumentViewers()[1].disableReadOnlyMode();
    }
    Core.getDocumentViewers()[1].getDisplayModeManager().disableVirtualDisplayMode();
    Core.getDocumentViewers()[0].disableReadOnlyMode();
    let furl = encodeURI('/file/' + UserInfo.info.sessionId + path);
    currentVersion = path;
    currentVersionNumber = label;
    changingVersions = true;
    Core.getDocumentViewers()[1].loadDocument(furl, { l: uxLicense, filename: label, extension: 'pdf' });
    */
}

const loadRightPdfDocument = (path, label) => {
    const { Core } = pdftronInstance;
    // console.log('documentViewers: ' + Core.getDocumentViewers()[0].getDisplayModeManager().getDisplayMode());
    const dv = Core.getDocumentViewers();
    // console.log('documentViewers: ' + dv.length);
    const dm = Core.getDocumentViewers()[0].getDisplayModeManager().getDisplayMode();
    Core.getDocumentViewers()[1].getDisplayModeManager().setDisplayMode(new Core.DisplayMode(Core.getDocumentViewers()[1], dm.getMode()));
    if ( isRightDocumentReadOnly ) {
        Core.getDocumentViewers()[1].enableReadOnlyMode();
    } else {
        Core.getDocumentViewers()[1].disableReadOnlyMode();
    }
    Core.getDocumentViewers()[1].getDisplayModeManager().disableVirtualDisplayMode();
    Core.getDocumentViewers()[0].disableReadOnlyMode();
    let furl = encodeURI('/file/' + UserInfo.info.sessionId + path);
    currentVersion = path;
    currentVersionNumber = label;
    changingVersions = true;
    Core.getDocumentViewers()[1].loadDocument(furl, { l: uxLicense, filename: label, extension: 'pdf' });
}

const getDcrPortafolioBinders = (dcr) => {
    if ( !Array.isArray(dcr.properties?.files) ) {
        return [];
    }
    const binders = [];
    dcr.properties.files.forEach( item => {
        let formId;
        let formTitle = item.properties?.title;
        if ( ! formTitle ) {
            formTitle = item.properties?.formTitle;
        }
        if ( item.properties?.parentFormId ) {
            formId = item.properties?.parentFormId;
        } else if ( item.properties?.formId ) {
            formId = item.properties?.formId;
        }
        if (formId) {
            let binder = binders.find(b => b.id === formId);
            if (!binder) {
                binder = {
                    id: formId,
                    title: formTitle,
                    files: [],
                };
                binders.push(binder);
            }
            item.folio = dcr;
            binder.files.push(item);
            // console.log('Binder: ' + JSON.stringify(binder));
        } 
        /*
        else {
            console.log('DCR formId: ' + formId + ', title=' + formTitle);
            console.log('DCR file: ' + JSON.stringify(item));
        }
        */
    });
    return binders;
};

const getDcrPortafolioOtherFiles = (files) => {
    if ( !Array.isArray(files) ) {
        return [];
    }
    const other = [];
    files.forEach( item => {
        if (! item.properties?.formId) {
            other.push(item);
        } 
    });
    return other;
};

const buildVersionsMenu = () => {
    const { UI, Core } = pdftronInstance;
    //console.log('Previous drafts: ' + JSON.stringify(currentPreviousVersions));
    versionsMenu.innerHTML = '';

    let v = 0;
    // count the unlabel versions
    currentPreviousVersions.forEach((item) => {
        if (Array.isArray(item) ) {
            if ( typeof item.base == 'boolean') {
                // the base edition is separated and labeled differently
                if ( !item.base ) {
                    v++;
                }
            } else {
                v++;
            }
        }
    });
    let hasVersion = false;
    let hasInitialMarkup = false;
    let isDCRForm = false;
    let formId = '';
    //handle the single draft case for now
    if (currentPreviousVersions.length > 0) {
        const wdiv = document.createElement('div');
        const idiv = document.createElement('div');
        
        wdiv.style.paddingLeft = '2ex';
        currentPreviousVersions.forEach((item, index) => {
            const btn = document.createElement('button');
            btn.classList.add('Button');
            btn.classList.add('ActionButton');
            btn.classList.add('row');
            btn.style.width = '100%';
            btn.style.textAlign = 'left';
            btn.style.justifyContent = 'left';
            btn.style.paddingLeft = '1ex';
            btn.onmouseover = () => {
                btn.style.backgroundColor = '#f0f0f0';
            }
            btn.onmouseout = () => {
                btn.style.backgroundColor = '#ffffff';
            }
            // const fpath = Array.isArray(item) ? item[0].path : item.draft[0].path;
            const fpath = Array.isArray(item) ?
                findDraftFile(currentDocument, currentDocumentLabel, item) :
                /* findDraftFile(currentDocument, currentDocumentLabel, item.draft) */ item.path;
            if (fpath) {
                // console.log('Found previous draft: ' + JSON.stringify(item));
                let isInitial = false;
                let fversion = Array.isArray(item) || !item.label ? 'Draft ' + v : item.label;
                if ( item.parentFormId ) {
                    fversion = item.name;
                    isDCRForm = true;
                    formId = item.parentFormId;
                    isRightDocumentReadOnly = false;
                } else if ( item.formId ) {
                    fversion = item.name;
                    isDCRForm = true;
                    formId = item.formId;
                    isRightDocumentReadOnly = false;
                }
                if ( Array.isArray(item) && item.length > 0 && typeof item[0].base == 'boolean') {
                    if ( item[0].base ) {
                        if ( item[0].baseLabel ) {
                            // fversion = item[0].baseLabel;
                            fversion = 'Candidate Draft';
                        } else {
                            /*
                            fversion = 'Previous edition annotated for v20';
                            
                            const pdoc = currentPortfolio?.properties?.document;
                            if ( pdoc ) {
                                console.log('PFOLIO revision number: ' + pdoc.revisionNumber);
                                if ( pdoc.revisionNumber ) {
                                    fversion = 'Previous edition annotated for v' + pdoc.revisionNumber;
                                }
                            }
                            */
                            fversion = 'Candidate Draft';
                        }
                        hasInitialMarkup = true;
                        isInitial = true;
                        // console.log('found initial edition:' + fpath);
                    } else {
                        hasVersion = true;
                    }
                } else {
                    hasVersion = true;
                    
                }
                btn.textContent = fversion;
                // console.log('Adding ' + fversion);
                btn.onclick = () => {
                    // closeCustomMenu();
                    closeVersionsModal();
                    // if ( currentVersion !== item.path ) {
                    if (multiviewerEnabled) {
                        //console.log('Multiviewer mode already enabled...');
                        loadPdfDocumentVersion(fpath, fversion);
                    } else {
                        scheduledVersion.push({ path: fpath, number: fversion, type: 'version' });
                        multiviewerEnabled = true;
                        console.log('Enabling multiviewer mode...');
                        UI.enableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);

                        // don't need to close it
                        //UI.closeDocument().then(() => {
                        //    console.log('Closed document [' + currentDocument + ']');
                        //    UI.enableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);
                        //});
                        //
                    }
                    // }

                };
                if ( !isInitial ) {
                    wdiv.appendChild(btn);
                } else {
                    idiv.appendChild(btn);
                }
                if (Array.isArray(item)) {
                    v--;
                }
            }
            
        });
        if ( hasInitialMarkup ) {
            const mdiv = document.createElement('div');
            versionsMenu.appendChild(mdiv);
            const mtitle = document.createElement('div');
            mtitle.innerText = 'Initial Markup';
            mtitle.style.fontWeight = 'bold';
            mtitle.style.paddingBottom = '1.5ex';
            mdiv.appendChild(mtitle);
            mdiv.appendChild(idiv);
        }
        const pdiv = document.createElement('div');
        pdiv.style.paddingTop = '1ex';
        versionsMenu.appendChild(pdiv);
        const ptitle = document.createElement('div');
        ptitle.innerText = isDCRForm ? formId : 'Previous Drafts';
        ptitle.style.fontWeight = 'bold';
        ptitle.style.paddingBottom = '1.5ex';
        pdiv.appendChild(ptitle);
        versionsMenu.appendChild(pdiv);
        pdiv.appendChild(wdiv);
        if (!hasVersion) {
            const ndiv = document.createElement('div');
            ndiv.innerText = 'None'
            wdiv.appendChild(ndiv);
        }
    }
    
    if ( currentOemPortfolio ) {
        const pdiv = document.createElement('div');
        versionsMenu.appendChild(pdiv);
        const ptitle = document.createElement('div');
        ptitle.innerText = 'OEM Revision';
        ptitle.style.fontWeight = 'bold';
        ptitle.style.paddingTop = '1.5ex';
        ptitle.style.paddingBottom = '1.5ex';
        pdiv.appendChild(ptitle);
        const doc = currentOemPortfolio.properties.document;
        const wdiv = document.createElement('div');
        pdiv.appendChild(wdiv);
        wdiv.style.paddingLeft = '2ex';
        currentOemPortfolio.properties.files.forEach( (item, index) => {
            const btn = document.createElement('button');
            btn.classList.add('Button');
            btn.classList.add('ActionButton');
            btn.classList.add('row');
            btn.style.width = '100%';
            btn.style.textAlign = 'left';
            btn.style.justifyContent = 'left';
            btn.style.paddingLeft = '1ex';
            btn.onmouseover = () => {
                btn.style.backgroundColor = '#f0f0f0';
            }
            btn.onmouseout = () => {
                btn.style.backgroundColor = '#ffffff';
            }
            
            let fpath = DLIB_DIR + doc.path + '/' + item.path;
            hasVersion = true;
            btn.textContent = item.name;
            console.log('OEM Portfolio item: (' + item.name + ') : ' + fpath);
            btn.onclick = () => {
                closeVersionsModal();
                if (multiviewerEnabled) {
                    console.log('Multiviewer mode already enabled...');
                    loadRightPdfDocument(fpath, item.name);
                } else {
                    scheduledVersion.push({ path: fpath, number: item.name, type: 'normal' });
                    multiviewerEnabled = true;
                    console.log('Enabling multiviewer mode...');
                    UI.enableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);

                    // don't need to close it
                    //UI.closeDocument().then(() => {
                    //    console.log('Closed document [' + currentDocument + ']');
                    //    UI.enableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);
                    //});
                    //
                }
            };
            wdiv.appendChild(btn);
        });
        
    }

    if ( currentRefPortfolio ) {
        const pdiv = document.createElement('div');
        versionsMenu.appendChild(pdiv);
        const ptitle = document.createElement('div');
        ptitle.innerText = 'References';
        ptitle.style.fontWeight = 'bold';
        ptitle.style.paddingTop = '1.5ex';
        ptitle.style.paddingBottom = '1.5ex';
        pdiv.appendChild(ptitle);
        const doc = currentRefPortfolio.properties.document;
        const wdiv = document.createElement('div');
        pdiv.appendChild(wdiv);
        wdiv.style.paddingLeft = '2ex';
        currentRefPortfolio.properties.files.forEach( (item, index) => {
            const btn = document.createElement('button');
            btn.classList.add('Button');
            btn.classList.add('ActionButton');
            btn.classList.add('row');
            btn.style.width = '100%';
            btn.style.textAlign = 'left';
            btn.style.justifyContent = 'left';
            btn.style.paddingLeft = '1ex';
            btn.onmouseover = () => {
                btn.style.backgroundColor = '#f0f0f0';
            }
            btn.onmouseout = () => {
                btn.style.backgroundColor = '#ffffff';
            }
            
            let fpath = DLIB_DIR + doc.path + '/' + item.path;
            hasVersion = true;
            btn.textContent = item.name;
            console.log('REF Portfolio item: (' + item.name + ') : ' + fpath);
            btn.onclick = () => {
                closeVersionsModal();
                if (multiviewerEnabled) {
                    console.log('Multiviewer mode already enabled...');
                    loadRightPdfDocument(fpath, item.name);
                } else {
                    scheduledVersion.push({ path: fpath, number: item.name, type: 'normal' });
                    multiviewerEnabled = true;
                    console.log('Enabling multiviewer mode...');
                    UI.enableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);

                    // don't need to close it
                    //UI.closeDocument().then(() => {
                    //    console.log('Closed document [' + currentDocument + ']');
                    //    UI.enableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);
                    //});
                    //
                }
            };
            wdiv.appendChild(btn);
        });
        
    }

    if ( currentPortfolio ) {
        const pdiv = document.createElement('div');
        versionsMenu.appendChild(pdiv);
        const ptitle = document.createElement('div');
        ptitle.innerText = 'Document Change Requests';
        ptitle.style.fontWeight = 'bold';
        ptitle.style.paddingTop = '1.5ex';
        ptitle.style.paddingBottom = '1.5ex';
        pdiv.appendChild(ptitle);
        const doc = currentPortfolio.properties.document;
        const wdiv = document.createElement('div');
        pdiv.appendChild(wdiv);
        wdiv.style.paddingLeft = '2ex';
        const binders = getDcrPortafolioBinders(currentPortfolio);
        binders.forEach( (binder, bindex) => {
            const btitle = document.createElement('div');
            btitle.innerText = `${binder.id}: ${binder.title}`
            btitle.style.fontWeight = 'bold';
            btitle.style.paddingTop = '1.5ex';
            btitle.style.paddingBottom = '1.5ex';
            btitle.style.paddingLeft = '2ex';
            pdiv.appendChild(btitle);
            binder.files.forEach( (item,index) => {
                const btn = document.createElement('button');
                btn.classList.add('Button');
                btn.classList.add('ActionButton');
                btn.classList.add('row');
                btn.style.width = '100%';
                btn.style.textAlign = 'left';
                btn.style.justifyContent = 'left';
                btn.style.paddingLeft = '4ex';
                btn.onmouseover = () => {
                    btn.style.backgroundColor = '#f0f0f0';
                }
                btn.onmouseout = () => {
                    btn.style.backgroundColor = '#ffffff';
                }
                
                let fpath = DLIB_DIR + doc.path + '/' + item.path;
                hasVersion = true;
                btn.textContent = item.name;
                console.log('DCR Portfolio item: (' + item.name + ') : ' + fpath);
                btn.onclick = () => {
                    closeVersionsModal();
                    if (multiviewerEnabled) {
                        console.log('Multiviewer mode already enabled...');
                        loadRightPdfDocument(fpath, item.name);
                    } else {
                        scheduledVersion.push({ path: fpath, number: item.name, type: 'normal' });
                        multiviewerEnabled = true;
                        console.log('Enabling multiviewer mode...');
                        UI.enableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);
                    }
                };
                pdiv.appendChild(btn);
            });
        });

        const other = getDcrPortafolioOtherFiles(currentPortfolio.properties.files);        
        other.forEach( (item, index) => {
            const btn = document.createElement('button');
            btn.classList.add('Button');
            btn.classList.add('ActionButton');
            btn.classList.add('row');
            btn.style.width = '100%';
            btn.style.textAlign = 'left';
            btn.style.justifyContent = 'left';
            btn.style.paddingLeft = '1ex';
            btn.onmouseover = () => {
                btn.style.backgroundColor = '#f0f0f0';
            }
            btn.onmouseout = () => {
                btn.style.backgroundColor = '#ffffff';
            }
            
            let fpath = DLIB_DIR + doc.path + '/' + item.path;
            hasVersion = true;
            btn.textContent = item.name;
            console.log('DCR Portfolio item: (' + item.name + ') : ' + fpath);
            btn.onclick = () => {
                closeVersionsModal();
                if (multiviewerEnabled) {
                    console.log('Multiviewer mode already enabled...');
                    loadRightPdfDocument(fpath, item.name);
                } else {
                    scheduledVersion.push({ path: fpath, number: item.name, type: 'normal' });
                    multiviewerEnabled = true;
                    console.log('Enabling multiviewer mode...');
                    UI.enableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);

                    // don't need to close it
                    //UI.closeDocument().then(() => {
                    //    console.log('Closed document [' + currentDocument + ']');
                    //    UI.enableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);
                    //});
                    //
                }
            };
            wdiv.appendChild(btn);
        });
        
    }
    return hasVersion;
};

const buildDCRBinderMenu = () => {
    const { UI, Core } = pdftronInstance;
    //console.log('Previous drafts: ' + JSON.stringify(currentPreviousVersions));
    versionsMenu.innerHTML = '';
    let formId = '';
    let formTitle = '';
    let formType = '';
    
    // count the unlabel versions
    for(let i=0; i<currentBinderFiles.length; i++) {
        const file = currentBinderFiles[i];
        if ( file.parentFormId ) {
            formId = file.parentFormId;
            formTitle = file.title;
            formType = file.formType;
            break;
        } else if ( file.formId ) {
            formId = file.formId;
            formTitle = file.title;
            formType = file.formType;
            break;
        }
    }
    
    if (currentBinderFiles.length > 0) {
        const wdiv = document.createElement('div');
        
        wdiv.style.paddingLeft = '2ex';
        currentBinderFiles.forEach((item, index) => {
            const btn = document.createElement('button');
            btn.classList.add('Button');
            btn.classList.add('ActionButton');
            btn.classList.add('row');
            btn.style.width = '100%';
            btn.style.textAlign = 'left';
            btn.style.justifyContent = 'left';
            btn.style.paddingLeft = '1ex';
            btn.onmouseover = () => {
                btn.style.backgroundColor = '#f0f0f0';
            }
            btn.onmouseout = () => {
                btn.style.backgroundColor = '#ffffff';
            }
            if (item.path) {
                // console.log('Found previous draft: ' + JSON.stringify(item));
                let fversion = item.name;
                btn.textContent = fversion;
                // console.log('Adding ' + fversion);
                btn.onclick = () => {
                    // closeCustomMenu();
                    closeVersionsModal();
                    if (item.path !== currentBinderLeftPath && item.path !== currentBinderRightPath) {
                        if (multiviewerEnabled) {
                            //console.log('Multiviewer mode already enabled...');
                            if (item.type === 'ATTACHMENT') {
                                currentBinderLeftPath = item.path;
                                currentBinderLeftReadOnly = false;
                                currentDocumentLabel = fversion;
                                isCurrentDocumentReadOnly = false;
                                currentBinderRightPath = '';
                                loadLeftPdfDocument(item.path, fversion, false);
                                /*
                                const right = currentBinderFiles.find(item => item.path === currentBinderRightPath);
                                if (right) {
                                    currentBinderRightPath = right.path;
                                    scheduledVersion.push({ path: right.path, number: right.name, type: 'normal' });
                                    multiviewerEnabled = true;
                                    console.log('Enabling multiviewer mode...');
                                    UI.enableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);
                                }
                                */
                            } else {
                                currentBinderRightPath = item.path;
                                loadRightPdfDocument(item.path, fversion);
                            }
                        } else {
                            if (item.type === 'ATTACHMENT') {
                                currentBinderLeftPath = item.path;
                                currentBinderLeftReadOnly = false;
                                isCurrentDocumentReadOnly = false;
                                currentDocumentLabel = fversion;
                                loadLeftPdfDocument(item.path, fversion, false);
                            } else {
                                currentBinderLeftPath = item.path;
                                currentBinderLeftReadOnly = false;
                                isCurrentDocumentReadOnly = false;
                                currentDocumentLabel = fversion;
                                loadLeftPdfDocument(item.path, fversion, false);
                                /*
                                currentBinderRightPath = item.path;
                                scheduledVersion.push({ path: item.path, number: fversion, type: 'normal' });
                                multiviewerEnabled = true;
                                console.log('Enabling multiviewer mode...');
                                UI.enableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);
                                */
                            }
                        }
                    }

                };
                wdiv.appendChild(btn);
                
            }
            
        });

        
        
        const pdiv = document.createElement('div');
        pdiv.style.paddingTop = '1ex';
        versionsMenu.appendChild(pdiv);
        const ptitle = document.createElement('div');
        ptitle.innerText = formType === 'CAA' ? `${formId} ${formTitle}` : `${formId}: ${formTitle}`;
        ptitle.style.fontWeight = 'bold';
        ptitle.style.paddingBottom = '1.5ex';
        pdiv.appendChild(ptitle);
        versionsMenu.appendChild(pdiv);
        pdiv.appendChild(wdiv);


        
    }
    if (currentRefPortfolio) {
        const pdiv = document.createElement('div');
        versionsMenu.appendChild(pdiv);
        const ptitle = document.createElement('div');
        ptitle.innerText = 'References';
        ptitle.style.fontWeight = 'bold';
        ptitle.style.paddingTop = '1.5ex';
        ptitle.style.paddingBottom = '1.5ex';
        pdiv.appendChild(ptitle);
        const doc = currentRefPortfolio.properties.document;
        const wdiv = document.createElement('div');
        pdiv.appendChild(wdiv);
        wdiv.style.paddingLeft = '2ex';
        currentRefPortfolio.properties.files.forEach((item, index) => {
            const btn = document.createElement('button');
            btn.classList.add('Button');
            btn.classList.add('ActionButton');
            btn.classList.add('row');
            btn.style.width = '100%';
            btn.style.textAlign = 'left';
            btn.style.justifyContent = 'left';
            btn.style.paddingLeft = '1ex';
            btn.onmouseover = () => {
                btn.style.backgroundColor = '#f0f0f0';
            }
            btn.onmouseout = () => {
                btn.style.backgroundColor = '#ffffff';
            }

            let fpath = DLIB_DIR + doc.path + '/' + item.path;
            btn.textContent = item.name;
            console.log('REF Portfolio item: (' + item.name + ') : ' + fpath);
            btn.onclick = () => {
                closeVersionsModal();
                if (multiviewerEnabled) {
                    console.log('Multiviewer mode already enabled...');
                    loadRightPdfDocument(fpath, item.name);
                } else {
                    scheduledVersion.push({ path: fpath, number: item.name, type: 'normal' });
                    multiviewerEnabled = true;
                    console.log('Enabling multiviewer mode...');
                    UI.enableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);

                    // don't need to close it
                    //UI.closeDocument().then(() => {
                    //    console.log('Closed document [' + currentDocument + ']');
                    //    UI.enableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);
                    //});
                    //
                }
            };
            wdiv.appendChild(btn);
        });

    }

    return true;
};


// try to find the file that matches current file
// first try matching the label, if not get the longest file name match
const findDraftFile = (path, label, drafts) => {
    if (!Array.isArray(drafts) || !path) {
        return undefined;
    }
    // normalize
    if (label) {
        const nlabel = label.toLowerCase().replace(/\s+/g, ' ').trim().replace(/[-_]/g, '');
        let fpath;
        for (let i = 0; i < drafts.length; i++) {
            if (!drafts[i].label) {
                continue;
            }

            const ilabel = drafts[i].label.toLowerCase().replace(/\s+/g, ' ').trim().replace(/[-_]/g, '');
            // console.log('Matching label ' + ilabel + ' to ' + nlabel + ' , path: ' + drafts[i].path);
            if (nlabel === ilabel) {
                // found a matching label
                fpath = drafts[i].path;
                break;
            }
        }
        if (fpath) {
            return fpath;
        }
    }
    // no label match, try matching paths
    // disable for now
    /*
    let j = path.lastIndexOf('/');
    const name = j >= 0 ? path.substring(j+1) : path;
    let fpath;
    let max = -1;
    for(let i=0; i<drafts.length; i++) {
        if ( !drafts[i].path ) {
            continue;
        }
        j = drafts[i].path.lastIndexOf('/');
        const fname = j >= 0 ? drafts[i].path.substring(j+1) : drafts[i].path;
        const fmax = findMaxMatchingLength(name, fname);
        if ( fmax > max ) {
            fpath = drafts[i].path;
            max = fmax;
        }
    }
    return fpath;
    */
    // if there's only one, always match it, in case we're from a draft output
    if (drafts.length === 1) {
        return drafts[0].path;
    }
    return undefined;
};

const findMaxMatchingLength = (s1, s2) => {
    let i = 0;
    for (; i < s1.length && i < s2.length; i++) {
        if (s1.charAt(i) !== s2.charAt(i)) {
            break;
        }
    }
    return i;
};

// Deprecated, open 2 documents in multiviewer mode
const openPdfDocumentCompare = (path1, path2) => {
    const { UI, Core } = pdftronInstance;
    const { annotationManager } = Core;
    annotationManager.setCurrentUser(UserInfo.info.name);

    UI.addEventListener(UI.Events.MULTI_VIEWER_READY, () => {
        const dm = Core.getDocumentViewers()[0].getDisplayModeManager().getDisplayMode();
        Core.getDocumentViewers()[0].getDisplayModeManager().setDisplayMode(dm);

        Core.getDocumentViewers()[0].loadDocument('/file/' + UserInfo.info.sessionId + path1);
        Core.getDocumentViewers()[1].loadDocument('/file/' + UserInfo.info.sessionId + path2);
    })
    UI.enableFeatures([UI.Feature.MultiViewerMode]);
    // change the page index to display our webviewer
    // page 1 is reserved for the webviewer
    postal.publish({
        topic: "app.page.change.index",
        data: {
            index: 1
        }
    });
};

const closePdfViewer = () => {
    // this should close the document as well
    // since we're listening to the page index change to do so
    if (!dontClose) {
        dontClose = false;
        postal.publish({
            topic: "app.page.change.index",
            data: {
                index: 0
            }
        });
    }
    
};

const loadPdfDocumentTask = (path, alias, changePage = true) => {
    if (pdftronInstance) {
        const { UI, Core } = pdftronInstance;


        const { annotationManager } = Core;
        annotationManager.setCurrentUser(UserInfo.info.name);

        UI.closeDocument().then(() => {
            currentDocument = path;
            // register the file first before opening, just in case
            netGet('/api/webviewer/document/' + UserInfo.info.sessionId + currentDocument)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Status: ' + response.status);
                    }
                    /*
                    const furl = alias ?
                        '/file/alias/' + UserInfo.info.sessionId + path + '/' + alias.replaceAll(/\//g, '_') :
                        '/file/' + UserInfo.info.sessionId + path;
                    */
                    const furl = '/file/' + UserInfo.info.sessionId + path;
                    console.log('Load PDF: ' + furl);
                    const options = { l: uxLicense, extension: 'pdf'};
                    if ( alias ) {
                        options.filename = alias;
                    }
                    UI.loadDocument(furl, options);
                }).catch(error => {
                    console.log('Failed to register webviewer document: ' + error);
                    // open it anyway
                    /*
                    const furl = alias ?
                        '/file/alias/' + UserInfo.info.sessionId + path + '/' + alias :
                        '/file/' + UserInfo.info.sessionId + path;
                    */
                    const furl = '/file/' + UserInfo.info.sessionId + path;
                    console.log('Load PDF: ' + furl);
                    const options = { l: uxLicense, extension: 'pdf' };
                    if (alias) {
                        options.filename = alias;
                    }
                    UI.loadDocument(furl, options);
                    // UI.loadDocument(furl, { l: uxLicense });
                });
        });

        // change the page index to display our webviewer
        // page 1 is reserved for the webviewer
        console.log('webviewer: changePage = ' + changePage);
        if (changePage) {
            postal.publish({
                topic: "app.page.change.index",
                data: {
                    index: 1
                }
            });
        }
    }
};

const RevisionsDialog2 = (props) => {

    const [selectedIndex,setSelectedIndex] = React.useState(-1);

    const handleClick = (index) => {
        setSelectedIndex(index);
    };

    return (
            <Box>
                <List sx={{ minHeight: '2em' }}>
                    <ListItem
                        key={'pvr-1'} 
                        sx={{ padding: 1, paddingTop: 0, paddingBottom: 0 }}
                    >
                        <ListItemButton selected={selectedIndex == 0} onClick={(e) => handleClick(0)}>
                            <ListItemText>Label 1</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem
                        key={'pvr-2'}
                        sx={{ padding: 1, paddingTop: 0, paddingBottom: 0 }}
                    >
                        <ListItemButton selected={selectedIndex == 1} onClick={(e) => handleClick(1)}>
                            <ListItemText>Label 2</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
    );
}


const PdftronWebViewer = (props) => {
    const viewer = useRef(null);

    React.useEffect(() => {

        const subscription = postal.subscribe({
            topic: "app.page.change.index.notify",
            callback: (data, envelope) => {
                // console.log('Got message: ' + JSON.stringify(data) + ',' + JSON.stringify(envelope));
                handlePostalPageChangeIndex(data);
            }
        });

        return () => {
            subscription.unsubscribe();
        }

    }, []);

    React.useEffect(() => {

        const subscription = postal.subscribe({
            topic: "webviewer.initialized",
            callback: (data, envelope) => {
                // console.log('Got message: ' + JSON.stringify(data) + ',' + JSON.stringify(envelope));
                handlePostalWebviewerInitialized(data);
            }
        });

        return () => {
            subscription.unsubscribe();
        }

    }, []);

    const handlePostalPageChangeIndex = (msg) => {
        if (msg.index !== 1 && pdftronInstance) {
            // close the current document if webviewer page is closed
            const { UI, Core } = pdftronInstance;
            if (multiviewerEnabled) {
                // UI.setNotesPanelSortStrategy(UI.NotesPanelSortStrategy.CREATED_DATE, false);
                UI.setCustomPanel({
                    uniscope: {
                        type: 'notesInLeftPanel',
                        notesInLeftPanel: false,
                    }
                });
                setTimeout(() => {
                    UI.enableElements(['toggleNotesButton']);
                    UI.openElements(['notesPanel']);
                    UI.closeElements(['multiRightPanel', 'multiNotesPanel']);
                    UI.closeDocument().then(() => {
                        console.log('Closed document [' + currentDocument + ']');
                        UI.disableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);
                        multiviewerEnabled = false;
                        removeViewer();
                    });
                }, 200);
                /*
                Core.getDocumentViewers()[1].closeDocument().then(() => {
                    console.log('Closed ' + currentVersionNumber + ' : ' + currentVersion);
                    UI.closeDocument().then(() => {
                        console.log('Closed document [' + currentDocument + ']');
                        UI.disableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);
                        multiviewerEnabled = false;
                    }).catch( error => {
                        console.log('Error closing document ' + currentDocument + ' : ' + error);
                        UI.disableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);
                        multiviewerEnabled = false;
                    });
                }).catch ( error => {
                    console.log('Error closing version ' + currentVersionNumber + ' : ' + currentVersion + ' : ' + error);
                    UI.disableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);
                    multiviewerEnabled = false;
                });
                */
            } else {
                UI.closeDocument().then(() => {
                    console.log('Closed document [' + currentDocument + ']');
                    UI.disableFeatures([pdftronInstance.UI.Feature.MultiViewerMode]);
                    multiviewerEnabled = false;
                    removeViewer();
                });
            }
        }
    };

    const removeViewer = () => {
        /*
        let viewerContainer = document.getElementById('pdftron-viewer-container');
        viewerContainer.innerHTML = '';
        pdftronInstance.
        pdftronInstance = undefined;
        */
    };

    const handlePostalWebviewerInitialized = (msg) => {
        if (scheduledDocument.length > 0) {
            const job = scheduledDocument.pop();
            if (job) {
                if ( job.isBinder ) {
                    currentRefPortfolio = job.refPortfolio;
                    loadDCRBinder();
                } else if (job.readOnly) {
                    viewPdfDocument(job.path, job.label);
                } else {
                    loadPdfDocument(job.path, job.label, job.previousDrafts, job.dcrPortfolio, job.oemPortfolio, job.isCandidate, job.refPortfolio);
                }
            }
        }
    }


    const annotMessage = (message) => {
        let wevent = message.data;
        // console.log('Received collab message: ' + JSON.stringify(wevent));
        const msg = wevent.data;
        if (pdftronInstance) {
            if (msg) {
                if (msg.docPath) {
                    if (msg.docPath === currentDocument) {
                        processAnnotations(msg);
                    } else {
                        console.log('Skipping annotations for different document ' + msg.docPath);
                    }

                } else {
                    console.log('No target document in annotation message, processing anyway...');
                    processAnnotations(msg);
                }
            }
        }
    };

    const processAnnotations = (msg) => {
        const { annotationManager } = pdftronInstance.Core;

        if (msg.add) {
            annotationManager.importAnnotationCommand(msg.add).then(function (annotations) {
                annotations.forEach(function (item, index) {
                    annotationManager.redrawAnnotation(item);
                });
            });

        }
        if (msg.modify) {
            annotationManager.importAnnotationCommand(msg.modify).then(function (annotations) {
                annotations.forEach(function (item, index) {
                    annotationManager.redrawAnnotation(item);
                });
            });
        }
        if (msg.remove) {
            annotationManager.importAnnotationCommand(msg.remove);
        }
    };

    MessageHooks["collab"]["webviewer"] = annotMessage;


    return (
        <Box sx={{ position: 'relative', width: '100%', height: '100%', zIndex: 100 }}>
            { /* <div id="pdftron-viewer" className="webviewer" ref={viewer}></div> */}
            <div id="pdftron-viewer-container" ref={viewer} style={{ width: "100%", height: "100%" }} />
        </Box>
    );


};

export { PdftronWebViewer, pdftronInstance, initWebViewer, openPdfDocument, openReadOnlyPdfDocument, openPdfDocumentCompare, openDCRBinder, closePdfViewer, openPdfEditor };
